<template>
    <Card class="relative">
        <template #content>
            <div class="interview-summary w-full flex flex-col justify-start gap-[16px] pt-[4px] pb-[4px]"
                :style="{ 'overflow': overflow, 'height': height }">
                <div class="audio-player">
                    <video ref="audioPlayer" controls @timeupdate="onTimeUpdate">
                        <source :src="transcript?.transcript_video_url" type="audio/mpeg">
                        Your browser does not support the audio element.
                    </video>
                </div>

                <section class="transcript-and-questions">
                    <div v-for="(entry, index) in combinedTranscriptAndQuestions" :key="index"
                        :class="['entry', { 'transcript-entry': !entry.score, 'question-entry': entry.score }]"
                        @click="entry.score ? null : seekToTime(entry.timestamp)">
                        <template v-if="!entry.score">
                            <div class="timestamp">{{ entry.timestamp }}</div>
                            <div class="speaker" :class="{ 'ai-agent': entry.isQuestion }">
                                {{ entry.speaker }}:
                            </div>
                            <div class="text">{{ entry.text }}</div>
                        </template>
                        <template v-else>
                            <div class="question-number">Question {{ entry.questionNumber }}</div>
                            <div class="question-text">{{ entry.question }}</div>
                            <div class="question-score">Score: {{ entry.score }}/10</div>
                            <div class="question-feedback">Feedback: {{ entry.feedback }}</div>
                        </template>
                    </div>
                </section>

                <section class="feedback">
                    <h2>Overall Impression</h2>
                    <p>{{ transcript?.summary }}</p>
                </section>
            </div>
            <slot name="overlayButton">
            </slot>
        </template>
    </Card>
</template>

<script setup lang="ts">
import Card from 'primevue/card';
import { ref, computed, reactive } from "vue";
import type { PropType } from 'vue';
import type { TranscriptType, TranscriptEntry, TranscriptFeedback } from '@/stores/candidateStore';



const props = defineProps({
    overflow: {
        type: String,
        default: 'auto'
    },
    height: {
        type: String,
        default: '100%'
    },
    transcript: {
        type: Object as PropType<TranscriptType>,
        required: true
    }
})

const currentTime = ref(0)
const audioPlayer = ref<HTMLAudioElement>()


interface CombinedEntries extends TranscriptFeedback, TranscriptEntry {
    questionNumber: number
}
const combinedTranscriptAndQuestions = computed((): CombinedEntries[] => {
    if (!props.transcript)
        return [];
    let combined = [...(props.transcript as TranscriptType).transcript_entries] as CombinedEntries[];
    (props.transcript as TranscriptType).transcript_feedback.forEach((feedback: TranscriptFeedback, index: number) => {
        // Find the position to insert the question
        const position = combined.findIndex(entry =>
            entry.text?.toLowerCase().includes(feedback.question?.toLowerCase().slice(0, 20))
        );
        if (position !== -1) {
            combined.splice(position, 0, {
                questionNumber: index + 1,
                ...feedback
            } as CombinedEntries);
        }
    });
    console.log(combined);
    return combined;
})

function onTimeUpdate() {
    currentTime.value = (audioPlayer.value as HTMLAudioElement).currentTime;
}
function seekToTime(timestamp: string) {
    const [minutes, seconds] = timestamp.split(':').map(Number);
    const timeInSeconds = minutes * 60 + seconds;
    (audioPlayer.value as HTMLAudioElement).currentTime = timeInSeconds;
    (audioPlayer.value as HTMLAudioElement).play();
}
</script>

<style scoped>
.interview-summary {
    max-width: 800px;
}

h1,
h2 {
    color: #333;
}

.audio-player {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.audio-player video {
    flex-grow: 1;
}

.current-time {
    margin-left: 10px;
    font-weight: bold;
}

.transcript-and-questions {
    margin-bottom: 40px;
}

.entry {
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 5px;
}

.transcript-entry {
    background-color: #f9f9f9;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.transcript-entry:hover {
    background-color: #e0e0e0;
}

.question-entry {
    background-color: #e6f3ff;
    border-left: 4px solid #0066cc;
}

.timestamp {
    font-size: 0.8em;
    color: #666;
}

.speaker {
    font-weight: bold;
    margin-top: 5px;
}

.ai-agent {
    color: #0066cc;
}

.text {
    margin-top: 5px;
}

.question-number {
    font-weight: bold;
    color: #0066cc;
    margin-bottom: 5px;
}

.question-text {
    font-style: italic;
    margin-bottom: 5px;
}

.question-score {
    font-weight: bold;
}

.question-feedback {
    margin-top: 5px;
    color: #666;
}

.feedback {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 5px;
}
</style>