<template>
    <div class="flex flex-col items-center justify-center box-border max-w-full">
        <SubNavbar>
            <template #title>
                Interviews / Reports
            </template>
        </SubNavbar>
        <TwoThirdByOneThird class="max-w-[1440px] pt-[50px] pb-[100px] px-[108px] space-x-[24px]">
            <template #first>
                <div class="flex flex-col gap-[24px]">
                    <CandidateDetailsCard :details="candidate.details" />
                    <SummarySection :isModal="false" :interviewReport="candidate.report" @openModal="SummaryModalVisible = true" />
                </div>
            </template>
            <template #second>
                <div class="flex flex-col gap-[24px]">
                    <Transcript overflow="hidden" height="400px" :transcript="(candidate.transcript as TranscriptType)">
                        <template #overlayButton>
                            <Button class="w-full mt-5" outlined severity="secondary" label="View Full"
                                @click="transcriptModalVisible = true"></Button>
                        </template>
                    </Transcript>
                    <Card>
                        <template #content>
                            <ParagraphSection>
                                <template #title>
                                    Recruiter notes
                                </template>
                            </ParagraphSection>
                            <Editor v-model="editorValue" editor-style="height: 200px">
                                <template v-slot:toolbar>
                                    <span class="ql-formats">
                                        <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                                        <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                                        <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button v-tooltip.bottom="'Image'" class="ql-image"></button>
                                        <button v-tooltip.bottom="'Ordered List'" class="ql-list"
                                            value="ordered"></button>
                                        <button v-tooltip.bottom="'Bullet List'" class="ql-list"
                                            value="bullet"></button>
                                    </span>
                                </template>
                            </Editor>
                            <div class="flex flex-row justify-between gap-[24px] mt-5">
                                <Button class="w-full" severity="secondary" rounded label="Cancel"></Button>
                                <Button class="w-full" severity="primary" rounded label="Save"></Button>
                            </div>
                        </template>
                    </Card>
                    <SectionHeader :is-sub-section="true" title-align="start">
                        <template #title>
                            Latest notes
                        </template>
                    </SectionHeader>
                    <NoteCard v-for="(note, index) in candidate.recruiter_notes" :key="index" :note="note" />
                </div>
            </template>
        </TwoThirdByOneThird>
        <Dialog v-model:visible="transcriptModalVisible" maximizable modal header="Transcript"
            :style="{ width: 'fit-content', minHeight: '90vh' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
            :contentStyle="{ display: 'flex', flexDirection: 'column', alignItems: 'center' }">
            <Transcript overflow="auto" :transcript="(candidate.transcript as TranscriptType)" />
        </Dialog>
        <Dialog v-model:visible="SummaryModalVisible" maximizable modal header="Summary"
            :style="{ width: 'fit-content', minHeight: '90vh' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
            :contentStyle="{ display: 'flex', flexDirection: 'column', alignItems: 'center' }">
            <SummarySection overflow="auto" :interviewReport="candidate.report" :isModal="true" />
        </Dialog>
    </div>

</template>

<script setup lang="ts">
import SummarySection from "@/components/SummarySection.vue";
import SectionHeader from "@/components/slots/SectionHeader.vue";
import ParagraphSection from "@/components/slots/ParagraphSection.vue";
import Transcript from "@/components/Transcript.vue";
import { ref, onMounted, computed } from "vue";
import Button from "primevue/button";
import Editor from 'primevue/editor';
import SubNavbar from "@/components/slots/SubNavbar.vue";
import TwoThirdByOneThird from "@/components/slots/TwoThirdByOneThird.vue";
import Card from "primevue/card";
import Dialog from "primevue/dialog";
import type { Candidate, TranscriptType } from "@/stores/candidateStore";
import { useCandidateStore } from "@/stores/candidateStore";
import { useRoute } from "vue-router";
import NoteCard from "@/components/slots/NoteCard.vue";
import CandidateDetailsCard from "@/components/slots/CandidateDetailsCard.vue";

const route = useRoute();

const candidatesStore = useCandidateStore();
const candidate = computed<Candidate>(() => candidatesStore.getInterviewedCandidateById(Number(route.params.candidateId)));

onMounted(() => {
    chartData.value = setChartData();
    chartOptions.value = setChartOptions();
});

const chartData = ref();
const chartOptions = ref();

const editorValue = ref('');

const transcriptModalVisible = ref(false);
const SummaryModalVisible = ref(false);

const setChartData = () => {
    return {
        labels: ['Programming Languages', 'Development Tools', 'Testing and Debugging', 'Version Control', 'API and Integrations', 'Mobile UI/UX Design'],
        datasets: [
            {
                label: 'Job requirement',
                borderColor: '#47BAFB',
                backgroundColor: 'rgba(71, 186, 251, 0.24)',
                pointBackgroundColor: '#47BAFB',
                pointHoverBackgroundColor: 'red',
                pointHoverBorderColor: 'red',
                data: [65, 59, 90, 81, 56, 55]
            },
            {
                label: 'Candidate stats',
                borderColor: '#9B51E0',
                backgroundColor: 'rgba(155, 81, 224, 0.24',
                pointBackgroundColor: '#9B51E0',
                pointHoverBackgroundColor: 'red',
                pointHoverBorderColor: 'red',
                data: [28, 48, 40, 19, 96, 27]
            }
        ]
    };
};
const setChartOptions = () => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--p-text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--p-text-muted-color');

    return {
        plugins: {
            legend: {
                labels: {
                    color: textColor
                }
            }
        },
        scales: {
            r: {
                grid: {
                    color: textColorSecondary
                }
            }
        }
    };
}
</script>

<style scoped></style>