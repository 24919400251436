<template>
    <header class="w-full l-section-background-color">
        <div class="sub-navbar flex-wrap flex-col sm:flex-row py-4 sm:py-8">
            <div v-if="$slots.title" class="paragraph-title text-center sm:text-left">
                <slot name="title"></slot>
            </div>
            <div v-if="$slots.buttonGroup" class="button-group flex items-center">
                <slot name="buttonGroup"></slot>
            </div>
        </div>
    </header>
</template>

<script setup lang="ts">

</script>

<style scoped>
header.l-section-background-color {
  box-shadow: var(--p-card-shadow, 0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1));
}


.sub-navbar {
    /* padding: 26px 108px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
    /* padding: 26px 0; */
}

.paragraph-title {
    position: relative;
    font-size: 20px;
    line-height: 38px;
    font-weight: 500;
    /*text-align: start;*/
    padding-left: 108px;
}

.button-group {
    padding-right: 108px;
}

@media (max-width: 640px) {
    .paragraph-title,
    .button-group {
        padding-left: 24px;
        padding-right: 24px;
    }
}
</style>
