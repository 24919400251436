<template>
    <div
        class="self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-center text-13xl text-dark-background font-text-md-regular min-h-[100vh]">
        <div
            class="max-w-[1440px] w-full pt-[50px] pb-[100px] xl:px-[108px] flex flex-col flex flex-col justify-start gap-[38px]">
            <Tabs value="0">
                <TabList>
                    <Tab value="0">Schedule</Tab>
                    <Tab value="1">Result</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel value="0">
                        <p class="m-0">
                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                            laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
                            architecto beatae vitae dicta sunt explicabo. Nemo enim
                            ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur
                            magni dolores eos qui ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed
                            quia non numquam eius modi.
                        </p>
                    </TabPanel>
                    <TabPanel value="1">
                        <ListResultTable />
                    </TabPanel>
                </TabPanels>
            </Tabs>

        </div>
    </div>
</template>

<script setup lang="ts">
import ListResultTable from "@/components/ListResultTable.vue";
import Tabs from 'primevue/tabs';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabPanels from 'primevue/tabpanels';
import TabPanel from 'primevue/tabpanel';
</script>

<style scoped></style>
