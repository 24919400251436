//PrimeVue and Tailwind imports
import PrimeVue from 'primevue/config';
import { definePreset } from '@primevue/themes';
import Aura from '@primevue/themes/aura';
import './assets/main.css'
//Vue imports
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import Tooltip from 'primevue/tooltip';
import ToastService from 'primevue/toastservice';
// Add Google Analytics import
import VueGtag from 'vue-gtag'

import App from './App.vue'
import router from './router'
import { initializeConsent, initializeSentry } from './utils/cookieConsent'

// Create app
const app = createApp(App)

// Initialize Sentry with minimal tracking before mount
initializeSentry(app, router, false)

// Wrap initializeConsent in an async IIFE with setTimeout

initializeConsent(app, router)

// PrimeVue config
const MyPreset = definePreset(Aura, {
    semantic: {
        primary: {
            50: '{blue.50}',
            100: '{blue.100}',
            200: '{blue.200}',
            300: '{blue.300}',
            400: '{blue.400}',
            500: '{blue.500}',
            600: '{blue.600}',
            700: '{blue.700}',
            800: '{blue.800}',
            900: '{blue.900}',
            950: '{blue.950}'
        }
    }

})
app.use(PrimeVue, {
    theme: {
        preset: MyPreset,
        options: {
          darkModeSelector: '.dark-mode', // Use a class for toggling
          cssLayer: false,
        },
      },
})

// Register tooltip directive globally
app.directive('tooltip', Tooltip);

// Vue plugins
app.use(createPinia())
app.use(router)
app.use(ToastService)


// Mount app
app.mount('#app')
