<template>
  <Dialog :visible="visible" modal :closable="false" class="w-11/12 lg:w-4/5 xl:w-3/5 !max-w-[1000px] !max-h-[95%] overflow-y-auto profile-modal">
    <template #container="{ closeCallback }">
      <form @submit.prevent="saveProfile" class="flex flex-col gap-8 px-8 lg:px-24 pt-10 pb-4">
        <div class="flex flex-col gap-2">
            <h2 class="text-2xl font-bold mb-4 text-center lg:text-left">Profile</h2>
            <div class="flex flex-col gap-2">
              <div class="flex flex-col lg:flex-row gap-4">
                <div class="relative flex justify-center lg:justify-start w-full lg:w-auto">
                  <Avatar 
                    :image="recruiterDetails.avatarUrl ? recruiterDetails.avatarUrl : undefined" 
                    :icon="recruiterDetails.avatarUrl ? undefined : 'pi pi-user'"
                    shape="circle" 
                    class="!flex !bg-slate-200 !text-4xl"
                  />
                  <div class="avatar-overlay" @click="uploadAvatar(true)">
                    <i class="pi pi-camera"></i>
                  </div>
                </div>
                <div class="flex flex-col gap-4 grow">
                  <div class="flex grow flex-col lg:flex-row gap-4">
                    <div class="flex flex-col grow basis-2/5 gap-2">
                      <label for="nameFirst" class="my-paragraph-subtitle">
                        First Name <span class="text-red-500">*</span>
                      </label>
                      <InputText id="nameFirst" v-model="recruiterDetails.nameFirst" required />
                    </div>
                    <div class="flex flex-col grow basis-2/5 gap-2">
                      <label for="nameLast" class="my-paragraph-subtitle">
                        Last Name <span class="text-red-500">*</span>
                      </label>
                      <InputText id="nameLast" v-model="recruiterDetails.nameLast" required />
                    </div>
                  </div>
                  <div class="flex grow flex-col lg:flex-row gap-4">
                    <div class="flex flex-col grow basis-2/5 gap-2">
                      <label for="email" class="my-paragraph-subtitle">Email Address</label>
                      <InputText type="email" id="email" v-model="recruiterDetails.email" disabled />
                    </div>
                    <div class="flex flex-col grow basis-2/5 gap-2">
                      <label for="jobPosition" class="my-paragraph-subtitle">Job Position</label>
                      <InputText id="jobPosition" v-model="recruiterDetails.jobPosition" />
                    </div>
                  </div>
                </div>
              </div>

            </div>
        </div>
        <div class="flex flex-col gap-2">
            <h2 class="text-2xl font-bold mb-4  text-center lg:text-left">Company</h2>
            <div class="flex flex-col gap-2">
              <div class="flex flex-col lg:flex-row  gap-4 lg:items-center">
                <div class="relative flex justify-center lg:justify-start w-full lg:w-auto">
                  <Avatar 
                    :image="companyDetails.avatarUrl ? companyDetails.avatarUrl : undefined" 
                    :icon="companyDetails.avatarUrl ? undefined : 'pi pi-building'"
                    shape="circle" 
                    class="!flex !bg-slate-200 company-avatar"
                  />
                  <div class="avatar-overlay" @click="uploadAvatar(false)">
                    <i class="pi pi-camera"></i>
                  </div>
                </div>
                <div class="flex-grow">
                  <div class="flex flex-col grow gap-2">
                    <label for="companyName" class="my-paragraph-subtitle">
                      Company Name <span class="text-red-500">*</span>
                    </label>
                    <InputText id="companyName" v-model="companyDetails.name" required />
                  </div>
                </div>
              </div>
              <div class="flex gap-4 flex-col lg:flex-row ">
                <div class="flex flex-col grow basis-2/4 gap-2">
                  <label for="location" class="my-paragraph-subtitle">Location</label>
                  <InputText id="location" v-model="companyDetails.location" />
                </div>
                <div class="flex flex-col grow basis-2/4 gap-2">
                  <label for="industry" class="my-paragraph-subtitle">Industry</label>
                  <InputText id="industry" v-model="companyDetails.industry" />
                </div>
              </div>
              <div class="flex gap-4 flex-col lg:flex-row ">
                <div class="flex flex-col grow basis-2/4 gap-2">
                  <label for="website" class="my-paragraph-subtitle">
                    Website <span class="text-red-500">*</span>
                  </label>
                  <InputText id="website" v-model="companyDetails.website" required />
                </div>
                <div class="flex flex-col grow basis-2/4 gap-2">
                  <label for="companyEmail" class="my-paragraph-subtitle">Company Email</label>
                  <InputText type="email" id="companyEmail" v-model="companyDetails.email" />
                </div>
              </div>
            </div>
            <div class="flex justify-end gap-4 mt-6 flex-wrap">
              <div class="flex flex-row gap-4 w-full justify-end">
                <Button 
                  label="Close" 
                  severity="secondary" 
                  type="button"
                  outlined
                  rounded
                  @click="$emit('close')"
                  :disabled="isSaving || !props.closeable"
                  :title="props.closeable ? 'Close' : 'please fill in all required fields'"
                />
                <Button 
                  label="Save changes"
                  type="submit" 
                  rounded
                  :loading="isSaving"
                  :disabled="isSaving"
                />
              </div>
              <div class="w-full flex justify-end">
                <div :class="!props.closeable ? 'visible' : 'invisible'">
                  <span class="text-sm text-gray-500">Please fill in all required fields*</span>
                </div>
              </div>
            </div>
        </div>

      </form>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import {ref, watch} from 'vue';
import { useToast } from 'primevue/usetoast';
import Dialog from 'primevue/dialog';
import Avatar from 'primevue/avatar';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { useRecruiterStore, type CompanyProfile, type UserProfile } from '@/stores/recruiterStore';

const toast = useToast();
const recruiterStore = useRecruiterStore();


const recruiterDetails = ref<UserProfile>(recruiterStore.recruiter);
const companyDetails = ref<CompanyProfile>(recruiterStore.recruiterCompany);

const isSaving = ref(false);
const unsaved = ref(false);

const emit = defineEmits<{
  /**
   * Emitted when the modal should be closed
   */
  (event: 'close'): void
  /**
   * Emitted when the profile is saved
   */
  (event: 'saved'): void
}>();


const props = defineProps({
  closeable: {
    type: Boolean,
    default: false
  },
    visible: {
    type: Boolean,
    default: false
  },
})

const uploadAvatar = (recruiter: boolean) => {
  const input = document.createElement('input');
  input.type = 'file';
  input.accept = 'image/*';
  
  input.onchange = (e) => {
    const file = (e.target as HTMLInputElement).files?.[0];
    if (file) {
      if(recruiter) {
        recruiterDetails.value.avatarUrl = URL.createObjectURL(file);
        recruiterDetails.value.avatarFile = file;
      } else {
        companyDetails.value.avatarUrl = URL.createObjectURL(file);
        companyDetails.value.avatarFile = file;
      }
    }
  };
  input.click();
};

const saveProfile = async () => {
  if (isSaving.value) return;
  
  isSaving.value = true;
  try {
    if (companyDetails.value.website && !companyDetails.value.website.match(/^https?:\/\//)) {
      companyDetails.value.website = 'https://' + companyDetails.value.website;
    }
    await recruiterStore.updateUserProfile(recruiterDetails.value);
    await recruiterStore.updateUserCompany(companyDetails.value);
    toast.add({ severity: 'success', summary: 'Success', detail: 'Profile saved successfully', life: 3000 });
    unsaved.value = false;
    emit('saved');
  } catch (error) {
    toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to save profile', life: 3000 });
  } finally {
    isSaving.value = false;
  }
};

watch(() => [recruiterDetails.value, companyDetails.value], ()=>{ unsaved.value=true })
</script>

<style>
.avatar-overlay {
  position: absolute;
  width: 150px;
  height: 150px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor: pointer;
  }

.p-avatar-icon {
  font-size: 25px !important;
}

.avatar-overlay:hover {
  opacity: 1;
}

.avatar-overlay i {
  color: white;
  font-size: 2.5rem;
}

.profile-modal .company-avatar > img,
.profile-modal .p-avatar img,
.navbar-avatar > img,
.profile-modal .avatar > img {
  object-fit: cover;
  width: 150px !important;
  height: auto !important;
  border-radius: 0 !important;
}

.p-avatar-circle {
    border-radius: 50%;
    border: 1px #e2e8f0 solid;
    overflow: hidden;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.profile-modal .p-avatar-circle {
    width: 150px !important;
    height: 150px !important;
}
</style>
