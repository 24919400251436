<template>
    <div class="flex flex-col gap-8">
        <SectionHeader :is-sub-section="true" title-align="start">
            <template #title>
                Advanced Options
            </template>
        </SectionHeader>
        <div class="flex flex-col gap-6">
            <div class="flex flex-col gap-1">
                <ParagraphSection>
                    <template #title>
                        Salary
                    </template>
                </ParagraphSection>
                <div class="flex flex-col gap-4">
                    <div class="flex flex-wrap gap-4">
                        <div class="flex flex-col grow basis-2/5 gap-2">
                            <label for="minSal" class="flex align-center justify-between">
                                <div class="my-paragraph-subtitle">
                                    Min Annual Salary USD
                                </div>
                                <InputNumber size="small" mode="currency" currency="USD" id="minSal"
                                    v-model="advancedOptions.salary[0]"
                                    :max="advancedOptions.salary[1]" />
                            </label>
                        </div>
                        <div class="flex flex-col grow basis-2/5 gap-2">
                            <label for="maxSal" class="flex align-center justify-between">
                                <div class="my-paragraph-subtitle">
                                    Max Annual Salary USD
                                </div>
                                <InputNumber size="small" mode="currency" currency="USD" id="maxSal"
                                    v-model="advancedOptions.salary[1]" :max="500000" />
                            </label>
                        </div>
                    </div>
                    <Slider v-model="advancedOptions.salary" :min="0" :max="500000" range :step="1" />
                </div>
            </div>
            <div class="flex flex-col gap-1">
                <ParagraphSection>
                    <template #title>
                        Status
                    </template>
                </ParagraphSection>
                <div class="flex flex-wrap gap-4">
                    <div class="flex flex-col sm:basis-2/5 gap-2 w-full">
                        <label for="jobStatus" class="my-paragraph-subtitle">
                            Currently the job is
                        </label>
                        <Select id="jobStatus" v-model="advancedOptions.jobStatus" :options="jobStatus"
                            optionLabel="display" optionValue="value" placeholder="Select" />
                    </div>
                </div>
            </div>
            <Divider />
            <div class="flex flex-col gap-6">
                <div class="flex flex-col gap-2">
                    <ParagraphSection>
                        <template #title>
                            AI Preselect / Reject
                        </template>
                        <template #smallText>
                            Automatically update candidate statuses to AI-Preselect and AI
                            Reject based on their scores. No automatic emails will be sent to
                            candidates. You have the option to review these candidates and
                            change
                            their status to any other if needed. Default values are 87 and 65.
                        </template>
                    </ParagraphSection>
                </div>
                <div>
                    <Checkbox v-model="advancedOptions.inviteAi" inputId="inviteAI" :binary="true" />
                    <label for="inviteAI" class="ml-2">
                        Automatically invite for AI interview
                    </label>
                </div>
                <div class="flex flex-wrap gap-4">
                    <div class="flex flex-col grow basis-2/5 gap-2">
                        <label for="preselectGreat" class="my-paragraph-subtitle flex justify-between">
                            <div class="my-paragraph-subtitle">
                                AI-Preselect if the score is greater than
                            </div>
                            <div class="my-paragraph-subtitle">
                                {{ advancedOptions.aiPreselectGreat }}
                            </div>
                        </label>
                        <Slider id="preselectGreat" v-model="advancedOptions.aiPreselectGreat" />
                    </div>
                    <div class="flex flex-col grow basis-2/5 gap-2">
                        <label for="preselectLess" class="flex justify-between">
                            <div class="my-paragraph-subtitle">
                                AI-Preselect if the score is less than
                            </div>
                            <div class="my-paragraph-subtitle">
                                {{ advancedOptions.aiPreselectLess }}
                            </div>
                        </label>
                        <Slider id="preselectLess" v-model="advancedOptions.aiPreselectLess" />
                    </div>
                </div>
            </div>
            <Divider />
            <div class="flex flex-col gap-6">
                <div class="flex flex-col gap-2">
                    <ParagraphSection>
                        <template #title>
                            AI prompt customisation
                        </template>
                        <template #smallText>
                            Adjust the candidates AI Evaluation Score by adding your
                            personalized prompt. This prompt will serve as an additional
                            instruction without overriding the default evaluation criteria that
                            candidate should match the job description. Please be specific in
                            your instructions. This will be applied to all candidates for this
                            job. Leave it blank to use default.
                        </template>
                    </ParagraphSection>
                </div>
                <div>
                    <Textarea id="aiScorePrompt" class="w-full" rows="4" :placeholder="placeholders.prompt"
                        v-model="advancedOptions.aiScorePrompt" />
                </div>
            </div>
            <Divider />
            <div class="flex flex-col gap-6">
                <div class="flex flex-col gap-4">
                    <ParagraphSection>
                        <template #title>
                            Email template to contact candidate
                        </template>
                        <template #smallText>
                            <div class="flex flex-col gap-6">
                                <p>
                                    Once you've preselected some candidates, you'll need to
                                    contact out
                                    to them, for instance, to invite them for an interview or to
                                    assign
                                    them a test task.
                                </p>
                                <p>
                                    This template functions as a 'mailto' link. Clicking on an
                                    candidate's email will launch your default email client,
                                    automatically populating it with the candidate's email
                                    address, as
                                    well as the subject and body text provided below.
                                </p>
                                <p>
                                    The template supports variables: {first_name}, {company},
                                    {job_position}.
                                </p>
                            </div>
                        </template>
                    </ParagraphSection>
                </div>
                <div class="flex flex-wrap grow gap-2">
                    <label for="emailSubject" class="my-paragraph-subtitle">Subject</label>
                    <InputText id="emailSubject" class="w-full" rows="4" :placeholder="placeholders.subject"
                        v-model="advancedOptions.emailSubject" />
                </div>
                <div class="flex flex-wrap grow gap-2">
                    <label for="emailTemplate" class="my-paragraph-subtitle">Email Template</label>
                    <Textarea id="emailTemplate" class="w-full" rows="4" :placeholder="placeholders.template"
                        v-model="advancedOptions.emailTemplate" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue';
import ParagraphSection from '@/components/slots/ParagraphSection.vue';
import SectionHeader from '@/components/slots/SectionHeader.vue';
import Checkbox from 'primevue/checkbox';
import Divider from 'primevue/divider';
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import Slider from 'primevue/slider';
import Textarea from 'primevue/textarea';
import InputNumber from 'primevue/inputnumber';
import { type AdvancedOptionsForm } from '@/stores/jobStore';

const advancedOptions = defineModel<AdvancedOptionsForm>('advancedOptions', {required: true})

//Placeholders
const placeholders = reactive({
    prompt: "Score candidate much higher if the candidate has some blockchain or financial markets experience. Candidate with 6-24 month blockchain or financial markets experience is the ideal fit.",
    subject: "Invitation to Interview for the {job_position} Position at {company}",
    template: "Hello {first_name}, Thank you for your application for the {job_position} role at {company}. We would like to invite you for an interview. Could you please schedule a time for a screening interview using this link? [INSERT YOUR CALENDAR LINK] Thank you, {company}."
})

const jobStatus = [
    { display: 'Open', value: 'open' },
    { display: 'Closed', value: 'closed' }
]
</script>
