<template>
     <div class="flex space-x-4 w-full">
    <div class="w-1/2">
      <!-- First item goes here -->
      <slot name="first"></slot>
    </div>
    <div class="w-1/2">
      <!-- Second item goes here -->
      <slot name="second"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped>

</style>