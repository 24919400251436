<script setup lang="ts">
import {ref} from 'vue';
import Carousel from 'primevue/carousel';
import CenterTitle from './slots/CenterTitle.vue';
import screening from '@/assets/img/screening.png'
import invite from '@/assets/img/invite.png'
import strengths from '@/assets/img/strengths.png'

const products = ref([{
    name: 'Automated Screening',
    description: 'Let haier scan resumes, extract key information and generate a report for you.',
    image: screening,
}, {
    name: 'Automated Interviewing',
    description: 'Invite your candidates to a virtual interview and let them answer all your questions at their own pace.',
    image: invite,
},
    {
        name: 'Automated Reporting',
        description: 'Get a comprehensive report of your hiring process, resume and interview, including candidate strengths and weaknesses.',
        image: strengths,

    }
]);
</script>
<template>
    <Carousel
        :showNavigators="false"
        :value="products"
        :numVisible="1"
        :autoplayInterval="4000"
        :numScroll="1"
        orientation="horizontal"
        verticalViewPortHeight="100%"
        containerClass="flex items-center"
        class="max-w-full"
    >
        <template #item="slotProps">
  <div class="px-8 py-4">
    <div class="dark:border-surface-700 rounded m-2 px-4">
      <div class="mb-4 flex justify-center"> <!-- Added flex and justify-center -->
        <div class="relative">
          <img
            :src="slotProps.data.image"
            :alt="slotProps.data.name"
            class="w-full rounded max-w-[600px]"
          />
        </div>
      </div>
    </div>
    <div class="my-4 text-white">
      <CenterTitle>
        <template #title>{{ slotProps.data.name }}</template>
        <template #description>
          <div class="sans-serif">{{ slotProps.data.description }}</div>
        </template>
      </CenterTitle>
    </div>
  </div>
</template>

    </Carousel>

</template>

<style>
.p-carousel-content {
    /* Add your custom styles here */
    max-width: 100%;
}
</style>