import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(localizedFormat)

export function formatDate_LL(date: Date) {
    return dayjs(date).format('LL')
}

export function formatDate_lll(date: Date) {
    return dayjs(date).format('lll')
}