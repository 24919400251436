<script setup lang="ts">
import { ref, watch, computed } from "vue";
import { useRouter, useRoute } from 'vue-router';
import CenterTitle from "./slots/CenterTitle.vue";
import AuthInput from "./slots/AuthInput.vue";
import InputText from 'primevue/inputtext';
import InputOtp from 'primevue/inputotp';
import Button from 'primevue/button';
import { sendOTP, confirmOTP } from "@/api_client";
import { useRecruiterStore } from "@/stores/recruiterStore";
import HaierLogo from "@/components/HaierLogo.vue";

const isCodeSent = ref(false)
const loading = ref(false)
const isLoggingIn = ref(false) // New reactive variable for logging in status
const inputEmail = ref<string>('')
const inputCode = ref<number | undefined>(); // Update type hint to include undefined

// Watch for changes in inputCode
watch(inputCode, (newValue) => {
    if (newValue && newValue.toString().length === 6) {
        handleVerifyOTP(); // Automatically call handleVerifyOTP when inputCode is filled and has 6 digits
    }
});

const recruiterStore = useRecruiterStore()
const router = useRouter() // Initialize router
const route = useRoute() // Initialize route

const countdown = ref(0);
const canResend = computed(() => countdown.value === 0);

function startCountdown(): void {
    countdown.value = 30;
    const timer = setInterval(() => {
        countdown.value--;
        if (countdown.value === 0) {
            clearInterval(timer);
        }
    }, 1000);
}

async function handleSendOTP() {
    loading.value = true
    if (!inputEmail.value) {
        isCodeSent.value = false
        loading.value = false
        return null
    }

    try {
        const res = await sendOTP(inputEmail.value);
        // console.log(res);
        if (res.status === 200) {
            isCodeSent.value = true;
            startCountdown(); // Start the countdown after successful OTP send
        }
    } catch (error) {
        console.error('Error:', error);
    } finally {
        loading.value = false;
    }
}
async function handleVerifyOTP() {
    isLoggingIn.value = true; // Set logging in status to true
    //TODO handle first time user by adding create profile page
    if (!inputEmail.value || !inputCode.value) {
        //TODO show missing details message
        isLoggingIn.value = false; // Reset logging in status
        return null
    }

    try {
        const res = await confirmOTP(inputCode.value, inputEmail.value);
        if (res.status === 200) {
            recruiterStore.recruiterToken = res.data.access_token
            recruiterStore.login()
            // Check for the next parameter in the URL
            const nextRoute = (route.query.next || '/') as string; // Default to '/' if no next parameter
            router.push(nextRoute); // Navigate to the next route
        }
    } catch (error) {
        console.error('Error:', error);
    } finally {
        isLoggingIn.value = false; // Reset logging in status
    }
}
</script>

<template>
    <div class="flex justify-center mb-4">
      <div class="flex justify-start items-center">
        <HaierLogo class="w-64 pb-4"/>
      </div>
    </div>
    <div class="w-[500px] flex flex-col items-start justify-start gap-[50px] max-w-full mq750:gap-[25px]">
        <!-- TODO Make this page unaccessible when user is authenticated -->
        <template v-if="!isCodeSent">
            <CenterTitle>
                <template #description>You're just a step away from becoming a 10x recruiter.</template>
            </CenterTitle>
            <AuthInput>
                <template #input>
                    <label for="email">Enter your email</label>
                    <InputText id="email" v-model="inputEmail" placeholder="hello@example.com" type="email" />
                </template>
                <template #button>
                    <Button
                        type="submit"
                        :label="loading ? 'Sending verification code...' : 'Send verification code'"
                        :loading="loading"
                        :disabled="!inputEmail || loading"
                        class="self-stretch"
                        severity="primary"
                        rounded
                        @click.prevent="handleSendOTP" />
                </template>
            </AuthInput>
        </template>
        <template v-else>
            <CenterTitle>
                <template #title>Verification code</template>
                <template #description>We have sent the code to your email.</template>
            </CenterTitle>
            <AuthInput>
                <template #input>
                    <InputOtp style="align-self: center;" :length="6" v-model="inputCode" integerOnly />
                </template>
                <template #button>
                    <Button class="self-stretch justify-center" 
                            :label="isLoggingIn ? 'Logging in...' : 'Verify'" 
                            :disabled="isLoggingIn" 
                            rounded
                            @click="handleVerifyOTP" />
                            <div class="mt-4 text-center mx-auto">
                        <Button link
                                :disabled="!canResend"
                                @click="handleSendOTP"
                                :label="canResend ? 'Send the code again' : `Send the code again in ${countdown}s`" />
                    </div>
                        </template>
            </AuthInput>
        </template>
    </div>
</template>
