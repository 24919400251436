<template>
  <div
    class="self-stretch flex flex-col items-center justify-start py-0 box-border max-w-full text-center">
    <SubNavbar>
          <template #title>Jobs at {{ recruiterStore.recruiterCompany.name }} </template>
      <template #buttonGroup>
        <Button label="Create Job" class="w-[200px]" rounded severity="primary" as="router-link" :to="{name: 'createJob'}" />
      </template>
    </SubNavbar>
    <div class="w-full max-w-[1440px] px-3 sm:px-4 md:px-6 lg:px-8 xl:px-[108px] flex-grow flex flex-col pt-4 sm:pt-8 mb-8 overflow-x-auto">
      <ListJobsTable :show-closed="showClosedJobs" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import ListJobsTable from "@/components/ListJobsTable.vue";
import SubNavbar from "@/components/slots/SubNavbar.vue";
import Button from "primevue/button";
import { useRecruiterStore } from "@/stores/recruiterStore";

const recruiterStore = useRecruiterStore();
const showClosedJobs = ref<boolean>(true);
</script>