import { createRouter, createWebHistory, type NavigationGuardNext, type RouteLocationNormalized } from 'vue-router'
import AuthPage from '@/views/AuthPage.vue'
import ListJobsPage from '@/views/ListJobsPage.vue'
import InterviewsPage from '@/views/InterviewsPage.vue'
import InterviewResultPage from '@/views/InterviewResultPage.vue'
import CreateJobPage from '@/views/CreateJobPage.vue'
import CreateCandidatePage from '@/views/CreateCandidatePage.vue'
import ListCandidatesPage from '@/views/ListCandidatesPage.vue'
import JobPage from '@/views/JobPage.vue'
import { useRecruiterStore } from "@/stores/recruiterStore"; // Import the recruiter store
import JobApplicationSubmission from '@/views/JobApplicationSubmission.vue'
import { useConfigStore } from '@/stores/configStore'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      meta: { requiresAuth: true },
      redirect: '/jobs' 
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/DashboardPage.vue'),
      meta: {
        requiresAuth: true,
        requiresNavbar: true,
        showInNavbar: () => useConfigStore().switches.ENABLE_DASHBOARD_BUTTON_IN_NAVBAR,
        navOptions: {
          label: 'Dashboard',
          path: '/dashboard',
        }
      }
    },
    {
      path: '/auth',
      name: 'auth',
      component: AuthPage,
      meta: { requiresNavbar: false },
    },
    {
      path: '/jobs',
      name: 'jobs',
      component: ListJobsPage,
      meta: {
        requiresAuth: true,
        requiresNavbar: true,
        showInNavbar: true,
        navOptions: {
          label: 'Jobs',
          path: '/jobs'
        }
      }
    },
    {
      path: '/jobs/:jobId',
      name: 'jobPage',
      component: JobPage,
      meta: { requiresAuth: false, requiresNavbar: true }
    },
    {
      path: '/jobs/create',
      name: 'createJob',
      component: CreateJobPage,
      meta: { requiresAuth: true, requiresNavbar: true }
    },
    {
      path: '/jobs/:jobId/interviews',
      name: 'interviews',
      component: InterviewsPage,
      meta: {
        requiresAuth: true,
        requiresNavbar: true,
        showInNavbar: true,
        navOptions: {
          label: 'Interviews',
          path: '/interviews',
          disabled: () => !useConfigStore().switches.ENABLE_INTERVIEWS_BUTTON_IN_NAVBAR
        },
      }
    },
    {
      path: '/jobs/:jobId/interviews/:candidateId',
      name: 'result',
      component: InterviewResultPage,
      meta: { requiresAuth: true, requiresNavbar: true }
    },
    {
      path: '/candidates',
      name: 'candidates',
      component: ListCandidatesPage,
      meta: {
        requiresAuth: true,
        requiresNavbar: true,
        showInNavbar: true,
        navOptions: {
          label: 'Candidates',
          path: '/candidates'
        }
      }
    },
    {
      path: '/candidates/create',
      name: 'candidateCreate',
      component: CreateCandidatePage,
      meta: { requiresAuth: true, requiresNavbar: true }
    },
    {
      path: '/jobs/:id/apply',
      name: 'apply',
      component: JobApplicationSubmission,
      meta: { requiresAuth: false }
    },
    {
      path: '/jobs/:jobId/success',
      name: 'success',
      component: () => import('@/views/ApplicationSubmitted.vue'),
      meta: { requiresAuth: false }
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/ProfilePage.vue'),
      meta: { requiresAuth: true, requiresNavbar: true }
    },
    {
      path: '/pricing',
      name: 'pricing',
      component: () => import('@/views/PricingPage.vue'),
      meta: { requiresAuth: true, requiresNavbar: true }
    }
  ]
})

/**
 * Global navigation guard to check authentication
 * @param {RouteLocationNormalized} to - Target route object being navigated to
 * @param {RouteLocationNormalized} from - Current route being navigated away from
 * @param {NavigationGuardNext} next - Function to resolve the hook
 */
router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const recruiterStore = useRecruiterStore();
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresNavbar = to.matched.some(record => record.meta.requiresNavbar);
  if (requiresNavbar && !recruiterStore.isAuthenticated) {
    to.meta.requiresNavbar = false;
  }
  if (requiresAuth && !recruiterStore.isAuthenticated) {
    next({ name: 'auth', query: { next: to.fullPath } }); // Redirect to auth with next URL
  } else if (recruiterStore.isAuthenticated && to.name == 'auth') {
    next({ name: 'home' });
  } else {
    next();
  }
});

export default router
