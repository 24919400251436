<template>
    <div class="flex flex-col items-center justify-center box-border max-w-full">
        <SubNavbar>
            <template #title>
                Add a new candidate for:
            </template>
            <template #buttonGroup>
                <Select 
                    ref="jobSelect"
                    v-model="selectedJob" 
                    :options="jobs" 
                    optionLabel="title" 
                    optionValue="id" 
                    @change="updateJobQuery" 
                    placeholder="Select a job"
                    class="w-[300px]"
                    :class="{ 'highlight-select': showSelectHighlight }"
                />
            </template>
        </SubNavbar>
        <div class="px-4 max-w-[1440px] w-full pt-[50px] pb-[100px] xl:px-[108px] min-h-[90vh] flex flex-col items-center">
            <CandidateApplicationForm
                v-model="newCandidateForm"
                :loading="loading"
                @submit="handleSubmit"
                @fileSelected="filePDF = $event"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useJobStore } from '@/stores/jobStore';
import { useCandidateStore, type Candidate } from '@/stores/candidateStore';
import SubNavbar from '@/components/slots/SubNavbar.vue';
import Select from 'primevue/select';
import CandidateApplicationForm from '@/components/candidate/CandidateApplicationForm.vue';
import { submitApplication, type Application } from '@/api_client';
import { useToast } from 'primevue/usetoast';

const route = useRoute();
const router = useRouter();

const jobStore = useJobStore()
const candidateStore = useCandidateStore()
const candidates = ref<Candidate[]>()

const jobs = computed(() => jobStore.jobs)
const selectedJob = ref()
const isQueried = ref(false)

const updateJobQuery = () => {
    router.push({ name: 'candidateCreate', query: { job: selectedJob.value } });
    candidates.value = candidateStore.getCandidatesByJobId(selectedJob.value) as Candidate[];
    isQueried.value = true
};

const jobSelect = ref();
const showSelectHighlight = ref(false);

onMounted(async () => {
    await jobStore.init()
    const jobId = route.query.job;
    if (jobId && typeof jobId === 'string') {
        isQueried.value = true
        selectedJob.value = parseInt(jobId);
        candidates.value = candidateStore.getCandidatesByJobId(selectedJob.value) as Candidate[];
    } else {
        isQueried.value = false
    }
});

const newCandidateForm = ref({
    fullname: '',
    email: '',
    phone: '',
    linkedin: '',
});

const filePDF = ref<File>();

const isFormFilled = computed(() => {
    return Object.values(newCandidateForm.value).every(field => field.trim() !== '') && filePDF.value !== undefined;
})

const loading = ref(false);

const toast = useToast();

const handleSubmit = async (): Promise<void> => {
    if (!selectedJob.value) {
        showSelectHighlight.value = true;
        jobSelect.value.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        setTimeout(() => {
            showSelectHighlight.value = false;
        }, 2000);
        return;
    }

    if (isFormFilled.value) {
        loading.value = true;
        const formData = <Application>{
            resume: filePDF.value,
            full_name: newCandidateForm.value.fullname,
            email: newCandidateForm.value.email,
            phone: newCandidateForm.value.phone,
            linkedin: newCandidateForm.value.linkedin,
            job: Number(selectedJob.value)
        }
        try {
            const res = await submitApplication(formData);
            console.log(res);

            toast.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Candidate application submitted successfully',
                life: 3000
            });

            setTimeout(() => {
                router.push({
                    name: 'candidates',
                    query: { job: selectedJob.value }
                });
            }, 3000);

        } catch (err) {
            console.error(err);
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to submit application',
                life: 3000
            });
        } finally {
            loading.value = false;
        }
    }
}
</script>

<style scoped>
.highlight-select {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(93, 156, 236, 0.8);
        transform: scale(1);
    }
    50% {
        box-shadow: 0 0 20px 10px rgba(93, 156, 236, 0.4);
        transform: scale(1.02);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(93, 156, 236, 0);
        transform: scale(1);
    }
}
</style>
