<template>
  <div class="self-stretch flex flex-col items-center justify-start py-0 box-border w-full text-center h-full">
    <SubNavbar>
      <template #title>
        <div class="flex items-center gap-4">
          <div class="text-[#040813] text-lg sm:text-xl font-medium font-['Sora'] leading-7">Candidates</div>
          <div v-if="selectedJob !== 'all'" class="hidden sm:flex items-center gap-2">
            <Button
              outlined
              size="small"
              rounded
              icon="pi pi-list"
              title="List View"
              :class="{
                'view-mode-btn': true,
                'view-mode-btn--active': viewMode === 'list'
              }"
              @click="viewMode = 'list'"
            />
            <Button
              outlined
              size="small"
              rounded
              icon="pi pi-th-large"
              title="Kanban View"
              :class="{
                'view-mode-btn': true,
                'view-mode-btn--active': viewMode === 'kanban'
              }"
              @click="viewMode = 'kanban'"
            />
          </div>
        </div>
      </template>
      <template #buttonGroup>
        <div class="sm:block hidden flex items-center sm:space-x-4">
          <Select
            v-model="selectedJob"
            :options="jobOptions"
            optionLabel="title"
            optionValue="id"
            @change="updateJobQuery"
            @click="handleSelectClick"
            placeholder="Select a job"
            class="w-[300px]"
            :loading="jobLoading"
          />
          <Button
            label="Add applicant"
            rounded
            severity="primary"
            @click="createNewApplicant"
            class="w-[200px]"
          />
        </div>
        <div class="sm:hidden block flex flex-col items-center gap-4">
          <Select
            v-model="selectedJob"
            :options="jobOptions"
            optionLabel="title"
            optionValue="id"
            @change="updateJobQuery"
            placeholder="Select a job"
            class="w-[300px] mx-auto"
          />
          <Button
            label="Add applicant"
            rounded
            severity="primary"
            @click="createNewApplicant"
            :disabled="!selectedJob"
            class="w-[200px]"
            v-tooltip.bottom="!selectedJob ? 'Please select a job to add a new applicant to it' : ''"
          />
        </div>
      </template>
    </SubNavbar>

    <div class="w-full max-w-[1440px] px-3 sm:px-4 md:px-6 lg:px-8 xl:px-[108px] flex-grow flex flex-col pt-4 sm:pt-8 mb-8" v-if="!showKanban">
      <div v-if="!candidatesLoading && candidates.length === 0" class="empty-state h-[calc(100vh-200px)] flex flex-col items-center justify-center w-full">
        <img src="@/assets/empty-illustration.svg" alt="No applicants" class="empty-illustration" />
        <p class="empty-message text-center w-full max-w-md mx-auto">No applicants yet for this job! Start adding candidates to view them here.</p>
        <div class="flex justify-center w-full max-w-md mx-auto px-4">
          <Button 
            class="h-[40px] sm:h-[46px] px-3 sm:px-4 py-2 sm:py-2.5 bg-[#0066ff] rounded-[100px] flex justify-center items-center gap-1.5 sm:gap-2 text-sm sm:text-base w-full md:w-auto min-w-[200px]"
            :disabled="!selectedJob || selectedJob === 'all'"
            @click="createNewApplicant"
            v-tooltip.bottom="!selectedJob || selectedJob === 'all' ? 'Please select a specific job to add a new applicant to it' : ''"
            unstyled
          >
            <i class="pi pi-plus text-white text-lg sm:text-xl"></i>
            <span class="text-white font-medium font-['Sora'] leading-relaxed tracking-tight">Add Applicant</span>
          </Button>
        </div>
      </div>
      <ListCandidatesTable 
        v-else
        v-model:candidates="candidates"
        :loading="candidatesLoading"
      />
    </div>
    
    <div v-else class="w-full flex-grow flex flex-col px-3 sm:px-4 md:px-6 lg:px-8 xl:px-[108px] pt-4 sm:pt-8 mb-8">
      <CandidateKanbanView 
        :candidates="candidates"
        @switch-view="viewMode = 'list'"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter, useRoute } from 'vue-router';
import ListCandidatesTable from "@/components/ListCandidatesTable.vue";
import CandidateKanbanView from "@/components/CandidateKanbanView.vue";
import SubNavbar from "@/components/slots/SubNavbar.vue";
import { useJobStore, type Job } from "@/stores/jobStore";
import { useCandidateStore, type Candidate } from '@/stores/candidateStore';
import Button from "primevue/button";
import Select from "primevue/select";
import { ref, computed, onMounted, watch } from 'vue';
import { useLocalStorage } from '@vueuse/core';

const router = useRouter();
const route = useRoute();
const jobStore = useJobStore();
const candidateStore = useCandidateStore();

const viewMode = useLocalStorage('candidates-view-mode', 'list');
const showKanban = computed(() => {
  const isMobile = window.innerWidth < 640;
  return viewMode.value === 'kanban' && selectedJob.value !== 'all' && !isMobile;
});

const jobLoading = computed(()=>(jobStore.loading))
const candidatesLoading = ref(true);
const selectedJob = ref<number | 'all'>('all');
const currentJob = ref<Job | null>(null);

const jobOptions = computed(() => {
  const allJobsOption = { id: 'all', title: 'All Jobs' };
  if (jobStore.jobs.length > 0) {
    return [allJobsOption, ...jobStore.jobs.map(job => ({ id: job.id, title: job.title }))];
  }
  if (currentJob.value) {
    return [allJobsOption, { id: currentJob.value.id, title: currentJob.value.title }];
  }
  return [allJobsOption];
});

const candidates = computed(() => {
  if (selectedJob.value === 'all') {
    return candidateStore.candidates;
  }
  return candidateStore.candidates.filter(
    candidate => candidate.job_id === selectedJob.value
  );
});

// const fetchJobsData = async () => {
//   try {
//     await jobStore.fetchJobsRecruiter();
//   } finally {
//     jobLoading.value = false;
//   }
// };

onMounted(async () => {
  try {
    jobStore.init();
    const jobId = route.query.job;
    
    if (jobId && typeof jobId === 'string') {
      const parsedJobId = parseInt(jobId);
      selectedJob.value = parsedJobId;
      if (!candidateStore.fetchedJobIds.has(parsedJobId)) {
        try {
          currentJob.value = await jobStore.getJobById(parsedJobId);
          await candidateStore.fetchCandidatesByJobId(parsedJobId);
        } catch (error) {
          console.error('Error fetching job:', error);
        }
      }
    } else {
      selectedJob.value = 'all';
      if (candidateStore.candidates.length === 0) {
        await candidateStore.fetchAllCandidates();
        if (jobStore.jobs.length > 0) {
          const allJobIds = new Set(jobStore.jobs.map(job => job.id));
          candidateStore.trackJobsFromAllCandidates(allJobIds);
        }
      }
    }
  } catch (error) {
    console.error('Error initializing page:', error);
  } finally {
    candidatesLoading.value = false;
  }
});

const handleSelectClick = async () => {
  if (jobStore.jobs.length === 0) {
    currentJob.value = null;
    await jobStore.fetchJobsRecruiter();
  }
};

const shouldFetchCandidates = computed(() => {
  if (selectedJob.value === 'all') {
    return candidateStore.candidates.length === 0;
  }
  return !candidateStore.fetchedJobIds.has(selectedJob.value) && 
         !candidateStore.emptyJobs.has(selectedJob.value);
});

const updateJobQuery = async () => {
  try {
    candidatesLoading.value = true;
    if (selectedJob.value === 'all') {
      await router.push({ name: 'candidates' });
      // Always fetch all candidates when switching to "all" view
      await candidateStore.fetchAllCandidates();
      if (jobStore.jobs.length > 0) {
        const allJobIds = new Set(jobStore.jobs.map(job => job.id));
        candidateStore.trackJobsFromAllCandidates(allJobIds);
      }
    } else {
      await router.push({ name: 'candidates', query: { job: selectedJob.value } });
      if (candidateStore.emptyJobs.has(selectedJob.value)) {
        return;
      }
      if (shouldFetchCandidates.value) {
        await candidateStore.fetchCandidatesByJobId(selectedJob.value);
      }
    }
  } finally {
    candidatesLoading.value = false;
  }
};

const createNewApplicant = () => {
  if (selectedJob.value !== 'all') {
    router.push({ name: 'candidateCreate', query: { job: selectedJob.value } });
  }
};

// Force list view on mobile devices
watch(() => window.innerWidth, (width) => {
  if (width < 640 && viewMode.value === 'kanban') {
    viewMode.value = 'list';
  }
});

// Add resize listener
onMounted(() => {
  window.addEventListener('resize', () => {
    if (window.innerWidth < 640 && viewMode.value === 'kanban') {
      viewMode.value = 'list';
    }
  });
});
</script>

<style scoped>
.empty-state {
  min-height: 300px;
  padding: 1rem;
  text-align: center;
}

.empty-illustration {
  max-width: min(300px, 80vw);
  margin-bottom: 1rem;
  margin-inline: auto;
}

.empty-message {
  font-size: clamp(1rem, 2.5vw, 1.2em);
  margin-bottom: 1rem;
  padding: 0 1rem;
  text-align: center;
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

/* Add fade transition styles */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}


@media (max-width: 640px) {

   .empty-state {
    padding: 0.75rem;
  }

  .empty-message {
    font-size: 0.875rem;
    margin-bottom: 0.75rem;
  }
}

/* Add tablet styles */
@media (min-width: 641px) and (max-width: 1024px) {
  .empty-state {
    align-items: center;
  }

  .empty-illustration {
    max-width: 250px;
  }
}

.view-mode-btn {
  border: 1px solid #dee2e6;
  color: #6c757d;
  background: transparent;
  transition: all 0.2s ease;
}

.view-mode-btn:hover {
  background: rgba(0, 102, 255, 0.04);
  border-color: #0066ff;
  color: #0066ff;
}

.view-mode-btn--active {
  border-color: #0066ff;
  color: #0066ff;
}
</style>