<template>
    <div>
        <h1 :class="isSubSection?'subsection-title':'section-title'" class="text-figma-dark" :style="`text-align: ${titleAlign}`">
            <slot name="title"></slot>
        </h1>
        <p v-if="$slots.smallText" class="my-small-description pt-2">
            <slot name="smallText"></slot>
        </p>
    </div>
</template>

<script setup lang="ts">
defineProps({
    titleAlign: {
        type: String,
        required: true
    },
    isSubSection: {
        type: Boolean,
        default: false
    }
})
</script>

<style scoped>

</style>