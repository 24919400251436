<template>
    <Card>
        <template #title>
            <div class="flex flex-row items-center justify-between">
                <div class="flex flex-col">
                    <h1 class="text-figma-dark text-lg font-medium">
                        {{ note.writer }}
                    </h1>
                    <p class="text-sm text-neutral-500">
                        {{ note.last_updated }}
                    </p>
                </div>
                <Button outlined severity="secondary" icon="pi pi-pen-to-square"></Button>
            </div>
        </template>
        <template #content>
            <ParagraphSection>
                <template #smallText>
                    {{ note.note }}
                </template>
            </ParagraphSection>
        </template>
    </Card>
</template>

<script setup lang="ts">
import Button from 'primevue/button';
import Card from 'primevue/card';
import ParagraphSection from './ParagraphSection.vue';
import type { RecruiterNote } from '@/stores/candidateStore';

defineProps<{
    note: RecruiterNote
}>()

</script>

<style scoped></style>