import { fetchSignedInCompanyDetails, fetchSignedInUserDetails, patchCompanyProfile, patchUserProfile, type CompanyProfile_Server, type UserProfile_Server } from "@/api_client";
import router from "@/router";
import axios from "axios";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export type UserProfile = {
    email: string;
    nameFirst: string;
    nameLast: string;
    jobPosition: string;
    avatarUrl?: string;
    avatarFile?: File;
}

export type CompanyProfile = {
    name: string;
    location: string;
    description: string;
    industry: string;
    size: string;
    website: string;
    email: string;
    avatarUrl?: string;
    avatarFile?: File;
}

export const useRecruiterStore = defineStore('recruiter', () => {
    const isReady = ref(false)
    const recruiter = ref<UserProfile>({
        avatarUrl: '',
        nameFirst: '',
        nameLast: '',
        jobPosition: '',
        email: '',
    })
    const recruiterCompany = ref<CompanyProfile>({
        avatarUrl: '',
        name: '',
        location: '',
        industry: '',
        description: '',
        size: '',
        website: '',
        email: ''
    })
    const recruiterToken = ref('')
    const isLoading = ref(true)

    const isAuthenticated = computed(() => !!recruiterToken.value)

    const isProfileComplete = computed(() => {
        const mandatoryUserFields = ['nameFirst', 'nameLast', 'email']
        const mandatoryCompanyFields = ['name', 'website']
        return mandatoryUserFields.every(field => !!recruiter.value[field as keyof UserProfile]) &&
               mandatoryCompanyFields.every(field => !!recruiterCompany.value[field as keyof CompanyProfile])
    })

    async function init() {
        const token = localStorage.getItem('token');
        if (token) {
            recruiterToken.value = token
            axios.defaults.headers.common['Authorization'] = `${token}`;
        } else {
            delete axios.defaults.headers.common['Authorization']
        }
        if (isAuthenticated.value) {
            await getUserDetails()
            isReady.value = true
        }
    }
    async function login() {
        localStorage.setItem('token', recruiterToken.value)
        axios.defaults.headers.common['Authorization'] = `${recruiterToken.value}`
        await getUserDetails()
    }
    async function getUserDetails() {
        isReady.value = false
        await getProfileDetails()
        await getCompanyDetails()
        isLoading.value = false
        isReady.value = true
    }
    async function getProfileDetails() {
        const res = await fetchSignedInUserDetails()
        recruiter.value.email = res.data.email
        recruiter.value.nameFirst = res.data.first_name
        recruiter.value.nameLast = res.data.last_name
        recruiter.value.jobPosition = res.data.position
        recruiter.value.avatarUrl = res.data.profile_picture ? decodeURIComponent(res.data.profile_picture) : ''
        
    }
    async function getCompanyDetails() {
        const res = await fetchSignedInCompanyDetails()
        recruiterCompany.value.name = res.data.name
        recruiterCompany.value.location = res.data.location
        recruiterCompany.value.description = res.data.description
        recruiterCompany.value.industry = res.data.industry
        recruiterCompany.value.size = res.data.size
        recruiterCompany.value.website = res.data.url
        recruiterCompany.value.email = res.data.contact
        recruiterCompany.value.avatarUrl = res.data.logo ? decodeURIComponent(res.data.logo) : ''
    }

    async function updateUserProfile(profile: UserProfile) {
        recruiter.value = profile
        const requestData: UserProfile_Server = {
            first_name: profile.nameFirst,
            last_name: profile.nameLast,
            position: profile.jobPosition,
            email: profile.email,
        };
        
        if (profile.avatarFile) {
            requestData.profile_picture = profile.avatarFile;
        }
        
        const res = await patchUserProfile(requestData);
        if (res.data.profile_picture) {
            recruiter.value.avatarUrl = decodeURIComponent(res.data.profile_picture);
        }
    }
    async function updateUserCompany(company: CompanyProfile) {
        recruiterCompany.value = company
        const requestData: CompanyProfile_Server = {
            name: company.name,
            location: company.location,
            industry: company.industry,
            url: company.website ?? '',
            contact: company.email ?? '',
        };
        
        if (company.avatarFile) {
            requestData.logo = company.avatarFile;
        }
        
        const res = await patchCompanyProfile(requestData);
        if (res.data.logo) {
            recruiterCompany.value.avatarUrl = decodeURIComponent(res.data.logo);
        }
    }
    function logout() {
        // TODO after implementation logoutUser()
        // TODO call endpoint to logout in supabase
        localStorage.removeItem('key')
        localStorage.removeItem('token')
        delete axios.defaults.headers.common['Authorization']
        recruiter.value = {
            avatarUrl: '',
            nameFirst: '',
            nameLast: '',
            jobPosition: '',
            email: ''
        }
        recruiterCompany.value = {
            avatarUrl: '',
            name: '',
            location: '',
            industry: '',
            description: '',
            size: '',
            website: '',
            email: ''
        }
        recruiterToken.value = ''
        router.push({ name: 'auth' })
    }
    return {
        recruiter,
        recruiterToken,
        recruiterCompany,
        isAuthenticated,
        isLoading,
        init,
        login,
        getUserDetails,
        updateUserProfile,
        updateUserCompany,
        logout,
        isProfileComplete,
        isReady
    }
})