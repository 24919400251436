<template>
    <Card class="w-full p-8">
        <template #content>
            <form class="flex flex-col gap-8" @submit.prevent="$emit('submit')">
                <SectionHeader :is-sub-section="true" title-align="start">
                    <template #title>
                        Please provide the resume and contact info
                    </template>
                    <!-- <template #smallText>
                        Apply to this job in a few clicks, recruiter needs your updated resume and your proof of work
                    </template> -->
                </SectionHeader>

                <div>
                    <FileUpload ref="primeFileUploader" 
                        @select="onFileUpload($event)"
                        :multiple="false"
                        :fileLimit="1"
                        :auto="true"
                        accept="application/pdf" 
                        :maxFileSize="3145728">
                        <template #header="{ chooseCallback, files, uploadedFiles }">
                            <div class="flex flex-wrap justify-between items-center flex-1 gap-4">
                                <div class="flex gap-2">
                                    <Button v-if="uploadedFiles.length == 0" @click="chooseCallback()"
                                        icon="pi pi-cloud-upload" label="Upload" rounded outlined
                                        severity="success"></Button>
                                    <Button v-else @click="() => { uploadedFiles.pop(); onFileRemove(); }"
                                        icon="pi pi-times"
                                        label="Cancel" rounded outlined severity="danger"></Button>
                                </div>
                            </div>
                        </template>
                        <template #content="{ uploadedFiles, removeUploadedFileCallback }">
                            <div class="flex flex-col gap-8 pt-4">
                                <div v-if="uploadedFiles.length > 0">
                                    <Tag severity="success" class="mb-2">
                                        Completed
                                    </Tag>
                                    <div class="flex flex-wrap gap-4 justify-center">
                                        <div v-for="(file, index) of uploadedFiles"
                                            :key="file.name + file.type + file.size"
                                            class="p-8 rounded-border flex flex-col border border-surface items-center gap-4">
                                            <span class="pi pi-file-pdf" style="font-size: 2.5rem" />
                                            <span class="font-semibold text-ellipsis max-w-60 whitespace-nowrap overflow-hidden">
                                                {{ file.name }}
                                            </span>
                                            <div>{{ formatSize(file.size) }}</div>
                                            <Badge value="Completed" class="mt-4" severity="success" />
                                            <Button icon="pi pi-times"
                                                @click="() => { removeUploadedFileCallback(index); onFileRemove(); }"
                                                outlined rounded
                                                severity="danger" />
                                            <ProgressBar :value="fileUploadProgressBar" :showValue="false"
                                                class="md:w-20rem h-1 w-full md:ml-auto">
                                            </ProgressBar>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template #empty>
                            <div class="flex items-center justify-center flex-col cursor-pointer" @click="openFileUpload">
                                <i class="pi pi-cloud-upload !border-2 !rounded-full !p-8 !text-4xl !text-muted-color" />
                                <p class="mt-6 mb-0">Drag & drop or Choose file to upload resume.</p>
                                <p class="mt-0 mb-4 my-paragraph-subtitle">PDF ONLY. <br/> 10MB MAX</p>
                            </div>
                        </template>
                    </FileUpload>
                </div>

                <div class="flex flex-col gap-4">
                    <ParagraphSection>
                        <!-- <template #smallText>
                            The information below is automatically filled with AI:
                        </template> -->
                    </ParagraphSection>
                    <div class="flex flex-col grow gap-2">
                        <label for="fname" class="my-paragraph-subtitle">Full name *</label>
                        <InputText id="fname" type="text" v-model="formData.fullname" required="true" />
                    </div>
                    <div class="flex flex-col grow gap-2">
                        <label for="email" class="my-paragraph-subtitle">Email *</label>
                        <InputText 
                            id="email" 
                            type="email" 
                            v-model="formData.email"
                            :class="{ 'p-invalid': emailError }"
                            @blur="validateEmail"
                        />
                        <small class="p-error" v-if="emailError">Please enter a valid email address</small>
                    </div>
                    <div class="flex flex-col grow gap-2">
                        <label for="linkedin" class="my-paragraph-subtitle">LinkedIn URL *</label>
                        <InputText 
                            id="linkedin" 
                            type="url" 
                            v-model="formData.linkedin"
                            :class="{ 'p-invalid': linkedinError }"
                            @blur="validateLinkedin"
                            required="true" 
                        />
                        <small class="p-error" v-if="linkedinError">Please enter a valid URL</small>
                    </div>
                    <div class="flex flex-col grow gap-2">
                        <label for="phone" class="my-paragraph-subtitle">Phone *</label>
                        <InputText 
                            id="phone" 
                            type="tel" 
                            v-model="formData.phone"
                            :class="{ 'p-invalid': phoneError }"
                            @blur="validatePhone"
                        />
                        <small class="p-error" v-if="phoneError">Please enter a valid phone number</small>
                    </div>
                </div>
                <Button 
                    type="submit"
                    :label="props.loading ? 'Submitting...' : 'Submit'"
                    rounded
                    :disabled="!isFormFilled || !hasFile || props.loading"
                    :loading="props.loading"
                    @click="$emit('submit')"
                />
            </form>
        </template>
    </Card>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import SectionHeader from '@/components/slots/SectionHeader.vue';
import Card from 'primevue/card';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import FileUpload from 'primevue/fileupload';
import ProgressBar from 'primevue/progressbar';
import ParagraphSection from '@/components/slots/ParagraphSection.vue';
import Tag from 'primevue/tag';
import Badge from 'primevue/badge';
import { useToast } from 'primevue/usetoast';

/**
 * Validates if the input is a valid phone number
 * @param phone - The phone number to validate
 * @returns boolean indicating if the phone number is valid
 */
const isValidPhone = (phone: string): boolean => {
    // Allow digits, spaces, +, -, and parentheses
    // Must contain at least one digit
    return /^[+]?[\s()0-9-]+$/.test(phone) && /[0-9]/.test(phone);
};

/**
 * Validates if the input is a valid email address
 * @param email - The email address to validate
 * @returns boolean indicating if the email is valid
 */
const isValidEmail = (email: string): boolean => {
    return email.includes('@') && email.includes('.');
};

/**
 * Validates if the input is a valid URL
 * @param url - The URL to validate
 * @returns boolean indicating if the URL is valid
 */
const isValidUrl = (url: string): boolean => {
    return url.includes('.');
};

const props = defineProps<{
    modelValue: {
        fullname: string;
        email: string;
        phone: string;
        linkedin: string;
    };
    loading: boolean;
}>();

const emit = defineEmits<{
    (e: 'update:modelValue', value: any): void;
    (e: 'submit'): void;
    (e: 'fileSelected', file: File): void;
}>();

const formData = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value)
});

const isFormFilled = computed(() => {
    const allFieldsFilled = Object.values(formData.value).every(field => field.trim() !== '');
    const allFieldsValid = isValidPhone(formData.value.phone) 
        && isValidEmail(formData.value.email) 
        && isValidUrl(formData.value.linkedin);
    return allFieldsFilled && allFieldsValid;
});

const hasFile = ref(false);

const toast = useToast();

const onFileUpload = (event: { files?: File[], originalEvent?: Event | DragEvent }) => {
    let file: File | null = null;

    // Handle input element file selection
    if (event.originalEvent instanceof Event && 'files' in event.originalEvent.target) {
        const input = event.originalEvent.target as HTMLInputElement;
        file = input.files?.[0] || null;
    }
    // Handle drag and drop
    else if ('dataTransfer' in event.originalEvent && event.originalEvent.dataTransfer?.files) {
        file = event.originalEvent.dataTransfer.files[0];
    }
    // Handle regular file selection
    else if (event.files && event.files.length > 0) {
        file = event.files[0];
    }

    if (!file) {
        toast.add({ 
            severity: 'error', 
            summary: 'Upload Failed', 
            detail: 'No file selected', 
            life: 3000 
        });
        return;
    }

    // Check file type
    if (!file.type || file.type !== 'application/pdf') {
        toast.add({ 
            severity: 'error', 
            summary: 'Invalid File', 
            detail: 'Please upload a PDF file only', 
            life: 3000 
        });
        return;
    }

    // Check file size (10MB = 10485760 bytes)
    if (file.size > 10485760) {
        toast.add({ 
            severity: 'error', 
            summary: 'File Too Large', 
            detail: 'File size must be less than 10MB', 
            life: 3000 
        });
        return;
    }

    hasFile.value = true;
    emit('fileSelected', file);
};

const onFileRemove = () => {
    hasFile.value = false;
};

const formatSize = (bytes: any) => {
    const k = 1024;
    const dm = 3;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];

    if (bytes === 0) return `0 ${sizes[0]}`;

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

    return `${formattedSize} ${sizes[i]}`;
};

const fileUploadProgressBar = ref(0);

const primeFileUploader = ref();

const openFileUpload = () => {
    primeFileUploader.value.choose();
};

// Optional: You can also handle invalid files globally
const onError = (event: any) => {
    toast.add({ 
        severity: 'error', 
        summary: 'Upload Failed', 
        detail: event.message || 'There was an error uploading your file', 
        life: 3000 
    });
};

// Add these refs for validation states
const phoneError = ref(false);
const emailError = ref(false);
const linkedinError = ref(false);

// Add validation handlers
const validatePhone = () => {
    phoneError.value = !isValidPhone(formData.value.phone);
};

const validateEmail = () => {
    emailError.value = !isValidEmail(formData.value.email);
};

const validateLinkedin = () => {
    linkedinError.value = !isValidUrl(formData.value.linkedin);
};
</script>
