import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useRecruiterStore } from "./recruiterStore";
import { fetchJobs, fetchJobById, deleteJob, closeJob, reopenJob } from "@/api_client";
import { type Candidate } from "./candidateStore";

export const useJobStore = defineStore("jobs", () => {
    const recruiterStore = useRecruiterStore();
    const jobs = ref<Job[]>([]);
    const loading = ref<boolean>(false);
    const loadingJob = ref<boolean>(false);
    const initialized = ref<boolean>(false);
    const createJob = ref<NewJobForm>({} as NewJobForm);

    /**
     * Initializes the job store by fetching jobs if not already initialized
     * @returns Promise<void>
     */
    async function init(): Promise<void> {
        if (!initialized.value) {
            await fetchJobsRecruiter();
            initialized.value = true;
        }
    }

    async function fetchJobsRecruiter(): Promise<void> {
        if (recruiterStore.isAuthenticated && !loading.value) {
            loading.value = true;
            try {
                const response = await fetchJobs();
                jobs.value = response.data.results.map((job: any): Job => ({
                    id: job.id,
                    company: Company.fromData(job),
                    title: job.title,
                    description: job.description,
                    language: job.language,
                    location: job.location,
                    url: job.url,
                    contact: job.contact,
                    publish: job.publish,
                    status: job.status,
                    max_salary: job.max_salary,
                    min_salary: job.min_salary,
                    views: job.views,
                    created: new Date(job.created),
                    modified: new Date(job.modified),
                    published_at: new Date(job.published_at),
                    status_changed: new Date(job.status_changed),
                    candidates: job.candidates,
                    candidatesList: undefined,  // Add support for candidatesList
                    skills: job.skills.map((skill: any): Skill => ({
                        id: skill.id,
                        name: skill.name,
                        description: skill.description,
                        skill_type: skill.skill_type as 'hardskill' | 'softskill'
                    }))
                }));
            } catch (error) {
                console.error('Error fetching jobs:', error);
            } finally {
                loading.value = false;
            }
        }
    }

    /**
     * Gets a job by ID from the store or fetches it from the API if not found
     * @param id - The ID of the job to retrieve
     * @returns Promise<Job> - The requested job
     * @throws Error if job cannot be found or fetched
     */
    async function getJobById(id: number): Promise<Job> {
        const localJob = jobs.value.find((j) => j.id === id);
        if (localJob) {
            return localJob;
        }

        try {
            loadingJob.value = true;
            const job = await fetchJobById(id);
            
            // Transform the API response into a Job object
            const transformedJob: Job = {
                id: job.id,
                company: Company.fromData(job),
                title: job.title,
                description: job.description,
                language: job.language,
                location: job.location,
                url: job.url,
                contact: job.contact,
                publish: job.publish,
                status: job.status,
                max_salary: job.max_salary,
                min_salary: job.min_salary,
                views: job.views,
                created: new Date(job.created),
                modified: new Date(job.modified),
                published_at: new Date(job.published_at),
                status_changed: new Date(job.status_changed),
                candidates: job.candidates,
                skills: job.skills.map((skill: any): Skill => ({
                    id: skill.id,
                    name: skill.name,
                    description: skill.description,
                    skill_type: skill.skill_type as 'hardskill' | 'softskill'
                }))
            };

            // Add the fetched job to the store
            jobs.value.push(transformedJob);
            return transformedJob;
        } catch (error) {
            console.error('Error fetching job by ID:', error);
            throw new Error('Failed to fetch job');
        } finally {
            loadingJob.value = false;
        }
    }

    function setNewJobDetails(jobDetails: JobDetailsForm) {
        createJob.value.jobDetails = jobDetails;
    }
    function setNewInterviewQuestions(interviewQuestions: InterviewQuestionsForm) {
        createJob.value.interviewQuestions = interviewQuestions;
    }
    function setNewAdvancedOptions(advancedOptions: AdvancedOptionsForm) {
        createJob.value.advancedOptions = advancedOptions;
    }

    // Add back the jobOptions computed property
    const jobOptions = computed(() => [
        { id: 'all', title: 'All Jobs' },
        ...jobs.value.map(job => ({ id: job.id, title: job.title }))
    ]);

    /**
     * Resets all store state to initial values
     * @returns void
     */
    function resetStore(): void {
        jobs.value = [];
        loading.value = false;
        loadingJob.value = false;
        initialized.value = false;
        createJob.value = {} as NewJobForm;
    }

    /**
     * Deletes a job from both the API and local store
     * @param jobId - ID of the job to delete
     * @throws Error if deletion fails
     */
    async function deleteJobById(jobId: number): Promise<void> {
        try {
            await deleteJob(jobId);
            jobs.value = jobs.value.filter(job => job.id !== jobId);
        } catch (error) {
            console.error('Error deleting job:', error);
            throw new Error('Failed to delete job');
        }
    }

    /**
     * Closes a job by updating its status
     * @param jobId - ID of the job to close
     * @throws Error if closing fails
     */
    async function closeJobById(jobId: number): Promise<void> {
        try {
            await closeJob(jobId);
            const job = jobs.value.find(j => j.id === jobId);
            if (job) {
                job.status = 'closed';
                job.status_changed = new Date();
            }
        } catch (error) {
            console.error('Error closing job:', error);
            throw new Error('Failed to close job');
        }
    }

    /**
     * Reopens a closed job
     * @param jobId - ID of the job to reopen
     * @throws Error if reopening fails
     */
    async function reopenJobById(jobId: number): Promise<void> {
        try {
            await reopenJob(jobId);
            const job = jobs.value.find(j => j.id === jobId);
            if (job) {
                job.status = 'open';
                job.status_changed = new Date();
            }
        } catch (error) {
            console.error('Error reopening job:', error);
            throw new Error('Failed to reopen job');
        }
    }

    return { 
        jobs, 
        loading,
        loadingJob,
        initialized,
        init,
        getJobById, 
        fetchJobsRecruiter, 
        createJob, 
        setNewJobDetails, 
        setNewInterviewQuestions, 
        setNewAdvancedOptions,
        jobOptions, // Expose jobOptions
        resetStore, // Replace the existing clearStore with resetStore
        deleteJobById,
        closeJobById,
        reopenJobById,
    };
})

/**
 * Represents a company in the job system
 */
export class Company {
    /**
     * Creates a new Company instance
     * @param id - Unique identifier for the company
     * @param name - Name of the company
     * @param logo - Optional URL to company's logo
     * @param websiteUrl - Optional URL to company's website
     */
    constructor(
        public readonly id: number,
        public name: string,
        public logo?: string,
        public websiteUrl?: string,
    ) {}

    /**
     * Creates a Company instance from raw data
     * @param data - Raw company data from API
     * @returns A new Company instance
     */
    static fromData(data: any): Company {
        return new Company(
            data.company_id || data.id,
            data.company_name || data.name,
            data.company_logo || data.logo,
            data.company_website_url || data.websiteUrl
        );
    }
}

export interface Job {
    id: number;
    title: string;
    company: Company;
    description: string;
    language: string;
    location: string;
    url: string;
    contact: string;
    publish: boolean;
    status: string;
    max_salary: string;
    min_salary: string;
    views: number;
    created: Date;
    modified: Date;
    published_at: Date;
    status_changed: Date;
    candidates: number;
    candidatesList?: Candidate[];
    skills: Skill[];
    // hidden skills
    // ai_extracted_skills: Skill[];
}

interface Skill {
    id: number;
    name: string;
    description: string | null;
    skill_type: 'hardskill' | 'softskill';
}

export interface NewJobForm {
    jobDetails: JobDetailsForm;
    interviewQuestions: InterviewQuestionsForm;
    advancedOptions: AdvancedOptionsForm;
    company: Company;
    recruiter: Recruiter[];
}

export class JobDetailsForm {
    jobPosition: string;
    language: string;
    location: string;
    skillsMajor: string[];
    jobDescription: string;

    constructor(
        jobPosition: string = '',
        language: string = '',
        location: string = '',
        skillsMajor: string[] = [],
        jobDescription: string = ''
    ) {
        this.jobPosition = jobPosition;
        this.language = language;
        this.location = location;
        this.skillsMajor = skillsMajor;
        this.jobDescription = jobDescription;
    }
}

export interface InterviewQuestionsForm {
    questions: (QuestionTextAnswer|QuestionMultipleChoice)[];
}

export interface AdvancedOptionsForm {
    inviteAi: boolean;
    salary: number[];
    jobStatus: string;
    aiAutoInvite: boolean;
    aiPreselectGreat: number;
    aiPreselectLess: number;
    aiScorePrompt: string;
    emailSubject: string;
    emailTemplate: string;
}

export interface Recruiter {
    id: number;
    fullname: string;
}

export interface Question {
    id: number;
    question: string;
    questionType: string;
    questionFrom: string;
    coefficient: number;
}
export interface QuestionTextAnswer extends Question{
    answer: string;
}
export interface QuestionMultipleChoice extends Question {
    answer: MultipleChoice[];
    expectedAnswer: MultipleChoice[];
}
export interface MultipleChoice {
    id: number;
    choice: string;
}

