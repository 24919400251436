<template>
    <div class="flex flex-col gap-8">
        <div class="flex justify-between flex-col sm:flex-row items-center sm:items-start">
            <SectionHeader :is-sub-section="true" title-align="start" class="pb-2 sm:pb-0">
                <template #title>
                    Interview Questions
                </template>
            </SectionHeader>
            <div class="flex flex-row gap-2">
                <Button 
                    rounded 
                    :label="isGenerating ? 'Generating questions...' : 'Generate +10 questions'" 
                    iconPos="right"
                    :icon="isGenerating ? 'pi pi-spinner pi-spin' : ''"
                    :disabled="isGenerating"
                    @click="generateQuestions(10)" 
                />
                <Button 
                    rounded 
                    label="5" 
                    icon="pi pi-plus" 
                    severity="secondary" 
                    outlined
                    @click="generateQuestions(5)" 
                />
                <Button 
                    rounded 
                    label="1" 
                    icon="pi pi-plus" 
                    severity="secondary" 
                    outlined
                    @click="generateQuestions(1)" 
                />
            </div>
        </div>
        <draggable v-model="interviewQuestions.questions" item-key="id" handle=".question-drag-handle"
            class="flex flex-col gap-4">
            <template #item="{ element, index }">
                <QuestionCreate 
                    :recruiters="recruiters" 
                    :key="element.id" 
                    :question="element" 
                    :index="index"
                    @update="updateQuestion"
                    @delete="deleteQuestion"
                />
            </template>
        </draggable>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'; // Added onMounted import
import { generateJobQuestions } from '@/api_client';
import { useJobStore, type InterviewQuestionsForm, type QuestionMultipleChoice, type QuestionTextAnswer, type Recruiter } from '@/stores/jobStore';
import { useToast } from 'primevue/usetoast';
import Button from 'primevue/button';
import draggable from 'vuedraggable';
import SectionHeader from '@/components/slots/SectionHeader.vue';
import QuestionCreate from '@/components/slots/QuestionCreate.vue';

const toast = useToast();
const jobStore = useJobStore();
const interviewQuestions = defineModel<InterviewQuestionsForm>('interviewQuestions', { required: true })

const isGenerating = ref<boolean>(false);

const generateQuestions = async (n_questions: number = 10) => {
    if (isGenerating.value) return;
    
    isGenerating.value = true;
    const initialQuestionCount = interviewQuestions.value.questions.length;

    try {
        const questions = await generateJobQuestions(jobStore.createJob.jobDetails.jobDescription, n_questions);
        questions.forEach(q => {
            interviewQuestions.value.questions.push({
                id: q.order,
                question: q.question,
                questionType: "text", //Hardcoded for now, check api_client.ts note
                questionFrom: q.type,
                coefficient: q.coefficient,
                answer: '',
            } as QuestionTextAnswer)
        })
        toast.add({ severity: 'success', summary: 'Interview questions generated', detail: `${n_questions>1? `${n_questions} questions` : 'question'} generated successfully`, life: 3000 });
    } catch (error) {
        console.error('Error generating job questions:', error);
        toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to generate job questions', life: 3000 });
    } finally {
        isGenerating.value = false;
        // Scroll to the newly added questions
        setTimeout(() => {
            const newQuestions = document.querySelectorAll('.question-drag-handle');
            const newQuestionsArray = Array.from(newQuestions).slice(initialQuestionCount);
            if (newQuestionsArray.length > 0) {
                newQuestionsArray[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 100);
    }
};

const updateQuestion = (updatedQuestion: QuestionTextAnswer | QuestionMultipleChoice) => {
    const index = interviewQuestions.value.questions.findIndex(q => q.id === updatedQuestion.id)
    if (index !== -1) {
        interviewQuestions.value.questions[index] = updatedQuestion
    }
}

const deleteQuestion = (questionId: number) => {
    const index = interviewQuestions.value.questions.findIndex(q => q.id === questionId);
    if (index !== -1) {
        interviewQuestions.value.questions.splice(index, 1);
        toast.add({ severity: 'info', summary: 'Question removed', life: 3000 });
    }
};

// Placeholders
const recruiters = <Recruiter[]>[
    {
        id: 1,
        fullname: "HR1",
    },
    {
        id: 2,
        fullname: "HR2",
    }
]
const defaultQuestion = computed<QuestionTextAnswer>( (): QuestionTextAnswer => ({
    id: Math.max(...interviewQuestions.value.questions.map(q => q.id), 0) + 1,
    question: "What is your name?",
    questionType: "text",
    questionFrom: "HR",
    coefficient: 1,
    answer: ''
}))

// Call generateQuestions on component load if no questions exist
onMounted(() => {
    if (interviewQuestions.value.questions.length === 0) {
        generateQuestions();
    }
});

</script>