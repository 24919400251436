<template>
    <div class="data-table-wrapper">
        <div class="overflow-x-auto">
            <DataTable 
                :value="loading ? loadingData : candidates" 
                sortMode="multiple" 
                removableSort 
                v-model:selection="selectedCandidate" 
                selectionMode="single" 
                @rowSelect="onRowSelect" 
                ref="candidatesTable"
                class="min-w-[768px] bg-white rounded-2xl border border-[#f2f4f7]"
                scrollable
                scrollHeight="flex"
                :rows="rows"
                :first="first"
                :paginator="true"
                :rowsPerPageOptions="[5, 10, 20, 50]"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} candidates"
                responsiveLayout="scroll"
                @page="onPage"
            >
                <template #empty> No candidates found. </template>
                
                <Column field="details.fullname" header="Name" sortable class="w-[150px] sm:w-[250px]">
                    <template #body="{ data }">
                        <Skeleton v-if="loading" width="180px" height="24px" />
                        <div v-else class="text-[#1e1e1e] text-base font-normal font-['Sora'] leading-relaxed tracking-tight sm:w-[250px]">
                            {{ data.details?.fullname || data.applicant_name }}
                        </div>
                    </template>
                </Column>
                
                <Column
                    field="status"
                    header="Status"
                    sortable
                >
                    <template #body="{ data }">
                        <template v-if="loading">
                            <Skeleton width="120px" height="24px" />
                        </template>
                        <template v-else>
                            <Tag rounded :value="formatStatus(data.status)" 
                                :style="getStatusStyle(data.status)"
                                :label="formatStatus(data.status)" />
                        </template>
                    </template>
                </Column>
                
                <Column
                    field="location"
                    header="Location"
                    sortable
                >
                    <template #body="{ data }">
                        <template v-if="loading">
                            <Skeleton width="140px" height="24px" />
                        </template>
                        <div v-else class="text-[#1e1e1e] text-base font-normal font-['Sora'] leading-relaxed tracking-tight">
                            {{ data.location }}
                        </div>
                    </template>
                </Column>
                
                <Column
                    field="ai_score"
                    header="AI Score"
                    sortable
                >
                    <template #body="{ data }">
                        <template v-if="loading">
                            <Skeleton width="60px" height="24px" />
                        </template>
                        <div v-else class="flex items-center gap-2.5">
                            <div class="spark-container">
                                <div class="w-4 h-4 relative">
                                    <img src="@/assets/icons/spark.svg" alt="spark" class="w-full h-full" />
                                </div>
                            </div>
                            <div class="text-[#1e1e1e] text-base font-normal font-['Sora'] leading-relaxed tracking-tight">
                                {{ data?.ai_score ?? '-' }}%
                            </div>
                        </div>
                    </template>
                </Column>
                
                <Column
                    field="date"
                    header="Applied on"
                    sortable
                >
                    <template #body="{ data }">
                        <template v-if="loading">
                            <Skeleton width="100px" height="24px" />
                        </template>
                        <div v-else class="text-[#1e1e1e] text-base font-normal font-['Sora'] leading-relaxed tracking-tight">
                            <span v-tooltip.top="data?.date ? new Date(data.date).toLocaleTimeString() : ''">
                                {{ data?.date ? new Date(data.date).toLocaleDateString() : '-' }}
                            </span>
                        </div>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>

    <CandidateDrawer v-model:visible="visibleDrawer" 
        v-model:candidate="selectedCandidate"
        v-model:invite-dialog-visible="inviteDialogVisible" 
        :table="$refs.candidatesTable" />
    <InviteDialog v-if="selectedCandidate" 
        v-model:visible="inviteDialogVisible"
        v-model:candidate="selectedCandidate" />
</template>

<script setup lang="ts">
import { ref, computed, onBeforeUnmount, nextTick } from 'vue';
import { type Candidate } from '@/stores/candidateStore';
import InviteDialog from './InviteDialog.vue';
import CandidateDrawer from './CandidateDrawer.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Tag from 'primevue/tag';
import Skeleton from 'primevue/skeleton';
import { getStatusStyle } from '@/utils/style';
const props = defineProps<{
    candidates: Candidate[];
    loading?: boolean;
}>();

// Format status function
function formatStatus(status: string): string {
    const statusMap: { [key: string]: string } = {
        'newapplicant': 'New Applicant',
        'rejected': 'Rejected',
        'aiselected': 'AI Selected',
        'airejected': 'AI Rejected',
        'no_decision': 'No Decision',
        'interview': 'Interview',
        'offer': 'Offer',
        'selected': 'Selected'
    };
    return statusMap[status] || status;
}


const visibleDrawer = ref(false);
const selectedCandidate = ref<Candidate | null>(null);
const inviteDialogVisible = ref(false);
const candidatesTable = ref<InstanceType<typeof DataTable> | null>(null);
const rows = ref<number>(10);
const first = ref<number>(0);

interface PaginatorState {
    first: number;
    rows: number;
    page: number;
    pageCount: number;
}

const onRowSelect = async (event: any) => {
    if (event?.data) {
        await nextTick();
        selectedCandidate.value = event.data;
        visibleDrawer.value = true;
    }
};

const onPage = (event: PaginatorState) => {
    first.value = event.first;
    rows.value = event.rows;
};

// Cleanup function
onBeforeUnmount(() => {
    selectedCandidate.value = null;
    visibleDrawer.value = false;
    candidatesTable.value = null;
});

// Add loading data
const loadingData = computed(() => new Array(5).fill({}));
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@400;500&display=swap');

/* Only keep essential styles */
:deep(.p-datatable-wrapper) {
    border-radius: 1rem;
    position: relative;
}

:deep(.p-datatable-thead) th {
    background-color: #f2f4f7;
    color: #636770;
}

:deep(.p-datatable-tbody) tr {
    cursor: pointer;
}

:deep(.p-datatable-tbody) tr:hover {
    background-color: #f2f4f7;
}

.spark-container {
    height: 1.75rem;
    padding: 0.375rem;
    border-radius: 0.375rem;
    display: inline-flex;
    justify-content: start;
    align-items: center;
    background-color: white;
}

/* Add these new styles for mobile responsiveness */
.data-table-wrapper {
    width: 100%;
    position: relative;
    margin: 0 auto;
}


/* Add mobile specific styles */
@media (max-width: 768px) {
    :deep(.p-datatable) {
        font-size: 0.875rem;
    }

    :deep(.p-datatable-thead) th {
        padding: 0.75rem 0.5rem;
    }

    :deep(.p-datatable-tbody) td {
        padding: 0.75rem 0.5rem;
    }

    /* Adjust column widths for mobile */
    :deep(.p-datatable-tbody) td:first-child,
    :deep(.p-datatable-thead) th:first-child {
        min-width: 140px;
    }
}

</style>