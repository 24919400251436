<template>
    <h1 v-if="$slots.title" class="my-paragraph-title text-figma-dark">
        <slot name="title"></slot>
    </h1>
    <p v-if="$slots.description" class="my-paragraph-description pt-2">
        <slot name="description"></slot>
    </p>
    <p v-if="$slots.smallText" class="my-small-description pt-2">
        <slot name="smallText"></slot>
    </p>
</template>

<style scoped>
.my-paragraph-title {
    position: relative;
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
    text-align: start;
}
.my-paragraph-description {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    color: #646771;
    text-align: justify;
}
.my-small-description {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    color: #646771;
}
</style>