<template>
    <div class="self-stretch flex flex-col items-start justify-start gap-[12px]">
        <h1
            class="title m-0 self-stretch relative tracking-[-0.01em] leading-[46px] font-medium font-text-sm-regular text-center mq450:text-[22px] mq450:leading-[28px] mq1025:text-[29px] mq1025:leading-[37px]">
            <slot name="title"></slot>
        </h1>
        <div
            class="description self-stretch relative text-base tracking-[0.01em] leading-[26px] font-text-sm-regular text-light-text-secondary text-center">
            <slot name="description"></slot>
        </div>
    </div>
</template>

<style scoped>
.title {
    font-size: 2rem;
}

.description {
    font-size: 1.2rem;
}

</style>