import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { fetchCandidatesByJobId as apiFetchCandidates, fetchAllCandidates as apiFetchAllCandidates, fetchApplicationDetailsById, updateApplicationStatus } from '../api_client';

export const useCandidateStore = defineStore('candidate', {
    state: () => ({
        candidates: ref<Candidate[]>([]),
        applicationDetails: ref<Map<number, ApplicationDetails>>(new Map()),
        loading: ref(false),
        fetchedJobIds: ref<Set<number>>(new Set()),
        emptyJobs: ref<Set<number>>(new Set())
    }),
    getters: {
        getInterviewedCandidateById: (state) => {
            return (id: number): Candidate =>
                state.candidates.find((a) => a.id === id && a.is_interviewed) || {} as Candidate
        },
        getCandidateById: (state) => {
            return (id: number): Candidate | undefined =>
                state.candidates.find((a) => a.id === id)
        },
        getCandidateFullDetails: (state) => {
            return (id: number) => {
                const candidate = state.candidates.find((a) => a.id === id);
                const details = state.applicationDetails.get(id);
                return { ...candidate, applicationDetails: details };
            }
        },
        getCandidatesByJobId: (state) => {
            return (jobId: number): Candidate[] => 
                state.candidates.filter((candidate) => candidate.job_id === jobId);
        },
    },
    actions: {
        clearState() {
            this.candidates = [];
            this.fetchedJobIds.clear();
            this.emptyJobs.clear();
        },

        // Modified trackJobsFromAllCandidates to properly track empty jobs
        trackJobsFromAllCandidates(allJobs: Set<number>) {
            const jobsWithCandidates = new Set(this.candidates.map(c => c.job_id));

            allJobs.forEach(jobId => {
                this.fetchedJobIds.add(jobId);

                // If a job doesn't have candidates, mark it as empty
                if (!jobsWithCandidates.has(jobId)) {
                    this.emptyJobs.add(jobId);
                }
            });
        },

        setCandidates(newCandidates: Candidate[]) {
            this.candidates = newCandidates;
        },

        async fetchAllCandidates() {
            this.loading = true;
            try {
                const response = await apiFetchAllCandidates();

                // Don't clear state when fetching all, merge instead
                const existingIds = new Set(this.candidates.map(c => c.id));
                const newCandidates = response.filter(c => !existingIds.has(c.id));

                this.candidates = [...this.candidates, ...newCandidates];

                // Track jobs that have candidates
                const jobsWithCandidates = new Set(response.map(c => c.job_id));
                jobsWithCandidates.forEach(jobId => {
                    this.fetchedJobIds.add(jobId);
                    // Remove from emptyJobs if we found candidates for it
                    this.emptyJobs.delete(jobId);
                });

                return response;
            } catch (error) {
                console.error('Error fetching all candidates:', error);
                throw error;
            } finally {
                this.loading = false;
            }
        },

        async fetchCandidatesByJobId(jobId: number) {
            // Check both fetchedJobIds and emptyJobs
            if (this.fetchedJobIds.has(jobId)) {
                // If we know it's empty, return empty array
                if (this.emptyJobs.has(jobId)) {
                    return [];
                }
                // Otherwise return existing candidates
                return this.candidates.filter(c => c.job_id === jobId);
            }

            // If we haven't fetched this job yet, fetch it
            this.loading = true;
            try {
                const response = await apiFetchCandidates(jobId);

                if (response.length === 0) {
                    this.emptyJobs.add(jobId);
                    this.fetchedJobIds.add(jobId);
                    return [];
                }

                const nonJobCandidates = this.candidates.filter(c => c.job_id !== jobId);
                this.candidates = [...nonJobCandidates, ...response];
                this.fetchedJobIds.add(jobId);
                return response;
            } catch (error) {
                console.error('Error fetching candidates:', error);
                throw error;
            } finally {
                this.loading = false;
            }
        },

        async inviteCandidateToInterview(candidateId: number, interviewType: string) {
            try {
                const candidate = this.candidates.find(c => c.id === candidateId);
                if (candidate) {
                    candidate.invite_to_interview = {
                        isInvited: true,
                        interviewType: interviewType,
                        inviteDate: new Date()
                    };

                    if (interviewType === 'Human interview') {
                        const subject = `Interview Invitation`;
                        const body = `Dear ${candidate.details.fullname},\n\nThank you for your interest in joining our team. We have reviewed your application and would like to invite you for an interview to further discuss your qualifications and experience.\n\nPlease reply to this email with your preferred date and time for the interview, along with any alternative slots that would work for you. We will do our best to accommodate your schedule.\n\nWe look forward to speaking with you.\n\nBest regards,\nThe Hiring Team`;

                        const mailtoLink = document.createElement('a');
                        mailtoLink.href = `mailto:${candidate.details.contact.email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
                        window.open(mailtoLink.href, '_blank');
                    }
                }
            } catch (error) {
                console.error('Error inviting candidate:', error);
                throw error;
            }
        },

        async fetchApplicationDetailsById(id: string) {
            const numId = Number(id);
            // Check if we already have the details cached
            if (this.applicationDetails.has(numId)) {
                return this.applicationDetails.get(numId);
            }

            try {
                const response = await fetchApplicationDetailsById(numId);
                // Store the details in our map
                this.applicationDetails.set(numId, response);
                return response;
            } catch (error) {
                console.error('Error fetching candidate details:', error);
                throw error;
            }
        },

        async updateCandidateStatus(candidateId: number, newStatus: ApplicationStatus) {
            try {
                const status = typeof newStatus === 'object' ? (newStatus as any).value : newStatus;
                
                await updateApplicationStatus(candidateId, status);
                // Update local state
                const candidate = this.candidates.find(c => c.id === candidateId);
                if (candidate) {
                    candidate.status = status;
                }
            } catch (error) {
                console.error('Error updating candidate status:', error);
                throw error;
            }
        },

        /**
         * Resets the entire store state to initial values
         * Should be called when user logs out
         */
        resetStore(): void {
            this.candidates = [];
            this.applicationDetails = new Map();
            this.loading = false;
            this.fetchedJobIds = new Set();
            this.emptyJobs = new Set();
        }
    }
});

export type ApplicationStatus = 
    | 'newapplicant'
    | 'rejected'
    | 'aiselected'
    | 'airejected'
    | 'no_decision'
    | 'interview'
    | 'offer'
    | 'selected';

export interface ApplicationSkill {
    skill_name: string;
    skill_type: string;
    score: number;
    score_reason: string;
    year_of_experience: number;
}

export interface Project {
    name: string;
    description: string;
    technologies: Skill[];
}

export interface Link {
    name: string;
    url: string;
}

export interface AcademicStudy {
    institution: string;
    degree: string;
    field_of_study: string;
    graduation_year: number;
}

export interface Note {
    id: number;
    note: string;
    author_name: string;
    created: string;
}

export interface Skill {
    name: string;
}

export interface ApplicationDetails {
    id: number;
    notes: Note[];
    status: ApplicationStatus;
    full_name: string;
    matching_skills: string[];
    total_experience?: number;
    current_position?: string;
    email: string;
    phone: string;
    linkedin: string;
    location: string;
    resume: string;
    cover_letter: string;
    score: number;
    score_reason: string;
    intro: string;
    languages: { name: string; proficiency?: string }[];
    certifications: string[];
    additional_info: string;
    strengths: string[];
    weaknesses: string[];
    skills: ApplicationSkill[];
    experiences: WorkExperience[];
    academic_studies: AcademicStudy[];
    projects: Project[];
    links: Link[];
    created: string;
    work_experiences: WorkExperience[];
}

interface WorkExperience {
    company: string;
    position: string;
    duration: string;
    description: string;
}

export interface Candidate {
    id: number;
    status: ApplicationStatus;
    applicant_name: string;
    location: string | null;
    ai_score: number;
    date: string;
    job_id: number;
    job_title: string;
    details?: CandidateDetails;
    resume_url?: string;
    is_interviewed?: boolean;
    report?: ReportAI;
    recruiter_notes?: RecruiterNote[];
    transcript?: TranscriptType;
    invite_to_interview?: {
        isInvited: boolean;
        interviewType?: string;
        inviteDate?: Date;
    };
}

export interface CandidateDetails {
    avatar_src: string;
    fullname: string;
    role: string;
    contact: ContactInformation;
    linkedin_link: string;
    experience: string;
    skills: ApplicationSkill[];
    education: AcademicStudy[];
    languages: { name: string; proficiency?: string }[];
    previous_roles: PreviousRoles[];
    // Removed work_experiences from here as it's now in ApplicationDetails
}

interface ContactInformation {
    email: string;
    location: string;
    phone: string;
    available_date: Date;
}

interface PreviousRoles {
    company: string;
    role: string;
    duration: string;
}

export interface RecruiterNote {
    writer: string;
    last_updated: Date;
    note: string;
}

export interface TranscriptType {
    transcript_video_url: string;
    transcript_entries: TranscriptEntry[];
    transcript_feedback: TranscriptFeedback[];
    summary: string;
}
export interface TranscriptEntry {
    isQuestion: boolean;
    timestamp: string;
    text: string;
    speaker: string;
}
export interface TranscriptFeedback {
    question: string;
    score: number;
    feedback: string;
}

export interface ReportAI {
    interview_date: Date;
    score: number;
    hard_skills_chart: RadarElement[];
    hard_skills_description: string;
    soft_skills_chart: RadarElement[];
    soft_skills_description: string;
    strength: string;
    weakness: string;
    summary?: string;
}
export interface RadarElement {
    label: string;
    score: number;
}
