<template>
    <LoadingLogo v-if="isLoading" message="Loading job details..."/>
    <div v-else class="flex flex-col items-center justify-center box-border max-w-full">
        <SubNavbar>
            <template #title>
                <!-- <span class="w-full text-center mt-2 lg:mt-0 text-lg"> -->
                    {{job.title ? job.title : 'Loading...'}}
                <!-- </span> -->
                <Tag v-if="isAuthenticated" 
                     :value="formatStatus(job.status)"
                     :style="getStatusStyle(job.status)"
                     :label="formatStatus(job.status)"
                     rounded 
                     class="ml-2"
                     v-tooltip.bottom="job.status === 'closed' ? 'Closed jobs are not visible to the public and can no longer accept applications' : undefined"
                />
            </template>
            <template #buttonGroup v-if="!isLoading">
                <div class="flex gap-4 flex-wrap">
                    <Button v-if="isAuthenticated" class="w-[150px] sm:w-[200px]" rounded icon="pi pi-cog" severity="secondary" label="Edit" as="router-link" :to="`/jobs/${job.id}/edit`"/>
                    <Button v-if="!isLoading" class="w-[150px] sm:w-[200px]" rounded :label="isAuthenticated ? 'Add Candidate' : 'Apply now'" as="router-link" :to="`/jobs/${job.id}/apply`"/>
                </div>
            </template>
        </SubNavbar>
        <div class="max-w-[1440px] w-full pt-[50px] pb-[50px] xl:px-[108px] flex flex-col items-center">
            <Card class="w-full px-6 py-6">
                <template #content>
                    <div class="flex flex-col gap-4">
                        <div class="flex items-center w-fit p-3 bg-slate-50 rounded-lg borderdrop-shadow-md mx-auto md:mx-0">
                            <Avatar 
                                size="xlarge" 
                                :image="job.company.logo || undefined"
                                :icon="!job.company.logo ? 'pi pi-briefcase' : undefined"
                                class="company-logo"
                                :pt="{
                                    image: { class: 'object-contain' }
                                }"
                            />
                        </div>
                        <div class="flex flex-col gap-1">
                            <SectionHeader :is-sub-section="true" title-align="start">
                                <template #title>
                                    <div class="subsection-title text-figma-dark text-center md:text-left">
                                    {{job.title}}
                                    <h1>@ <a v-if="job.company.websiteUrl" :href="job.company.websiteUrl" target="_blank" rel="noopener noreferrer">{{ job.company.name }} <i class="pi pi-external-link"></i></a>
                                        <template v-else>{{ job.company.name }}</template>
                                    </h1>
                                </div>
                                </template>
                            </SectionHeader>
                            
                            <!-- Add skills section here -->
                            <div v-if="job.skills && job.skills.length > 0" class="flex flex-col gap-2 my-2">
                                <div class="flex items-center gap-2 text-gray-600">
                                    <i class="pi pi-star-fill text-sm"></i>
                                    <span class="text-sm font-medium">Required Skills</span>
                                </div>
                                <div class="flex flex-wrap gap-2">
                                    <Tag 
                                        v-for="skill in job.skills" 
                                        :key="skill.id"
                                        :value="skill.name"
                                        severity="info"
                                        rounded
                                        class="skill-tag"
                                    >
                                        <template #icon>
                                            <i class="pi pi-cog mr-1"></i>
                                        </template>
                                    </Tag>
                                </div>
                            </div>
                        </div>
                        <Divider/>
                        <div class="markdown-container"
                            v-html="parsedJobDescription">
                        </div>
                        
                        <!-- Add new button section -->
                        <div class="flex justify-center mt-8">
                            <Button 
                                class="w-[300px]" 
                                rounded 
                                size="large"
                                :label="isAuthenticated ? 'Add Candidate' : 'Apply now'" 
                                as="router-link" 
                                :to="`/jobs/${job.id}/apply`"/>
                        </div>
                    </div>
                </template>
            </Card>
        </div>
    </div>
</template>

<script setup lang="ts">
import LoadingLogo from '@/components/LoadingLogo.vue';
import SectionHeader from '@/components/slots/SectionHeader.vue';
import SubNavbar from '@/components/slots/SubNavbar.vue';
import Avatar from 'primevue/avatar';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Divider from 'primevue/divider';
import Tag from 'primevue/tag';
import { type Job, useJobStore } from '@/stores/jobStore';
import { useRecruiterStore } from '@/stores/recruiterStore';
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { marked } from 'marked';
import { getStatusStyle } from '@/utils/style';

const jobStore = useJobStore();
const recruiterStore = useRecruiterStore();
const route = useRoute();

const job = ref<Job>({} as Job)
const isLoading = computed(() => jobStore.loadingJob || Object.keys(job.value).length === 0)
const isAuthenticated = computed(() => recruiterStore.isAuthenticated)

onMounted(async () => {
    job.value = await jobStore.getJobById(Number(route.params.jobId))
})

const parsedJobDescription = computed(() => {
    if (job.value.description) {
        const descriptionWithActualNewlines = job.value.description.replace(/\\n/g, '\n');
        return marked(descriptionWithActualNewlines)
    }
    return marked('_Loading..._')
})

function formatStatus(status: string): string {
    const statusMap: { [key: string]: string } = {
        'open': 'Open',
        'closed': 'Closed'
    };
    return statusMap[status] || status;
}

</script>

<style scoped>
.skill-tag :deep(.p-tag) {
    padding: 0.4rem 0.8rem;
    font-size: 0.9rem;
    transition: transform 0.2s, box-shadow 0.2s;
}

.skill-tag :deep(.p-tag:hover) {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.company-logo {
    height: 100px;
    min-width: 100px;
    width: auto;
}
</style>