<template>
  <div class="flex flex-col items-center justify-center p-8">
    <p v-if="message" class="mb-4 text-gray-600">{{ message }}</p>
    <div class="animate-pulse">
      <img 
        :src="logoPath" 
        :alt="altText" 
        class="w-[50vw] lg:w-[25vw] mx-auto"
        :class="{ 'mt-[20vh]': addTopMargin }"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import blackLogo from '@/assets/img/haier-black.png'
interface Props {
  /**
   * Path to the logo image
   */
  logoPath?: string;
  /**
   * Alt text for the logo image
   */
  altText?: string;
  /**
   * Optional loading message to display
   */
  message?: string;
  /**
   * Whether to add top margin for vertical centering
   * @default true
   */
  addTopMargin?: boolean;
}

withDefaults(defineProps<Props>(), {
  logoPath: blackLogo,
  altText: 'Loading logo',
  message: '',
  addTopMargin: true
});
</script> 