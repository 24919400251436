<template>
    <div class="flex flex-col items-center justify-center box-border max-w-full">

        <LoadingLogo 
            v-if="isLoading"
            message="Loading job details..."
        />
        
        <div v-else-if="error" class="flex flex-col items-center justify-center p-8">
            <p class="text-red-600">{{ error }}</p>
        </div>
        
        <div v-else-if="job" class="flex flex-col gap-8 w-full items-center mt-6 lg:mt-2">
            <!-- <img src="@/assets/img/haier-black.png" alt="Loading logo" class="w-[150px] mx-auto mt-4" /> -->
            <a href="https://haier-hr.com" target="_blank" rel="noopener noreferrer">
                <img src="@/assets/img/haier-icon-black.png" alt="Haier Logo" class="w-[50px] absolute left-0 top-0 m-6" />
            </a>

            <SectionHeader titleAlign="false">
                <template #title>
                    <div class="w-full text-center mt-2 lg:mt-0">
                        {{ recruiterStore.isAuthenticated ? 'Add candidate to' : 'Apply to the' }}
                        <br class="lg:hidden"/>
                        <router-link 
                            :to="`/jobs/${job.id}`"
                            class="hover:text-primary-600 transition-colors underline"
                        >
                            {{ job.title || 'Job Position' }}
                        </router-link>
                        <br class="lg:hidden"/>
                        position
                        <br />
                        <h4 class="text-lg text-gray-500 w-full text-center mt-2">@ {{ job.company.name }}</h4>
                    </div>
                </template>
            </SectionHeader>

            <div class="px-4 max-w-[1440px] w-full pt-4 pb-[100px] xl:px-[108px] min-h-[90vh] flex flex-col items-center">

            <CandidateApplicationForm
                v-model="newCandidateForm"
                :loading="loading"
                @submit="handleSubmit"
                @fileSelected="filePDF = $event"
                />
            </div>
        </div>
        
        <div v-else class="flex flex-col items-center justify-center p-8">
            <p class="text-red-600">Job not found. Please check the URL and try again.</p>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useJobStore } from '@/stores/jobStore';
import { submitApplication, type Application } from '@/api_client';
import SectionHeader from '@/components/slots/SectionHeader.vue';
import CandidateApplicationForm from '@/components/candidate/CandidateApplicationForm.vue';
import LoadingLogo from '@/components/LoadingLogo.vue';
import { useRecruiterStore } from '@/stores/recruiterStore';

const recruiterStore = useRecruiterStore();
const route = useRoute();
const router = useRouter();
const jobStore = useJobStore();
const loading = ref(false);
const isLoading = ref(true);
const error = ref<string | null>(null);

const job = ref<any>(null);
const newCandidateForm = ref({
    fullname: '',
    email: '',
    phone: '',
    linkedin: '',
});

const filePDF = ref<File>();

const init = async () => {
    const jobId = Number(route.params.id);
    if (isNaN(jobId)) {
        error.value = 'Invalid job ID';
        isLoading.value = false;
        return;
    }
    
    try {
        error.value = null;
        const jobData = await jobStore.getJobById(jobId);
        if (jobData) {
            job.value = jobData;
        } else {
            error.value = 'Job not found';
        }
    } catch (err: any) {
        console.error('Error fetching job:', err);
        error.value = err.message || 'Failed to load job details';
        job.value = null;
    } finally {
        isLoading.value = false;
    }
};

onMounted(() => {
    init();
});

const isFormFilled = computed(() => {
    const { fullname, email, phone, linkedin } = newCandidateForm.value;
    
    const hasRequiredFields = 
        fullname?.trim().length > 0 && 
        email?.trim().length > 0 && 
        phone?.trim().length > 0;
    
    const hasFile = filePDF.value != null;
    console.log('hasFile:', hasFile);
    console.log('newcandidateform', newCandidateForm.value);
    
    return hasRequiredFields && hasFile;
})

const handleSubmit = async () => {
    if (isFormFilled.value) {
        loading.value = true;
        const formData = <Application>{
            resume: filePDF.value,
            full_name: newCandidateForm.value.fullname,
            email: newCandidateForm.value.email,
            phone: newCandidateForm.value.phone,
            linkedin: newCandidateForm.value.linkedin,
            job: Number(job.value.id)
        }
        try {
            const res = await submitApplication(formData);
            console.log(res);
            router.push({ name: 'success', params: { jobId: job.value.id } });
        } catch (err) {
            console.error(err);
        } finally {
            loading.value = false;
        }
    }
}

const handleFileUpload = (file: File) => {
    console.log('File selected:', file);
    // TODO: Handle file upload logic
};
</script>
