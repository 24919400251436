<template>
  <div class="flex flex-col">
    <div class="flex justify-between items-center">
      <div class="flex items-center gap-4">
        <h2 class="text-xl font-semibold">Kanban View ({{ candidates.length }} Applications)</h2>
        <Button
          icon="pi pi-arrows-h"
          :severity="isDragging ? 'warning' : 'secondary'"
          text
          rounded
          :badge="isDragging ? 'Moving...' : undefined"
          v-tooltip.right="isDragging ? 'Drop to change status' : 'Drag candidates to change their status'"
        />
      </div>
      <Button 
        icon="pi pi-list" 
        label="Switch to List" 
        severity="secondary" 
        outlined 
        rounded
        @click="$emit('switchView')" 
      />
    </div>
    
    <div class="kanban-container overflow-x-auto">
      <div class="kanban-grid">
        <div 
          v-for="status in statuses" 
          :key="status.value" 
          class="kanban-column" 
          :data-status="status.value"
          :data-collapsed="isColumnCollapsed(status.value)"
        >
          <div class="bg-gray-50 rounded-lg p-4 h-full column-border border border-gray-200">
            <div 
              class="flex items-center justify-between mb-4 cursor-pointer select-none sticky top-0 bg-gray-50 z-10"
              :class="{ 'vertical-title': isColumnCollapsed(status.value) }"
              @click="toggleColumn(status.value)"
            >
              <div class="flex items-center gap-2" :class="{ 'flex-col': isColumnCollapsed(status.value) }">
                <div :class="['w-2 h-2 rounded-full', status.color]" v-show="!isColumnCollapsed(status.value)"></div>
                <h3 class="font-medium" :class="{ 'vertical-text': isColumnCollapsed(status.value) }">
                  {{ status.label }}
                </h3>
              </div>
              <div class="flex items-center gap-2">
                <Tag rounded 
                    :value="getCandidatesByStatus(status.value).length" 
                    :style="[getStatusStyle(status.value), { 
                      width: '32px', 
                      height: '32px', 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'center', 
                      padding: '0',
                      fontSize: '12px'
                    }]"
                    class="candidates-status-count text-sm text-gray-500">
                    {{ getCandidatesByStatus(status.value).length }}
                </Tag>
                <i :class="['pi text-gray-400 transition-transform', {
                  'pi-chevron-down': !isColumnCollapsed(status.value),
                  'pi-chevron-right': isColumnCollapsed(status.value)
                }]" />
              </div>
            </div>
              
            <Draggable
              v-show="!isColumnCollapsed(status.value)"
              :list="getCandidatesByStatus(status.value)"
              :group="{ name: 'candidates' }"
              item-key="id"
              ghost-class="ghost"
              @start="isDragging = true"
              @end="handleDragEnd"
              class="space-y-3 min-h-[50px] transition-all overflow-y-auto kanban-cards"
            >
              <template #item="{ element: candidate }">
                <div
                  class="bg-white p-4 rounded-lg shadow-sm border border-gray-100 cursor-move hover:shadow-md transition-all"
                  @click.stop="handleCandidateClick(candidate)"
                >
                  <div class="flex items-start justify-between">
                    <div>
                      <h4 class="font-medium text-gray-900">
                        {{ candidate.details?.fullname || candidate.applicant_name }}
                      </h4>
                      <p class="text-sm text-gray-500">{{ candidate.location }}</p>
                    </div>
                    <div class="flex flex-col items-end">
                      <div class="flex items-center gap-1">
                        <i class="pi pi-star-fill text-blue-500 text-sm"></i>
                        <span class="text-sm font-medium">{{ candidate.ai_score }}%</span>
                      </div>
                      <span class="text-xs text-gray-400">
                        {{ formatDate(candidate.date) }}
                      </span>
                    </div>
                  </div>
                </div>
              </template>
            </Draggable>
          </div>
        </div>
      </div>
    </div>
    <CandidateDrawer
      v-model:visible="isDrawerVisible"
      v-model:candidate="selectedCandidate"
      v-model:inviteDialogVisible="isInviteDialogVisible"
      :isKanbanView="true"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useLocalStorage } from '@vueuse/core';
import Button from 'primevue/button';
import { useToast } from 'primevue/usetoast';
import Draggable from 'vuedraggable';
import type { Candidate, ApplicationStatus } from '@/stores/candidateStore';
import { useCandidateStore } from '@/stores/candidateStore';
import dayjs from 'dayjs';
import Tag from 'primevue/tag';
import { getStatusStyle } from '@/utils/style';
import CandidateDrawer from './CandidateDrawer.vue';
const props = defineProps<{
  candidates: Candidate[];
}>();

const toast = useToast();
const candidateStore = useCandidateStore();
const isDragging = ref(false);
const collapsedColumns = useLocalStorage<string[]>('kanban-collapsed-columns', [
  'airejected',  // Default collapsed columns
  'rejected',
  'no_decision',
]);
const isDrawerVisible = ref(false);
const selectedCandidate = ref<Candidate | null>(null);
const isInviteDialogVisible = ref(false);

defineEmits<{
  (e: 'switchView'): void;
  (e: 'selectCandidate', candidate: Candidate): void;
}>();

const statuses = [
  { label: 'No Decision', value: 'no_decision', color: 'bg-orange-500' },
  { label: 'New', value: 'newapplicant', color: 'bg-blue-500' },
  { label: 'AI Selected', value: 'aiselected', color: 'bg-green-500' },
  { label: 'Selected', value: 'selected', color: 'bg-emerald-500' },
  { label: 'Interview', value: 'interview', color: 'bg-yellow-500' },
  { label: 'Offer', value: 'offer', color: 'bg-purple-500' },
  { label: 'AI Rejected', value: 'airejected', color: 'bg-red-500' },
  { label: 'Rejected', value: 'rejected', color: 'bg-red-500' },
];

const toggleColumn = (status: string) => {
  const index = collapsedColumns.value.indexOf(status);
  if (index === -1) {
    collapsedColumns.value.push(status);
  } else {
    collapsedColumns.value.splice(index, 1);
  }
};

const isColumnCollapsed = (status: string) => {
  return collapsedColumns.value.includes(status);
};

const getCandidatesByStatus = (status: string) => {
  return props.candidates.filter(c => c.status === status);
};

const handleDragEnd = async (event: any) => {
  isDragging.value = false;
  
  if (!event.to || !event.item) return;
  
  const columnElement = event.to.closest('.kanban-column');
  const newStatus = columnElement?.querySelector('.font-medium')?.textContent?.trim().toLowerCase().replace(/\s+/g, '') as ApplicationStatus;
  const candidate = event.item.__draggable_context?.element as Candidate;
  
  if (!candidate || !newStatus) return;
  
  try {
    await candidateStore.updateCandidateStatus(candidate.id, newStatus as ApplicationStatus);
    
    toast.add({
      severity: 'success',
      summary: 'Status Updated',
      detail: `${candidate.applicant_name} moved to ${statuses.find(s => s.value === newStatus)?.label}`,
      life: 3000
    });
  } catch (error) {
    toast.add({
      severity: 'error',
      summary: 'Update Failed',
      detail: 'Failed to update candidate status',
      life: 3000
    });
    
    // await candidateStore.fetchCandidates();
  }
};

const formatDate = (date: string) => {
  return dayjs(date).format('MMM D');
};

const handleCandidateClick = (candidate: Candidate) => {
  selectedCandidate.value = candidate;
  isDrawerVisible.value = true;
};
</script>

<style scoped>
.kanban-container {
  width: 100%;
  height: calc(100vh - 120px);
  overflow-x: auto;
  margin: 0;
  padding: 0 1rem;
}

.kanban-grid {
  display: flex;
  gap: 1rem;
  min-width: min-content;
  height: 100%;
  padding: 0.5rem 0;
}

.kanban-column {
  flex: 1 1 300px;
  min-width: 300px;
  height: 100%;
  transition: all 0.3s ease;
}

.kanban-column > div {
  border: 1px solid #e5e7eb;  /* Tailwind's gray-200 */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.kanban-column[data-collapsed="true"] {
  flex: 0 0 60px;
  min-width: unset;
}

.kanban-cards {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  scrollbar-width: thin;
}

.kanban-cards::-webkit-scrollbar {
  width: 6px;
}

.kanban-cards::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.kanban-cards::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.ghost {
  opacity: 0.5;
  background: #c8e6c9;
}

.vertical-title {
  min-height: 120px;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vertical-text {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  white-space: nowrap;
}

.vertical-title .candidates-status-count {
  margin-top: 10px;
  transform: rotate(90deg);
  width: 32px !important;
  height: 32px !important;
  font-size: 12px !important;
}

.kanban-column .column-border {
  min-height: 200px;
}
</style>