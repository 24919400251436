<script setup lang="ts">

const props = defineProps({
    isColored: {
        type: Boolean,
        default: true
    }
})


</script>

<template>
    <img v-if="isColored" src="../assets/img/haier-color.png" alt="Haier Logo">
    <img v-else src="../assets/img/haier-black.png" alt="Haier Logo">
</template>