<template>
    <Drawer v-model:visible="visibleDrawer" position="right" 
        class="basis-11/12 sm:basis-5/6 lg:basis-3/4 xl:basis-3/5 max-w-[1200px] overflow-y-auto bg-white border-l border-[#f2f4f7]"
        @hide="unselectCandidate">
        <template #container="{ closeCallback }">
            <div class="flex flex-col h-full">
                <div class="min-h-[92px] px-4 sm:px-10 py-[23px] bg-white border-b border-[#f2f4f7]">
                    <div class="flex items-center justify-between gap-4">
                        <!-- Left section -->
                        <div class="flex items-center gap-3 sm:gap-6">
                            <Button icon="pi pi-times" class="p-1.5 bg-[#f2f4f7] rounded-md" @click="closeCallback" />
                            <div v-if="!isKanbanView" class="flex items-center gap-1.5">
                                <Button outlined icon="pi pi-angle-up" class="p-1.5 rounded-md" @click="selectPrevCandidate" />
                                <Button outlined icon="pi pi-angle-down" class="p-1.5 rounded-md" @click="selectNextCandidate" />
                            </div>
                        </div>
                        
                        <!-- Right section -->
                        <div class="flex items-center gap-2 sm:gap-3.5">
                            <!-- Status select for desktop -->
                            <Select
                                v-if="selectedCandidate && !isMobile"
                                v-model="selectedCandidate.status"
                                :options="statusOptions"
                                optionLabel="label"
                                optionValue="value"
                                @update:modelValue="handleStatusChange"
                                class="w-[200px] hidden md:block status-select"
                                :class="{'p-invalid': !selectedCandidate.status}"
                            />
                            <Button v-if="!selectedCandidate?.invite_to_interview?.isInvited"
                                rounded
                                label="Invite to interview"
                                class="px-3 sm:px-4 py-2.5 bg-[#0066ff] text-white text-sm sm:text-base whitespace-nowrap"
                                @click.stop="inviteDialogVisible = true"
                            />
                        </div>
                    </div>
                </div>

                <!-- Content Area -->
                <div class="flex-1 p-4 sm:p-8">
                    <template v-if="!isLoading && applicationDetails">
                        <CandidateDetailsCard :details="selectedCandidate?.details"
                            :applicationDetails="applicationDetails"
                            :jobId="selectedCandidate?.job_id"
                            :candidate="selectedCandidate" />

                        <!-- AI Analysis Section -->
                        <div class="mt-8 flex-col justify-center items-start gap-4 inline-flex w-full">
                            <!-- AI Score Header -->
                            <div class="justify-start items-center gap-2 inline-flex">
                                <div class="p-1.5 bg-white rounded-md justify-start items-center gap-2.5 flex">
                                    <img src="@/assets/icons/spark.svg" class="w-4 h-4" alt="AI Score" />
                                </div>
                                <div class="text-[#040813] text-xl font-medium font-['Sora'] leading-7">
                                    AI Score: {{ applicationDetails.score }}
                                </div>
                                <div v-if="applicationDetails?.score_reason" 
                                     @click="isScoreReasonExpanded = !isScoreReasonExpanded"
                                     class="flex items-center gap-2 cursor-pointer group ml-2">
                                    <div class="relative flex items-center gap-1.5 text-blue-600 px-3 py-1.5 rounded-full 
                                                bg-blue-50/50 hover:bg-blue-100/50 transition-all duration-300">
                                        <i class="pi pi-info-circle text-xs"></i>
                                        <span class="text-sm font-medium">Analysis</span>
                                        <i :class="['pi text-xs transform transition-transform duration-300', 
                                                   isScoreReasonExpanded ? 'pi-chevron-up rotate-180' : 'pi-chevron-down rotate-0',
                                                   {'animate-attention': !isScoreReasonExpanded}]">
                                        </i>
                                        <!-- Pulse effect -->
                                        <span v-if="!isScoreReasonExpanded" 
                                              class="absolute inset-0 rounded-full border-2 border-blue-200 animate-ping-slow">
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <!-- Collapsible Score Reason -->
                            <transition
                                enter-active-class="transition duration-200 ease-out"
                                enter-from-class="transform opacity-0 -translate-y-1"
                                enter-to-class="transform opacity-100 translate-y-0"
                                leave-active-class="transition duration-200 ease-in"
                                leave-from-class="transform opacity-100 translate-y-0"
                                leave-to-class="transform opacity-0 -translate-y-1"
                            >
                                <div v-if="isScoreReasonExpanded && applicationDetails?.score_reason" 
                                     class="w-full p-4 bg-blue-50/50 rounded-xl border border-blue-100 mb-4">
                                    <div class="flex items-start gap-3">
                                        <i class="pi pi-info-circle text-blue-500 mt-0.5"></i>
                                        <div class="flex flex-col gap-2">
                                            <h3 class="text-[#040813] text-sm font-semibold font-['Sora']">AI Match Analysis</h3>
                                            <p class="text-[#636770] text-sm font-normal font-['Sora']" v-html="parsedScoreReason"></p>
                                        </div>
                                    </div>
                                </div>
                            </transition>

                            <!-- Radar Chart Container -->
                            <div class="p-2 sm:px-8 sm:py-4 bg-gray-50 rounded-2xl border border-[#f2f4f7] inline-flex w-full">
                                <div class="w-full bg-gray-50 justify-center items-start inline-flex">
                                    <Chart v-if="skillsChartData" 
                                           ref="chart"
                                           type="radar" 
                                           :data="skillsChartData" 
                                           :options="radarOptions" 
                                           class="w-full" 
                                           :style="{ minHeight: isMobile ? '300px' : '400px' }" />
                                </div>
                            </div>
                            <div class="flex justify-end mb-2">
                                <Button 
                                    :label="showFullSkillLabels ? 'Wrap Labels' : 'Show Full Labels'"
                                    link
                                    @click="showFullSkillLabels = !showFullSkillLabels"
                                    class="text-sm text-blue-600 hover:text-blue-700"
                                />
                            </div>

                            <!-- New Strengths and Weaknesses section -->
                            <div class="self-stretch flex flex-col gap-8 mt-8">
                                <!-- Strengths Section -->
                                <div class="flex-col justify-start items-start gap-4">
                                    <div class="text-[#040813] text-lg font-medium font-['Sora'] leading-tight mb-4">
                                        Strengths
                                    </div>
                                    <div class="grid grid-cols-1 md:grid-cols-2 gap-3 w-full">
                                        <template
                                            v-for="(strength, index) in showAllStrengths ? applicationDetails.strengths : applicationDetails.strengths?.slice(0, 6)"
                                            :key="index">
                                            <div class="flex items-start gap-2 p-3 bg-[#f8fafc] rounded-lg">
                                                <i class="pi pi-check-circle text-[#0066ff] mt-0.5"></i>
                                                <span class="text-[#475569] text-sm">{{ strength }}</span>
                                            </div>
                                        </template>
                                    </div>
                                    <div v-if="applicationDetails.strengths?.length > 6"
                                        class="text-[#0066ff] text-sm cursor-pointer mt-2 hover:underline"
                                        @click="toggleShowAllStrengths">
                                        {{ showAllStrengths ? 'Show less' : `Show ${applicationDetails.strengths.length
                                        - 6} more` }}
                                    </div>
                                </div>

                                <!-- Weaknesses Section -->
                                <div class="flex-col justify-start items-start gap-4">
                                    <div class="text-[#040813] text-lg font-medium font-['Sora'] leading-tight mb-4">
                                        Areas for Improvement
                                    </div>
                                    <div class="grid grid-cols-1 md:grid-cols-2 gap-3 w-full">
                                        <template
                                            v-for="(weakness, index) in showAllWeaknesses ? applicationDetails.weaknesses : applicationDetails.weaknesses?.slice(0, 6)"
                                            :key="index">
                                            <div class="flex items-start gap-2 p-3 bg-[#fff1f2] rounded-lg">
                                                <i class="pi pi-exclamation-circle text-[#dc2626] mt-0.5"></i>
                                                <span class="text-[#475569] text-sm">{{ weakness }}</span>
                                            </div>
                                        </template>
                                    </div>
                                    <div v-if="applicationDetails.weaknesses?.length > 6"
                                        class="text-[#0066ff] text-sm cursor-pointer mt-2 hover:underline"
                                        @click="toggleShowAllWeaknesses">
                                        {{ showAllWeaknesses ? 'Show less' : `Show
                                        ${applicationDetails.weaknesses.length - 6} more` }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="flex justify-center items-center p-8">
                            <ProgressSpinner />
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </Drawer>
</template>

<script setup lang="ts">
import { onBeforeUnmount, watchEffect, onMounted, computed, ref, watch } from 'vue';
import Button from 'primevue/button';
import Drawer from 'primevue/drawer';
import DataTable from 'primevue/datatable';
import CandidateDetailsCard from './slots/CandidateDetailsCard.vue';
import { findSingle } from "@primeuix/utils/dom";
import { useCandidateStore, type ApplicationDetails, type ApplicationSkill, type ApplicationStatus, type Candidate, type CandidateDetails } from '@/stores/candidateStore';
import Chart from 'primevue/chart';
import ProgressSpinner from 'primevue/progressspinner';
import Select from 'primevue/select';
import { useToast } from 'primevue/usetoast';
import { marked } from 'marked';

// Props definition should be at the top
const props = defineProps<{
    isKanbanView?: boolean
}>();

// Status options for the select
const statusOptions = [
    { label: 'New Applicant', value: 'newapplicant' },
    { label: 'AI Selected', value: 'aiselected' },
    { label: 'AI Rejected', value: 'airejected' },
    { label: 'Interview', value: 'interview' },
    { label: 'Selected', value: 'selected' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Offer', value: 'offer' },
    { label: 'No Decision', value: 'no_decision' }
] as const;

const toast = useToast();

// Handler for status change
const handleStatusChange = async (newStatus: string) => {
    if (!selectedCandidate.value?.id) return;
    
    try {
        await candidateStore.updateCandidateStatus(selectedCandidate.value.id, newStatus as ApplicationStatus);
        
        toast.add({
            severity: 'success',
            summary: 'Status Updated',
            detail: `Candidate status has been changed to ${statusOptions.find(opt => opt.value === newStatus)?.label}`,
            life: 3000,
        });
    } catch (error) {
        console.error('Failed to update status:', error);
        
        toast.add({
            severity: 'error',
            summary: 'Update Failed',
            detail: 'Failed to update candidate status. Please try again.',
            life: 3000,
        });
    }
};

const inviteDialogVisible = defineModel('inviteDialogVisible', { required: true })
const visibleDrawer = defineModel('visible', { required: true })
const selectedCandidate = defineModel<Candidate | null>('candidate', { required: true })
// Update the table prop definition to be optional
const candidatesTable = defineModel<InstanceType<typeof DataTable> | undefined>('table', { required: false })

const candidateStore = useCandidateStore();
const applicationDetails = ref<ApplicationDetails | null>(null);
const isLoading = ref(false);

// Update the watchEffect to properly handle the state
watchEffect(async () => {
    if (selectedCandidate.value?.id) {
        isLoading.value = true;
        applicationDetails.value = null;

        try {
            const details = await candidateStore.fetchApplicationDetailsById(String(selectedCandidate.value.id));
            if (details) {
                applicationDetails.value = details;

                // Update candidate details if needed
                if (selectedCandidate.value && !selectedCandidate.value.details) {
                    const candidateDetails: CandidateDetails = {
                        avatar_src: '/default-avatar.png',
                        fullname: details.full_name,
                        role: details.current_position || details.status, // Use current_position if available
                        contact: {
                            email: details.email,
                            phone: details.phone,
                            location: details.location,
                            available_date: new Date(),
                        },
                        linkedin_link: details.linkedin,
                        experience: Array.isArray(details.experiences) ? details.experiences.join('\n') : '',
                        skills: Array.isArray(details.skills) ? details.skills : [],
                        education: Array.isArray(details.academic_studies) ? details.academic_studies : [],
                        languages: details.languages || [],
                        previous_roles: details.work_experiences?.map(exp => ({
                            company: exp.company,
                            role: exp.position,
                            duration: exp.duration
                        })) || []
                    };

                    selectedCandidate.value.details = candidateDetails;
                }
            }
        } catch (error) {
            console.error('Error fetching application details:', error);
        } finally {
            isLoading.value = false;
        }
    }
});

// Safely handle component setup
onMounted(() => {
    watchEffect(() => {
        if (visibleDrawer.value && !selectedCandidate.value) {
            visibleDrawer.value = false;
        }
    });
});

const onRowSelect = (event: any) => {
    visibleDrawer.value = true
}

const selectCandidate = (direction: string) => {
    if (!candidatesTable.value) return;

    try {
        const body = candidatesTable.value.$el;
        if (!body) return;

        const selectedRow = findSingle(body, 'tr[data-p-selected="true"]');
        if (!selectedRow) return;

        const newSelectableRow = direction === 'next'
            ? candidatesTable.value.findNextSelectableRow?.(selectedRow)
            : candidatesTable.value.findPrevSelectableRow?.(selectedRow);

        if (!newSelectableRow) return;

        candidatesTable.value.focusRowChange?.(selectedRow, newSelectableRow);

        const data = candidatesTable.value.dataToRender?.();
        if (!data) return;

        const newRowIndex = newSelectableRow.getAttribute('data-p-index');
        if (newRowIndex === null) return;

        const event = new MouseEvent('row-click');
        candidatesTable.value.onRowClick?.({
            originalEvent: event,
            data: data[newRowIndex],
            index: newRowIndex
        });
    } catch (error) {
        console.error('Error selecting candidate:', error);
    }
};

const selectNextCandidate = () => selectCandidate('next');
const selectPrevCandidate = () => selectCandidate('prev');

const unselectCandidate = () => {
    if (selectedCandidate.value) {
        selectedCandidate.value = null;
    }
}

onBeforeUnmount(() => {
    unselectCandidate();
})



// Status severity mapping
const getStatusSeverity = (status: string) => {
    const severityMap: { [key: string]: string } = {
        'selected': 'success',
        'rejected': 'danger',
        'interview': 'warning',
        'newapplicant': 'info'
    };
    return severityMap[status] || 'info';
};

onMounted(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', checkScreenSize);
});

const isMobile = ref(false);

onMounted(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', checkScreenSize);
});

const checkScreenSize = () => {
    isMobile.value = window.innerWidth <= 640; // Changed to match sm breakpoint
    showFullSkillLabels.value = window.innerWidth > 640;
};

// Update radarOptions configuration
const radarOptions = {
    scales: {
        r: {
            min: 0,
            max: 100,
            beginAtZero: true,
            backgroundColor: '#ffffff',
            pointLabels: {
                font: {
                    family: 'Sora',
                    size: isMobile.value ? 11 : 13,
                    weight: '500',
                },
                color: '#1e293b',
                padding: 0,
                callback: (label: string) => {
                    if (!showFullSkillLabels.value) {
                        return label.slice(0,10) + '...';
                    }
                    // Split label into multiple lines if longer than 12 characters
                    else if (label.length > 12) {
                        const words = label.split(' ');
                        let lines = [];
                        let currentLine = '';
                        
                        for (const word of words) {
                            if (currentLine.length + word.length > 12) {
                                lines.push(currentLine.trim());
                                currentLine = word;
                            } else {
                                currentLine += (currentLine ? ' ' : '') + word;
                            }
                        }
                        lines.push(currentLine.trim());
                        return lines;
                    }

                    return label;
                }
            },
            grid: {
                color: '#e2e8f0', // Lighter grid lines
                circular: false
            },
            angleLines: {
                display: true,
                color: '#e2e8f0' // Matching grid lines
            },
            ticks: {
                display: true,
                maxTicksLimit: 5,
                color: '#64748b', // Softer tick color
                font: {
                    size: isMobile.value ? 10 : 11
                },
                backdropColor: 'transparent',
                stepSize: 20
            }
        }
    },
    plugins: {
        legend: {
            position: 'bottom',
            labels: {
                usePointStyle: true,
                pointStyle: 'circle',
                padding: isMobile.value ? 15 : 20,
                color: '#1e293b', // Darker text
                font: {
                    family: 'Sora',
                    size: isMobile.value ? 11 : 12,
                    weight: '500'
                }
            }
        }
    },
    maintainAspectRatio: false,
    responsive: true,
    layout: {
        padding: {
            top: isMobile.value ? 10 : 20,
            bottom: isMobile.value ? 10 : 20,
            left: isMobile.value ? 5 : 10,
            right: isMobile.value ? 5 : 10
        }
    }
};

const processSkillsForChart = (skills: ApplicationSkill[]) => {
    const sortedSkills = [...skills]
        .sort((a, b) => b.score - a.score);
    return {
        labels: sortedSkills.map(s => s.skill_name),
        datasets: [{
            label: 'Hard Skills',
            data: sortedSkills.filter(s => s.skill_type === 'hardskill').map(s => s.score),
            backgroundColor: 'rgba(0, 102, 255, 0.15)', // Brand blue with lower opacity
            borderColor: 'rgba(0, 102, 255, 0.8)', // Brand blue
            pointBackgroundColor: '#0066ff',
            pointBorderColor: '#ffffff',
            pointHoverBackgroundColor: '#ffffff',
            pointHoverBorderColor: '#0066ff',
            borderWidth: 2,
            pointRadius: 4,
            pointHoverRadius: 6
        }, {
            label: 'Soft Skills',
            data: sortedSkills.filter(s => s.skill_type === 'softskill').map(s => s.score),
            backgroundColor: 'rgba(255, 99, 132, 0.15)', // Pink with lower opacity
            borderColor: 'rgba(255, 99, 132, 0.8)', // Pink
            pointBackgroundColor: '#ff6384',
            pointBorderColor: '#ffffff',
            pointHoverBackgroundColor: '#ffffff',
            pointHoverBorderColor: '#ff6384',
            borderWidth: 2,
            pointRadius: 4,
            pointHoverRadius: 6
        }]
    };
};

const skillsChartData = computed(() => {
    if (!applicationDetails.value?.skills?.length) return null;
    return processSkillsForChart(applicationDetails.value.skills);
});

const showAllStrengths = ref(false);
const showAllWeaknesses = ref(false);

const toggleShowAllStrengths = () => {
    showAllStrengths.value = !showAllStrengths.value;
};

const toggleShowAllWeaknesses = () => {
    showAllWeaknesses.value = !showAllWeaknesses.value;
};

const isScoreReasonExpanded = ref(false);

const parsedScoreReason = computed(() => {
    if (!applicationDetails.value?.score_reason) return '';
    return marked(applicationDetails.value.score_reason);
});

onMounted(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', checkScreenSize);
});

const showFullSkillLabels = ref(window.innerWidth > 640);


const chart = ref<InstanceType<typeof Chart> | null>(null);

watch(showFullSkillLabels, (newVal: boolean) => {
    chart.value?.refresh()
});
</script>

<style scoped>

.grid {
    display: grid;
}

.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

@media (min-width: 768px) {
    .md\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.grid>* {
    transition: all 0.3s ease-in-out;
}


/* Add these new styles at the end of the style block */
.pi-chevron-up,
.pi-chevron-down {
    display: inline-block;
    font-size: 0.8rem;
}

@keyframes ping-slow {
    0% {
        transform: scale(1);
        opacity: 0.4;
    }
    50% {
        transform: scale(1.05);
        opacity: 0.2;
    }
    100% {
        transform: scale(1);
        opacity: 0.4;
    }
}

@keyframes attention {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(2px);
    }
}

.animate-ping-slow {
    animation: ping-slow 2s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.animate-attention {
    animation: attention 2s ease-in-out infinite;
}

.transform {
    transform-origin: center;
}

.rotate-0 {
    transform: rotate(0deg);
}

.rotate-180 {
    transform: rotate(180deg);
}

/* Add these specific button styles */
:deep(.p-button.p-button-outlined) {
    border-color: #e5e7eb;
    color: #374151;
}

:deep(.p-button.p-button-outlined:hover) {
    background: #f3f4f6;
    border-color: #d1d5db;
}

.p-drawer {
    @apply border-l border-[#f2f4f7];
}

/* Add these styles for better drawer sizing */
:deep(.p-drawer) {
    @apply min-w-[calc(100vw-2rem)] sm:min-w-[500px];
}

:deep(.p-drawer-content) {
    @apply w-full;
}

/* Update grid gap for mobile */
.grid {
    gap: 0.75rem;
}

@media (min-width: 640px) {
    .grid {
        gap: 1rem;
    }
}




:deep(.status-select) {
    .p-dropdown-label {
        white-space: normal;
        min-height: 2.5rem;
        display: flex;
        align-items: center;
        line-height: 1.2;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }

    .p-dropdown-item {
        white-space: normal;
        line-height: 1.2;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .p-dropdown-panel {
        .p-dropdown-items {
            padding: 0.25rem;
        }
    }
}



.card {
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
}


</style>