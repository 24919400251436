import type { App } from 'vue'
import * as Sentry from "@sentry/vue"
import Clarity from '@microsoft/clarity'
import VueCookieComply from 'vue-cookie-comply'
import VueGtag from 'vue-gtag'
import 'vue-cookie-comply/dist/style.css'
import { getCurrentInstance } from 'vue'
import type { Router } from 'vue-router'
// Add type for geolocation response
interface GeoResponse {
    country_code: string;
}

// Add EU countries array
const EU_COUNTRIES = [
    'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR',
    'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL',
    'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE'
];

/**
 * Checks if the user is located in an EU country
 * @returns Promise<boolean> - True if user is in EU, false otherwise
 */
export function isEUUser(): Promise<boolean> {
    return true // todo

    // return fetch('https://ipapi.co/json')
    //     .then(res => res.json())
    //     .then((response: { country_code: string }) => {
    //         return EU_COUNTRIES.includes(response.country_code);
    //     })
    //     .catch(error => {
    //         console.error('Error detecting location:', error);
    //         return true;
    //     });
}

/**
 * Initializes Sentry monitoring with basic configuration
 * @param app - Vue application instance
 * @param router - Vue router instance
 * @param enableTracking - Whether to enable full tracking features
 */
export const initializeSentry = (app: App, router: Router, enableTracking: boolean = false) => {
    if (import.meta.env.VITE_SENTRY_DSN) {
        const integrations = enableTracking ? [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration(),
        ] : [];

        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            integrations,
            // Only enable detailed tracking if consent is given
            tracesSampleRate: enableTracking ? 1.0 : 0,
            replaysSessionSampleRate: enableTracking ? 1.0 : 0,
            replaysOnErrorSampleRate: enableTracking ? 1.0 : 0,
            profilesSampleRate: enableTracking ? 1.0 : 0,
            environment: import.meta.env.VITE_APP_ENV
        });
    }
}

/**
 * Initializes Microsoft Clarity analytics
 */
const initializeClarity = () => {
    Clarity.init('p8n3g9o896');
}



// Define the type for cookie preferences
interface CookiePreferenceItem {
    label: string;
    value: string;
    isEnable: boolean;
}

interface CookiePreference {
    title: string;
    description: string;
    items: CookiePreferenceItem[];
}

export const cookieSettings = {
    cookiePreferences: [] as CookiePreference[],
    onAccept: () => {},
    onSaved: (params: any) => {}
}

/**
 * Initializes cookie consent and analytics based on user location
 * @param app - Vue application instance
 * @param router - Vue router instance
 */
export const initializeConsent = async (app: App, router: any): Promise<void> => {
    // Initialize Google Analytics with consent mode (disabled by default)
    // true when cookie cookie-comply == all or contains ga
    const enableGA = localStorage.getItem('cookie-comply') === 'all' || localStorage.getItem('cookie-comply')?.includes('ga') || false


    app.use(VueGtag, {
        config: {
            id: "G-NKM91SYFWM",
            cookie_flags: 'SameSite=None;Secure',
        },
        enabled: enableGA,
    }, router)
    app.use(VueCookieComply)

    let isEU = true
    try {
        isEU = await isEUUser();
    } catch (error) {
        console.error('Error detecting location:', error);
        isEU = true
    }

    if (isEU) {
        // Initialize cookie consent for EU users
    } else {
        // For non-EU users, enable analytics by default
        const gtag = app.config.globalProperties.$gtag
        gtag.optIn()
        initializeSentry(app, router, true)
        initializeClarity()
    }

    const cookiePreferences = [
        {
          title: 'Analytics',
          description:
            'This website uses cookies to improve your experience.',
          items: [
            { label: 'GoogleAnalytics', value: 'ga', isEnable: true },
            { label: 'Sentry', value: 'sentry', isEnable: true },
            { label: 'Clarity', value: 'msclarity', isEnable: true },
          ],
        },
      ]

    const onAccept = () => {
        const gtag = app.config.globalProperties.$gtag
        gtag.optIn()
        initializeSentry(app, router, true)
        initializeClarity()
    }

    const onSaved = (res: any) => {
        if (res.includes('ga')) {
            const gtag = app.config.globalProperties.$gtag
            console.log(gtag)
            gtag.optIn()
        }
        if (res.includes('sentry')) {
            // Re-initialize Sentry with full tracking enabled
            initializeSentry(app, router, true)
        }
        if (res.includes('msclarity')) {
            initializeClarity()
        }
    }

    cookieSettings.cookiePreferences = cookiePreferences
    cookieSettings.onAccept = onAccept
    cookieSettings.onSaved = onSaved
}

