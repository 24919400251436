<template>
    <Dialog v-if="selectedCandidate" v-model:visible="inviteDialogVisible" class="w-[500px] mx-auto"
        id="inviteDialog"
        pt:root:class="!border-0" pt:mask:class="backdrop-blur-sm"
        :closable="false"
        :header="inviteSent ? `Invitation Sent!` : `Invite ${candidateName} to an interview`">
        <div class="flex flex-col gap-5">

            <template v-if="inviteSent === false">
                <SelectButton v-model="interviewTypeSelected" :options="inviteOptions" optionDisabled="disabled" optionLabel="title" dataKey="title" aria-labelledby="custom" class="w-full mySelector" >
                    <template #option="slotProps">
                        <div class="text-base font-medium h-full flex flex-col justify-between gap-2 mx-3 mt-1 mb-3"
                             :title="slotProps.option.disabled ? 'Coming soon' : ''"
                             :class="slotProps.option.disabled ? 'text-gray-500 cursor-not-allowed' : ''"
                             >
                            <h3> {{slotProps.option.title}} </h3>
                            <p class="text-sm font-normal"> {{slotProps.option.body}} </p>
                        </div>
                    </template>
                </SelectButton>
                <div class="flex flex-row justify-between">
                    <Button class="basis-2/5" type="button" label="Cancel" outlined rounded severity="secondary" @click="inviteDialogVisible = false" />
                    <Button class="basis-2/5" type="button" label="Invite" rounded :disabled="!interviewTypeSelected" severity="primary" @click="handleSendInvite" />
                </div>
            </template>

            <template v-else>
                <div class="flex justify-center items-center">
                    <img src="@/assets/img/inviteSent.svg" alt="Invite Sent" class="w-[110px]" />
                </div>
                <Button class="grow" type="button" label="Close" outlined rounded :disabled="!interviewTypeSelected" severity="primary" @click="inviteDialogVisible = false" />
            </template>

        </div>
    </Dialog>
</template>

<script setup lang="ts">
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import SelectButton from 'primevue/selectbutton';
import { computed, reactive, ref } from 'vue';
import { useCandidateStore, type Candidate } from '@/stores/candidateStore';
import { useConfigStore } from '@/stores/configStore';

const configStore = useConfigStore()
const candidateStore = useCandidateStore()
const inviteDialogVisible = defineModel<boolean>('visible', { required: true })
const selectedCandidate = defineModel<Candidate>('candidate', { required: true })

const inviteOptions = reactive([
    { value: 0, title: 'AI interview', body: 'Automated interview that the candidate can take at any time', disabled: !configStore.switches.ENABLE_INVITES_TO_AI_INTERVIEW },
    { value: 1, title: 'Human interview', body: 'A classic video-call interview with a team member' },
])

const interviewTypeSelected = ref(inviteOptions.find(option => !option.disabled) || inviteOptions[0])

const candidateName = computed(() => {
    return selectedCandidate.value?.details?.fullname || 
           selectedCandidate.value?.applicant_name || 
           'Candidate';
});

const inviteSent = computed(() => 
    selectedCandidate.value?.invite_to_interview?.isInvited || false
);

const handleSendInvite = () => {
    if (selectedCandidate.value?.id) {
        candidateStore.inviteCandidateToInterview(
            selectedCandidate.value.id, 
            interviewTypeSelected.value.title
        );
        inviteDialogVisible.value = false;
    }
};
</script>

<style>
.mySelector .p-togglebutton, .mySelector .p-togglebutton.p-togglebutton-checked {
    background-color: var(--p-surface-0) !important;
    flex-basis: 45%;
    flex-grow: 1;
    color: var(--p-primary-color-text);
    border: none;
}
.mySelector .p-togglebutton::before {
    border: solid 1px var(--p-surface-100);
    background-color: var(--p-grey-50);

}
.mySelector .p-togglebutton:hover::before {
    background-color: var(--p-surface-200);
}

.mySelector .p-togglebutton.p-togglebutton-checked::before {
    border: solid 1px var(--p-primary-color);
    background-color: var(--p-primary-50);
}
</style>
