<template>
    <div class="self-stretch flex flex-row items-start justify-start py-0 px-4 box-border max-w-full">
        <form class="flex-1 flex flex-col items-start justify-start gap-[28px] max-w-full">
            <div class="self-stretch flex flex-col items-start justify-start relative gap-[4px] max-w-full">
                <div class="flex flex-col gap-2 self-stretch">
                    <slot name="input"></slot>
                </div>
            </div>
            <slot name="button"></slot>
        </form>
    </div>
</template>