<template>
    <Card>
        <template #content>
            <div class='max-w-[1040px] flex flex-col justify-start gap-[38px] mq675:gap-[19px] pt-[4px] pb-[4px]'>
                <div class="flex flex-row justify-between">
                    <SectionHeader :is-sub-section="true" title-align="start">
                        <template #title>AI Score: {{ interviewReport?.score }}/100</template>
                    </SectionHeader>
                    <Button v-if="!isModal" outlined severity="primary" rounded label="View score card" @click="$emit('openModal')"></Button>
                </div>
                <SectionHeader :is-sub-section="true" title-align="start">
                    <template #title>Hard Skills Scores</template>
                </SectionHeader>
                <div class="flex flex-col justify-center">
                    <div
                        class="flex justify-center l-subsection-background-color rounded-md border-[1px] border-solid l-section-border-color">
                        <Chart type="radar" :data="HS_chartData" :options="chartOptions" class="w-full md:w-[40rem] flex flex-row justify-center" />
                    </div>
                    <ParagraphSection>
                        <template #description>
                            {{ interviewReport?.hard_skills_description }}
                        </template>
                    </ParagraphSection>
                </div>
                <Divider />
                <SectionHeader :is-sub-section="true" title-align="start">
                    <template #title>Soft Skills Scores</template>
                </SectionHeader>
                <div class="flex flex-col justify-center">
                    <div
                        class="flex justify-center l-subsection-background-color rounded-md border-[1px] border-solid l-section-border-color">
                        <Chart type="radar" :data="SS_chartData" :options="chartOptions" class="w-full md:w-[40rem] flex flex-row justify-center" />
                    </div>
                    <ParagraphSection>
                        <template #description>
                            {{ interviewReport?.soft_skills_description }}
                        </template>
                    </ParagraphSection>
                </div>
                <Divider />
                <SideBySide>
                    <template #first>
                        <ParagraphSection>
                            <template #title>Strengths</template>
                            <template #description>
                                {{ interviewReport?.strength }}
                            </template>
                        </ParagraphSection>
                    </template>
                    <template #second>
                        <ParagraphSection>
                            <template #title>Weaknesses</template>
                            <template #description>
                                {{ interviewReport?.weakness }}
                            </template>
                        </ParagraphSection>
                    </template>
                </SideBySide>
            </div>
        </template>
    </Card>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import SideBySide from "@/components/slots/SideBySide.vue";
import ParagraphSection from "@/components/slots/ParagraphSection.vue";
import SectionHeader from "@/components/slots/SectionHeader.vue";
import Chart from 'primevue/chart';
import Divider from "primevue/divider";
import Card from "primevue/card";
import Button from "primevue/button";
import type { ReportAI, RadarElement } from "@/stores/candidateStore";

const props = defineProps<{
    interviewReport: ReportAI | undefined,
    isModal: boolean
}>()

onMounted(() => {
    chartOptions.value = setChartOptions();
});

const HS_chartData = computed(() => {
    if (props.interviewReport)
        return setChartData(props.interviewReport.hard_skills_chart)
}
)
const SS_chartData = computed(() => {
    if (props.interviewReport)
        return setChartData(props.interviewReport.soft_skills_chart)
}
)
const chartOptions = ref();

const setChartData = (data: RadarElement[]) => {
    const labels = data.map((key) => key.label);
    const values = data.map((key) => key.score);
    const values2 = data.map((key) => key.score).reverse();
    return {
        labels: labels,
        datasets: [
            {
                label: 'Job requirement',
                borderColor: '#47BAFB',
                backgroundColor: 'rgba(71, 186, 251, 0.24)',
                pointBackgroundColor: '#47BAFB',
                pointHoverBackgroundColor: 'red',
                pointHoverBorderColor: 'red',
                data: values2
            },
            {
                label: 'Candidate stats',
                borderColor: '#9B51E0',
                backgroundColor: 'rgba(155, 81, 224, 0.24',
                pointBackgroundColor: '#9B51E0',
                pointHoverBackgroundColor: 'red',
                pointHoverBorderColor: 'red',
                data: values
            }
        ]
    };
};
const setChartOptions = () => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--p-text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--p-text-muted-color');

    return {
        plugins: {
            legend: {
                labels: {
                    font: {
                        size: 16
                    },
                    color: textColor
                }
            }
        },
        scales: {
            r: {
                grid: {
                    color: textColorSecondary
                },
                pointLabels: {
                    font: {
                        size: 14
                    }
                }
            }
        }
    };
}
</script>

<style scoped></style>