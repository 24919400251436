<template>
    <div class="flex w-full">
        <section class="w-2/3">
            <slot name="first"></slot>
        </section>
        <section class="w-1/3">
            <slot name="second"></slot>
        </section>
    </div>
</template>

<script setup lang="ts">

</script>

<style scoped></style>