<template>
    <div class="bg-[#040813] flex flex-col sm:flex-row justify-center myMenubar">
        <div class="max-w-[1440px] w-full flex flex-col sm:flex-row sm:items-center justify-between sm:mx-4 px-4 py-3 sm:py-0 sm:h-16 relative">
            <div class="flex items-center justify-between w-full sm:w-auto">
              <div class="flex items-center gap-1 select-none cursor-pointer" @click="navigate('/')">
                <img src="@/assets/img/haier-white.png" alt="logo" class="h-10 hidden sm:block" />
                <img src="@/assets/img/haier-icon-white.png" alt="logo" class="h-8 sm:hidden" />
                <span v-if="isDev" class="text-xs text-yellow-400 font-bold">DEV</span>
              </div>
              <div class="flex items-center gap-2 sm:hidden">
                <Avatar v-if="recruiterStore.isAuthenticated"
                    :image="recruiter.avatarUrl ? recruiter.avatarUrl : undefined"
                    :icon="recruiter.avatarUrl ? undefined : 'pi pi-user'"
                    size="normal" 
                    shape="circle"
                    class="hover:cursor-pointer !w-[45px] !h-[45px] navbar-avatar relative z-50 mr-2"
                    @click="toggleDropdown"
                />
                <button 
                  class="text-white p-2 hover:bg-[#151920] rounded-lg transition-colors"
                  @click="isMenuOpen = !isMenuOpen"
                >
                  <i :class="['pi text-xl', isMenuOpen ? 'pi-times' : 'pi-bars']"></i>
                </button>
              </div>
            </div>
            
            <div 
              :class="[
                'flex flex-col sm:flex-row items-center gap-4 select-none w-full sm:w-auto sm:justify-center sm:flex-1',
                isMenuOpen ? 'flex' : 'hidden sm:flex'
              ]"
            >
                <div class="flex flex-col sm:flex-row items-center gap-4 sm:mx-auto">
                    <Button v-for="nav in computedNavigations" 
                        :key="nav.path"
                        :disabled="nav.isDisabled" 
                        severity="contrast" 
                        rounded 
                        @click="navigate(nav.path)"
                        class="!hover:bg-[#151920] w-full sm:w-auto justify-center">
                        <template #default>
                            <span class="!text-white" :title="nav.isDisabled ? 'Coming soon!' : ''">{{ nav.label }}</span>
                        </template>
                    </Button>
                </div>
                <div class="flex items-center gap-1 w-full sm:w-auto justify-center">
                <template v-if="recruiterStore.isAuthenticated">
                    <Avatar
                        :image="recruiter.avatarUrl ? recruiter.avatarUrl : undefined"
                        :icon="recruiter.avatarUrl ? undefined : 'pi pi-user'"
                        size="normal" 
                        shape="circle"
                        class="!hidden sm:!inline-flex hover:cursor-pointer !w-[45px] !h-[45px] navbar-avatar relative z-50"
                        @click="toggleDropdown"
                    />
                    <Menu ref="dropdownMenu" :model="dropdownItems" :popup="true" class="profile-menu"
                          :pt="{
                            root: { class: 'sm:min-w-[320px] w-[calc(100vw-2rem)] sm:w-auto mx-4 sm:mx-0' },
                            content: { class: 'p-0 sm:p-2' }
                          }">
                        <template #start>
                            <button @click="handleProfileClick"
                                class="relative overflow-hidden w-full border-0 bg-transparent flex items-start justify-between p-2 pl-4 hover:bg-surface-100 dark:hover:bg-surface-800 rounded-none cursor-pointer transition-colors duration-200">
                                <div class="flex items-start">
                                    <Avatar
                                        :image="recruiter.avatarUrl ? recruiter.avatarUrl : undefined"
                                        :icon="recruiter.avatarUrl ? undefined : 'pi pi-user'"
                                        size="xlarge"
                                        class="mr-2 navbar-avatar"
                                        shape="circle"
                                    />
                                    <span class="inline-flex flex-col items-start">
                                        <span class="font-bold text-xl">{{ recruiter.nameFirst }} {{ recruiter.nameLast }}</span>
                                        <span class="text-sm text-gray-500">{{ company.name }}</span>
                                    </span>
                                </div>
                                <Button 
                                    icon="pi pi-times" 
                                    text 
                                    rounded
                                    aria-label="Close menu"
                                    @click.stop.prevent="closeMenu"
                                    class="!p-2 hover:bg-surface-100 sm:!hidden"
                                />
                            </button>

                            <Divider class="my-2" />
                            <div class="p-4 bg-blue-50/50">
                                <div class="flex items-center justify-between">
                                    <div class="flex items-center gap-2">
                                        <i class="pi pi-star-fill text-blue-600"></i>
                                        <span class="font-medium">Starter Plan</span>
                                    </div>
                                    <Button 
                                        label="Upgrade" 
                                        link 
                                        size="small"
                                        class="text-blue-600 !p-0"
                                        @click="handleUpgrade"
                                    />
                                </div>
                            </div>
                          <div class="p-4">
                                <div class="flex flex-col gap-4">
                                    <div class="flex items-center justify-between">
                                        <div class="flex items-center gap-2">
                                            <i class="pi pi-file-pdf text-blue-600"></i>
                                            <span class="text-sm">Resume Credits</span>
                                        </div>
                                        <span class="text-sm font-medium">{{ resumeCredits }}/{{ totalResumeCredits }}</span>
                                    </div>
                                    <div class="flex items-center justify-between">
                                        <div class="flex items-center gap-2">
                                            <i class="pi pi-video text-blue-600"></i>
                                            <span class="text-sm">Interview Credits</span>
                                        </div>
                                        <span class="text-sm font-medium">{{ interviewCredits }}/{{ totalInterviewCredits }}</span>
                                    </div>
                                    <Button 
                                        label="Purchase Credits" 
                                        icon="pi pi-plus-circle"
                                        iconPos="right"
                                        severity="primary"
                                        rounded
                                        @click="toggleCreditsDialog"
                                        class="w-full"
                                    />
                                </div>
                            </div>
                            <Divider class="my-2" />
                        </template>
                        <template #item="{ item, props }">
                            <a class="flex items-center justify-center" v-bind="props.action" @click="item.command">
                                {{ item.label }}
                            </a>
                        </template>
                    </Menu>
                </template>
                <template v-else>
                    <Button label="Login" severity="primary" rounded @click="navigate('/auth')" />
                </template>
                </div>
            </div>
        </div>
        <CreditsDialog v-model="showCreditsDialog" />
    </div>
</template>

<script setup lang="ts">
import Avatar from "primevue/avatar";
import Button from "primevue/button";
import Menu from "primevue/menu";
import { computed, ref, type Ref } from "vue";
import { useRouter } from 'vue-router';
import router from "@/router";
import { useRecruiterStore } from "@/stores/recruiterStore";
import { useWindowSize } from '@vueuse/core';
import { useJobStore } from "@/stores/jobStore";
import { useCandidateStore } from "@/stores/candidateStore";
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import { useToast } from 'primevue/usetoast';
import CreditsDialog from './CreditsDialog.vue';
import { useConfigStore } from "@/stores/configStore";

const configStore = useConfigStore();
const handleUpgrade = () => {
    dropdownMenu.value?.hide();
    
    // Check if pricing page is enabled using configStore
    if (configStore.switches.ENABLE_PRICING_PAGE) {
        r.push('/pricing');
    } else {
        toast.add({
            severity: 'info',
            summary: 'Coming Soon',
            detail: 'Pricing page will be available soon!',
            life: 3000
        });
    }
};

const candidateStore = useCandidateStore();
const toast = useToast();
const { width } = useWindowSize();
const isMenuOpen = ref(false);

// Credits state
const showCreditsDialog = ref(false);
const resumeCredits = ref(25);
const totalResumeCredits = ref(50);
const interviewCredits = ref(3);
const totalInterviewCredits = ref(5);

const toggleCreditsDialog = () => {
    showCreditsDialog.value = true;
    dropdownMenu.value?.hide();
};

// First, let's define interfaces for our navigation and dropdown items
interface NavigationItem {
    label: string;
    path: string;
    disabled?: () => boolean;
}

interface DropdownItem {
    label: string;
    command: () => void;
}

// Update the navigations computed definition
const navigations = computed<NavigationItem[]>(() => 
    router.options.routes
        .filter(route => {
            // First check if showInNavbar exists and evaluate it if it's a function
            const showInNavbar = route.meta?.showInNavbar;
            return showInNavbar instanceof Function ? showInNavbar() : showInNavbar;
        })
        .filter(route => route.meta?.navOptions) // Only include routes that have navOptions 
        .map(route => route.meta?.navOptions as NavigationItem)
);

const r = useRouter()
function navigate(path: string) {
    r.push(path);
    if (width.value < 640) {
        isMenuOpen.value = false;
    }
}

const recruiterStore = useRecruiterStore();
const recruiter = recruiterStore.recruiter;
const company = recruiterStore.recruiterCompany;
//TODO use recruiter to display user info

const dropdownMenu = ref()
function toggleDropdown(event: any) {
    dropdownMenu.value.toggle(event);
    isMenuOpen.value = false;
}

// Update the dropdownItems with the new Profile option and type
const dropdownItems: Ref<DropdownItem[]> = ref([
    {
        label: 'Profile',
        icon: 'pi pi-user',
        command: () => {
            // Assuming you have an emit for showProfile
            router.push('/profile');
        }
    },
    {
        label: 'Logout',
        icon: 'pi pi-sign-out',
        command: () => {
            logout();
        }
    }
]);

// Add emit definition
const emit = defineEmits<{
    (e: 'showProfile'): void;
}>();

const jobStore = useJobStore();

function logout() {
    recruiterStore.logout();
    jobStore.resetStore();
    candidateStore.resetStore();
}

/**
 * Determines if the current environment is development.
 * @returns {boolean} True if in development environment, false otherwise.
 */
const isDev: Ref<boolean> = ref(import.meta.env.VITE_APP_ENV === 'dev');

// Add a computed property to handle the disabled state for each navigation item
const computedNavigations = computed(() => {
    return navigations.value.map(nav => ({
        ...nav,
        isDisabled: nav.disabled ? nav.disabled() : false
    }));
});

/**
 * Handles clicks on the profile button by hiding the dropdown and navigating to profile page
 */
function handleProfileClick(): void {
    dropdownMenu.value?.hide();
    router.push('/profile');
}

/**
 * Closes both the dropdown menu and mobile menu
 */
function closeMenu(): void {
    if (dropdownMenu.value) {
        dropdownMenu.value.hide();
    }
    isMenuOpen.value = false;
}
</script>


<style scoped>
.p-button-text.p-button-secondary:not(:disabled):hover {
  background: #151920;
}

/* Fix menu positioning on mobile */
:deep(.p-menu) {
  position: fixed !important;
  top: auto !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  transform: none !important;
  margin: 0 !important;
  border-radius: 1rem 1rem 0 0;
  max-height: 85vh;
  overflow-y: auto;
}

@media (min-width: 640px) {
  :deep(.p-menu) {
    position: absolute !important;
    top: 100% !important;
    left: auto !important;
    right: 0 !important;
    bottom: auto !important;
    transform: translateY(0.5rem) !important;
    margin: 0 !important;
    border-radius: 0.5rem;
    max-height: none;
  }
}

/* Add mobile menu transition */
@media (max-width: 640px) {
  .myMenubar {
    min-height: 64px;
  }
  
  [class*="flex-col sm:flex-row"] {
    transition: all 0.3s ease-in-out;
  }
  
  /* Add backdrop for mobile menu */
  :deep(.p-menu-overlay) {
    background: rgba(0, 0, 0, 0.5);
  }
}
</style>

<style>
@media (min-width: 640px) {
    .profile-menu {
    min-width: 300px !important;
    }
}

</style>