<template>
	<form @submit.prevent="generateDescription" class="flex flex-col gap-8">
		<SectionHeader :is-sub-section="true" title-align="start">
			<template #title>
				Fill job Information
			</template>
		</SectionHeader>
		<div class="flex flex-col gap-6">
			<div class="flex flex-wrap gap-4">
				<div class="flex flex-col grow basis-2/4 gap-2">
					<label for="jobPos" class="my-paragraph-subtitle">Job position *</label>
					<InputText id="jobPos" type="text" v-model="jobDetails.jobPosition" required />
				</div>
				<div class="flex flex-col sm:basis-1/4 w-full gap-2 min-w-[250px]">
					<label for="lang" class="my-paragraph-subtitle">Language *</label>
					<Select id="lang" v-model="jobDetails.language" :options="languages" optionLabel="name" optionValue="code" placeholder="Select" required />
				</div>
			</div>
			<div class="flex flex-wrap gap-4 items-end">
				<div class="flex flex-col grow basis-2/4 gap-2">
					<label for="loc" class="my-paragraph-subtitle">Location *</label>
					<InputText id="loc" type="text" v-model="jobDetails.location" required />
				</div>
				<div class="flex flex-col sm:basis-1/4 w-full gap-2 min-w-[250px]">
					<Button 
						:label="isGenerating ? 'Generating...' : 'Generate job description'" 
						iconPos="right"
						:icon="isGenerating ? 'pi pi-spinner pi-spin' : ''"
						:disabled="isGenerating"
						rounded 
						type="submit" 
					/>
				</div>
			</div>
			<div class="flex flex-col grow gap-2">
				<label for="skill" class="my-paragraph-subtitle">Major skills</label>
				<Chips separator="," id="skill" type="text" v-model="jobDetails.skillsMajor"
					:addOnBlur="true"
					placeholder="Enter skills separated by comma ','" />
			</div>
			<div class="flex flex-col grow gap-2">
				<div class="flex flex-col gap-1">
					<label for="jobDesc" class="my-paragraph-subtitle">Job description</label>
					<div>
						<a href="https://www.markdownguide.org/basic-syntax/" target="_blank" class="text-gray-500 hover:text-gray-700">
							Markdown styling is supported <i class="pi pi-external-link text-xs"></i>
						</a>
					</div>
				</div>

				<template v-if="isEditingDescription">
					<Textarea id="jobDesc" class="w-full" rows="20" v-model="jobDetails.jobDescription" />
					<div class="flex w-full justify-around gap-14">
						<Button rounded class="basis-1/3 grow" label="Cancel" severity="secondary"
							@click="cancelEdit" />
						<Button rounded class="basis-1/3 grow" label="Preview" @click="saveDescription" />
					</div>
				</template>
				<template v-else>
					<Card>
						<template #content>
							<div class="markdown-container"
								v-html="parsedJobDescription ? parsedJobDescription : marked('_Generated by AI, edit to add more details._')">
							</div>
						</template>
					</Card>
					<Button label="Edit" icon="pi pi-pencil" icon-pos="right" severity="secondary" text rounded @click="editDescription" />
				</template>
			</div>
		</div>
	</form>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { marked } from 'marked';
import SectionHeader from './slots/SectionHeader.vue';
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import Button from 'primevue/button';
import Chips from 'primevue/chips';
import Textarea from 'primevue/textarea';
import Card from 'primevue/card';
import { generateJobDescription } from '@/api_client';
import type { JobDetailsForm } from '@/stores/jobStore';
import { useToast } from 'primevue/usetoast';

const toast = useToast();
const jobDetails = defineModel<JobDetailsForm>('jobDetails', { required: true });

// Job Description
const isEditingDescription = ref(true);
const backupJobDescription = ref('');
const isGenerating = ref<boolean>(false);

const parsedJobDescription = computed(() => {
	return jobDetails.value.jobDescription
		? marked(jobDetails.value.jobDescription)
		: '';
});

const isFormValid = ref<boolean>(false);

// Watch for changes in jobDetails to validate the form
watch(jobDetails.value, () => {
	isFormValid.value = !!(jobDetails.value.jobPosition && jobDetails.value.language && jobDetails.value.location);
});

/**
 * Generates the job description based on the current job details.
 */
const generateDescription = async (): Promise<void> => {
	if (isGenerating.value || !isFormValid.value) {
		let warningMessage = 'Please fill in all mandatory fields:';
		if (!jobDetails.value.jobPosition) {
			warningMessage += '\n- Job position';
		}
		if (!jobDetails.value.language) {
			warningMessage += '\n- Language';
		}
		if (!jobDetails.value.location) {
			warningMessage += '\n- Location';
		}
		toast.add({ severity: 'warn', summary: 'Warning', detail: warningMessage, life: 3000 });
		return;
	}
	isGenerating.value = true;
	try {
		jobDetails.value.skillsMajor = [];
		jobDetails.value.jobDescription = '';

		const { job_description, required_skills } = await generateJobDescription(jobDetails.value);
		
		jobDetails.value.skillsMajor = required_skills;
		backupJobDescription.value = job_description;
		jobDetails.value.jobDescription = job_description;
		toast.add({ severity: 'success', summary: 'Success', detail: 'Job description generated successfully', life: 3000 });
	} catch (error) {
		console.error('Error generating job description:', error);
		toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to generate job description', life: 3000 });
	} finally {
		isGenerating.value = false;
	}
};

const editDescription = () => {
	isEditingDescription.value = true;
};

const saveDescription = () => {
	isEditingDescription.value = false;
	backupJobDescription.value = jobDetails.value.jobDescription;
};

const cancelEdit = () => {
	isEditingDescription.value = false;
	jobDetails.value.jobDescription = backupJobDescription.value;
};

// Placeholders
const languages: Array<{ name: string; code: string }> = [
	// { name: 'Arabic', code: 'AR' },
	// { name: 'Chinese', code: 'ZH' },
	{ name: 'English', code: 'EN' },
	{ name: 'French', code: 'FR' },
	// { name: 'German', code: 'DE' },
	// { name: 'Korean', code: 'KO' },
	// { name: 'Japanese', code: 'JA' },
	// { name: 'Portuguese', code: 'PT' },
	// { name: 'Russian', code: 'RU' },
	// { name: 'Spanish', code: 'ES' },
	// { name: 'Vietnamese', code: 'VI' },
];
</script>