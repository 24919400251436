<template>
    <Dialog 
        :visible="modelValue"
        @update:visible="emit('update:modelValue', $event)"
        modal 
        header="Purchase Additional Credits"
        :style="{ width: '90vw', maxWidth: '600px' }"
    >
        <div class="flex flex-col gap-6">
            <!-- Resume Credits Package -->
            <div class="flex items-center justify-between p-4 border rounded-lg hover:border-blue-500 transition-colors cursor-pointer">
                <div class="flex items-center gap-4">
                    <div class="w-12 h-12 bg-blue-50 rounded-full flex items-center justify-center">
                        <i class="pi pi-file-pdf text-blue-600 text-xl"></i>
                    </div>
                    <div>
                        <h3 class="text-lg font-semibold">Resume Review Credits</h3>
                        <p class="text-sm text-gray-600">100 credits for $29</p>
                    </div>
                </div>
                <Button 
                    label="Purchase" 
                    severity="primary" 
                    rounded
                    @click="purchaseCredits('resume')"
                />
            </div>

            <!-- Interview Credits Package -->
            <div class="flex items-center justify-between p-4 border rounded-lg hover:border-blue-500 transition-colors cursor-pointer">
                <div class="flex items-center gap-4">
                    <div class="w-12 h-12 bg-blue-50 rounded-full flex items-center justify-center">
                        <i class="pi pi-video text-blue-600 text-xl"></i>
                    </div>
                    <div>
                        <h3 class="text-lg font-semibold">AI Interview Credits</h3>
                        <p class="text-sm text-gray-600">5 credits for $49</p>
                    </div>
                </div>
                <Button 
                    label="Purchase" 
                    severity="primary" 
                    rounded
                    @click="purchaseCredits('interview')"
                />
            </div>
        </div>
    </Dialog>
</template>

<script setup lang="ts">
import { useToast } from 'primevue/usetoast';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

// Define props with proper TypeScript type
const props = defineProps<{
    modelValue: boolean
}>();

// Define emits with proper TypeScript type
const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void
}>();

const toast = useToast();

const purchaseCredits = (type: 'resume' | 'interview') => {
    toast.add({
        severity: 'info',
        summary: 'Coming Soon',
        detail: 'Credit purchase functionality will be available soon!',
        life: 3000
    });
    emit('update:modelValue', false);
};
</script>