<template>
    <div class="flex flex-col items-center justify-center box-border max-w-full">
        <SubNavbar>
            <template #title>
                Add a new job
            </template>
        </SubNavbar>
        <Stepper :value="currentStep" linear
            class="max-w-[1440px] w-full pt-4 sm:pt-[50px] pb-[100px] xl:px-[108px] min-h-[90vh] flex flex-col items-center mobile-stepper">
            <Card class="w-full">
                <template #content>
                    <div class="flex justify-center">
                        <StepList class="xl:w-2/3 sm:flex-row flex-col">
                            <Step value="1">Job Detail</Step>
                            <Step value="2">Interview Questions</Step>
                            <Step v-if="configStore.switches.ENABLE_ADVANCED_JOB_OPTIONS" value="3">Advanced Options</Step>
                        </StepList>
                    </div>
                </template>
            </Card>
            <StepPanels class="w-full">
                <Card class="p-0 sm:p-8">
                    <template #content>
                        <StepPanel value="1">
                            <StepJobDetails v-model:jobDetails="newJobForm.jobDetails" />
                            <div class="flex pt-6 justify-end">
                                <Button rounded label="Next" icon="pi pi-arrow-right" iconPos="right" :disabled="!isJobDetailsFilled" @click="handleStepChange('2'), jobStore.setNewJobDetails(newJobForm.jobDetails)" />
                            </div>
                        </StepPanel>
                        <StepPanel value="2">
                            <StepInterviewQuestions v-model:interviewQuestions="newJobForm.interviewQuestions" />
                            <div class="flex pt-6 justify-between">
                                <Button rounded label="Back" severity="secondary" icon="pi pi-arrow-left"
                                    @click="handleStepChange('1')" />
                                <Button v-if="configStore.switches.ENABLE_ADVANCED_JOB_OPTIONS" rounded label="Next" icon="pi pi-arrow-right" iconPos="right" :disabled="!isInterviewQuestionsFilled"
                                    @click="handleStepChange('3'), jobStore.setNewInterviewQuestions(newJobForm.interviewQuestions)" />
                                <Button v-else rounded label="Create Post" iconPos="right" icon="pi pi-plus" :loading="isLoading" :disabled="isLoading" @click="handleCreateJob" />
                            </div>
                        </StepPanel>
                        <StepPanel v-if="configStore.switches.ENABLE_ADVANCED_JOB_OPTIONS" value="3">
                            <StepAdvancedOptions v-model:advancedOptions="newJobForm.advancedOptions" />
                            <div class="flex pt-6 justify-between">
                                <Button rounded label="Back" severity="secondary" icon="pi pi-arrow-left"
                                    @click="handleStepChange('2')" />
                                <Button rounded label="Create Post" iconPos="right" icon="pi pi-plus" :loading="isLoading" :disabled="isLoading" @click="handleCreateJob" />
                            </div>
                        </StepPanel>
                    </template>
                </Card>
            </StepPanels>
        </Stepper>
        <Dialog v-model:visible="showSuccessModal" modal :closable="false" class="max-w-2xl">
            <div class="flex flex-col items-center">
                <h1 class="text-2xl font-bold">Job Created Successfully</h1>
                <img src="@/assets/img/successCheck.svg" alt="Success Check" class="w-1/2" />   
                <ParagraphSection>
                    <template #smallText>
                        Your post for has been successfully created! You can copy the post link,
                        view it in the job listings or create another job.
                    </template>
                </ParagraphSection>
            </div>
            <template #footer>
                <div class="flex w-full justify-between">
                    <ButtonGroup>
                        <Button rounded iconPos="left" outlined severity="secondary" label="View Job Listings" icon="pi pi-list" @click="navigateToJobListings" />
                        <Button rounded iconPos="right" severity="secondary"  label="Copy Link" icon="pi pi-copy" @click="copyToClipboard" />
                    </ButtonGroup>
                    <Button rounded iconPos="right" label="Create Another Job" icon="pi pi-plus" @click="resetForm" />
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script setup lang="ts">

import SubNavbar from '@/components/slots/SubNavbar.vue';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Step from 'primevue/step';
import StepList from 'primevue/steplist';
import StepPanel from 'primevue/steppanel';
import StepPanels from 'primevue/steppanels';
import Stepper from 'primevue/stepper';
import Dialog from 'primevue/dialog';
import ButtonGroup from 'primevue/buttongroup';
import { ref, computed } from 'vue';
import type { NewJobForm, InterviewQuestionsForm } from '@/stores/jobStore';
import { JobDetailsForm } from '@/stores/jobStore';
import { useJobStore } from '@/stores/jobStore';
import { useToast } from 'primevue/usetoast';
import StepJobDetails from '@/components/StepJobDetails.vue';
import StepInterviewQuestions from '@/components/StepInterviewQuestions.vue';
import StepAdvancedOptions from '@/components/StepAdvancedOptions.vue';
import ParagraphSection from '@/components/slots/ParagraphSection.vue';
import { addQuestionToJob, createJob } from '@/api_client';
import { useRouter } from 'vue-router';
import { useConfigStore } from '@/stores/configStore';

const configStore = useConfigStore();
const CLIENT_URL = import.meta.env.VITE_CLIENT_URL;
const jobStore = useJobStore();
const toast = useToast();
const router = useRouter();

//default values
const showSuccessModal = ref<boolean>(false);
const isLoading = ref<boolean>(false);
const newJobForm = ref<NewJobForm>({
    jobDetails: new JobDetailsForm('', 'EN', '', [], ''),
    interviewQuestions: { questions: [] } as InterviewQuestionsForm,
    advancedOptions: {
        salary: [0, 500000],
        aiPreselectGreat: 65,
        aiPreselectLess: 87,
        jobStatus: 'open',
        inviteAi: true
     }
} as NewJobForm);
const currentStep = ref('1');
const createdJobId = ref<number | null>(null);
const createdJobLink = ref<string>("");

// Submit job to server
const handleCreateJob = async () => {
    isLoading.value = true;
    try {
        const response = await createJob(newJobForm.value)
        const jobId = response.id
        createdJobId.value = jobId;
        await Promise.all(newJobForm.value.interviewQuestions.questions.map((question, index) => 
            addQuestionToJob(jobId, question, index)
        ));
        showSuccessModal.value = true;
        createdJobLink.value = `${CLIENT_URL}/jobs/${jobId}`;
    } catch (error) {
        toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to create job', life: 3000 });
        console.error(error);
    } finally {
        isLoading.value = false;
    }
}

const copyToClipboard = () => {
    if (createdJobLink.value) {
        navigator.clipboard.writeText(createdJobLink.value);
        toast.add({ severity: 'info', summary: 'Copied to Clipboard', detail: 'Job link copied to clipboard', life: 3000 });
    }
}

// Checks
const isJobDetailsFilled = computed(() => {
    return Object.values(newJobForm.value.jobDetails).length > 4 && Object.values(newJobForm.value.jobDetails).every(value => {
        if (value === null || value === undefined || value === '') {
            return false;
        }
        if (Array.isArray(value)) {
            return value.length > 0;
        }
        return true;
    });
});

const isInterviewQuestionsFilled = computed(() => {
    return newJobForm.value.interviewQuestions.questions.length > 0;
});

const isAdvancedOptionsFilled = computed(() => {
    return Object.values(newJobForm.value.advancedOptions).length > 7 && Object.values(newJobForm.value.advancedOptions).every(value => {
        if (value === null || value === undefined || value === '') {
            return false;
        }
        return true;
    });
});


const navigateToJobListings = () => {
    router.push('/jobs');
    showSuccessModal.value = false;
}

const resetForm = () => {
    newJobForm.value = {
        jobDetails: {} as JobDetailsForm,
        interviewQuestions: { questions: [] } as InterviewQuestionsForm,
        advancedOptions: { salary: [0, 500000], aiPreselectGreat: 65, aiPreselectLess: 87 }
    } as NewJobForm;
    currentStep.value = '1';
    showSuccessModal.value = false;
}

const handleStepChange = (newStep: string) => {
    currentStep.value = newStep;
}
</script>

<style>
.p-stepper-separator {
    display: none;
}
</style>