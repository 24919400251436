<script setup lang="ts">
import { RouterView } from 'vue-router'
import { useRecruiterStore } from "@/stores/recruiterStore";
import { useConfigStore } from "@/stores/configStore";
import { computed, onMounted, ref, watch } from 'vue';
import MainNavBar from '@/components/MainNavBar.vue'
import { useJobStore } from './stores/jobStore';
import ProfileModal from '@/components/ProfileModal.vue';
import { cookieSettings, isEUUser } from '@/utils/cookieConsent';
import Toast from 'primevue/toast';
const jobStore = useJobStore()
const recruiterStore = useRecruiterStore();
const configStore = useConfigStore()

const autoShowProfileModal = computed(() => (recruiterStore.isAuthenticated &&  (!recruiterStore.isProfileComplete)))
const manualShowProfileModal = ref(false)
const showProfileModal = ref(false);

onMounted( async () => {
  // wait for recruiterStore and configStore initialization
  await Promise.all([
    recruiterStore.init(),
    configStore.fetchSwitches()
  ])

  jobStore.init()

})

// Watch for conditions that should open the modal
watch(
  () => recruiterStore.isReady && autoShowProfileModal.value,
  (shouldShow) => {
    if (shouldShow) {
      showProfileModal.value = true;
    }
  }
);

// Watch for manual trigger
watch(
  () => manualShowProfileModal.value,
  (manualShowValue) => {
    if (manualShowValue) {
      showProfileModal.value = true;
    } else {
      showProfileModal.value = autoShowProfileModal.value;
    }
  }
);

function savedCallback() {
  manualShowProfileModal.value = true;
}

const showCookieConsent = ref(false);
setTimeout(async () => {
  showCookieConsent.value = await isEUUser();
}, 1000)

</script>

<template>
  <MainNavBar v-if="$route.meta.requiresNavbar" @showProfile="manualShowProfileModal = true"/>
  <RouterView/>
  <ProfileModal v-if="showProfileModal"
   :visible="showProfileModal"
   :closeable="!autoShowProfileModal"
   @close="manualShowProfileModal = false"
   @saved="savedCallback" />

  <vue-cookie-comply
    :preferences="cookieSettings.cookiePreferences"
    @on-accept-all-cookies="cookieSettings.onAccept"
    @on-save-cookie-preferences="cookieSettings.onSaved"
    v-if="showCookieConsent"
    class="cookie-consent"
  />
  <Toast position="top-right" />
  </template>

<style scoped>
.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
</style>
