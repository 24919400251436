import { defineStore } from 'pinia'
import { fetchSwitches } from '@/api_client'

interface SwitchesMap {
  [key: string]: boolean
}

interface ConfigState {
  switches: SwitchesMap
  loading: boolean
  error: string | null
}

/**
 * Store for managing feature switches and configuration
 */
export const useConfigStore = defineStore('config', {
  state: (): ConfigState => ({
    switches: {},
    loading: false,
    error: null
  }),

  actions: {
    /**
     * Fetches feature switches from the API
     * @returns Promise that resolves when switches are loaded
     */
    async fetchSwitches(): Promise<void> {
      this.loading = true
      this.error = null
      
      try {
        this.switches = await fetchSwitches()
      } catch (err) {
        this.error = err instanceof Error ? err.message : 'Failed to fetch switches'
        console.error('Error fetching switches:', err)
      } finally {
        this.loading = false
      }
    }
  }
})
