<script setup lang="ts">
import DragIcon from '@/components/slots/DragIcon.vue';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import Divider from 'primevue/divider';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import Panel from 'primevue/panel';
import Select from 'primevue/select';
import { ref, watch } from 'vue';
import draggable from 'vuedraggable';
import type { QuestionMultipleChoice, QuestionTextAnswer, Recruiter, MultipleChoice } from '@/stores/jobStore';
import { useConfigStore } from '@/stores/configStore';
const configStore = useConfigStore()
const props = defineProps<{
    question: QuestionTextAnswer | QuestionMultipleChoice,
    recruiters: Recruiter[],
    index: number,
}>()
const emit = defineEmits(['update', 'delete'])

const localQuestion = ref<QuestionTextAnswer | QuestionMultipleChoice>({ ...props.question })

const isMultipleChoice = (): boolean => localQuestion.value.questionType.toLowerCase() === 'multiple_choice'

watch(localQuestion, (newValue) => {
  emit('update', newValue)
}, { deep: true })

watch(() => localQuestion.value.questionType, (newValue) => {
    if (newValue === 'text') {
        localQuestion.value = convertToQuestionTextAnswer(localQuestion.value)
    } else {
        localQuestion.value = convertToQuestionMultipleChoice(localQuestion.value)
    }
})

function instanceOfQuestionMultipleChoice(question: QuestionTextAnswer | QuestionMultipleChoice): question is QuestionMultipleChoice {
    return 'questionType' in question && question.questionType === 'multiple_choice'
}
function instanceOfQuestionTextAnswer(question: QuestionTextAnswer | QuestionMultipleChoice): question is QuestionTextAnswer {
    return 'questionType' in question && question.questionType === 'text'
}

const toggleEditQuestion = ref<boolean>(true)
const isConfirmDelete = ref(false);

function togglePanel() {
    toggleEditQuestion.value = !toggleEditQuestion.value
}

function onRootContainerClick() {
}

function handleAddOption() {
    if (instanceOfQuestionMultipleChoice(localQuestion.value)) {
        const maxId = Math.max(...(localQuestion.value as QuestionMultipleChoice).answer.map(a => a.id), 0);
        (localQuestion.value as QuestionMultipleChoice).answer.push({
            id: maxId + 1,
            choice: '',
        })
    }
}
function handleDeleteOption(index: number) {
    if (instanceOfQuestionMultipleChoice(localQuestion.value)) {
        (localQuestion.value as QuestionMultipleChoice).answer.splice(index, 1);
    }

}

function indexInAlphabet(index: number): string {
    return String.fromCharCode(65 + index).toUpperCase()
}

function handleDeleteClick() {
    isConfirmDelete.value = true;
}

function handleMouseLeave() {
    isConfirmDelete.value = false;
}

function handleConfirmDelete() {
    emit('delete', localQuestion.value.id);
    isConfirmDelete.value = false;
}

const questionTypes = [
    { display: 'Text answer', value: 'text' },
    { display: 'Multiple choice', value: 'multiple_choice' },
]

function convertToQuestionTextAnswer(question: QuestionTextAnswer | QuestionMultipleChoice): QuestionTextAnswer {
    return {
        id: question.id,
        question: question.question,
        questionType: 'text',
        questionFrom: question.questionFrom,
        coefficient: question.coefficient,
        answer: '',
    }
}
function convertToQuestionMultipleChoice(question: QuestionTextAnswer | QuestionMultipleChoice): QuestionMultipleChoice {
    return {
        id: question.id,
        question: question.question,
        questionType: 'multiple_choice',
        questionFrom: question.questionFrom,
        coefficient: question.coefficient,
        answer: [{id: 1, choice: ''}, {id: 2, choice: ''}],
        expectedAnswer: [] as MultipleChoice[],
    }
}
</script>

<template>
    <div class="max-w-full flex flex-row flex-wrap items-center justify-start gap-2" @click="onRootContainerClick">
        <DragIcon class="cursor-grab question-drag-handle" />
        <Panel class="flex-1 border-[1px] max-w-full text-sm my-no-flex" :collapsed="toggleEditQuestion">
            <template #header>
                <div class="flex flex-col justify-start gap-4 self-stretch cursor-pointer" @click="togglePanel">
                    <div class="self-stretch flex flex-row items-center justify-between py-1.5 px-0 text-neutral-400">
                        <div class="flex flex-row items-center justify-start">
                            <div class="">Question category: {{ localQuestion.questionFrom === 'HR' ? 'Behavioral' : localQuestion.questionFrom }}</div>
                        </div>
                        <div class="flex flex-row items-center justify-start gap-2">
                            <template v-if="configStore.switches.ENABLE_MCQ_QUESTIONS">
                                <template v-if="isMultipleChoice()">
                                    <p class="">Multiple choice</p>
                                    <i class="pi pi-circle-fill" />
                                </template>
                                <template v-else>
                                    <p class="">Text answer</p>
                                    <i class="pi pi-align-center" />
                                </template>
                            </template>
                            <!-- Add arrow icon to indicate collapsed status -->
                            <i :class="toggleEditQuestion ? 'pi pi-chevron-down' : 'pi pi-chevron-up'" />
                        </div>
                    </div>
                    <div class="flex flex-row items-center justify-start gap-2 text-center text-lg">
                        <span class="bg-blue-500 rounded-full p-2 size-8 flex justify-center items-center">
                            <p class="text-center text-white font-medium select-none">
                                {{ props.index+1 }}
                            </p>
                        </span>
                        <div
                            class="relative tracking-[-0.01em] leading-[28px] font-medium text-dark-background text-left">
                            {{ localQuestion.question }}
                        </div>
                    </div>
                </div>
            </template>
            <div class="flex-col gap-4 max-w-full text-base">
                <div class="flex flex-col gap-4 max-w-full">
                    <div class="self-stretch flex flex-col gap-1 max-w-full">
                        <label for="question" class="font-medium">
                            Question
                        </label>
                        <Textarea id="question" class="w-full" rows="3"
                            v-model="localQuestion.question" />
                    </div>
                    <div class="self-stretch flex flex-row gap-4 max-w-full">
                        <div class="flex-1 flex flex-col gap-1 max-w-full">
                            <label for="questionFrom" class="font-medium">
                                Question category
                            </label>
                            <Select id="questionFrom" v-model="localQuestion.questionFrom"
                                :options="['HR', 'Technical']" :optionLabel="(option) => option === 'HR' ? 'Behavioral' : option" />
                        </div>
                        <div v-if="configStore.switches.ENABLE_MCQ_QUESTIONS" class="flex-1 flex flex-col gap-1 max-w-full">
                            <label for="questionType" class="font-medium">
                                Question type
                            </label>
                            <Select id="questionType" v-model="localQuestion.questionType"
                                :options="questionTypes" option-label="display" option-value="value" />
                        </div>
                    </div>
                    <div v-if="isMultipleChoice()"
                        class="self-stretch flex flex-col gap-2 max-w-full text-center text-sm">

                        <draggable v-model="localQuestion.answer as MultipleChoice[]" item-key="id"
                            handle=".drag-handle"
                            class="self-stretch flex flex-col gap-2 max-w-full text-center text-sm">
                            <template #item="{ element, index }">
                                <div class="flex flex-row items-center justify-start gap-1.5 max-w-full">
                                    <DragIcon class="cursor-grab drag-handle" />
                                    <div class="self-stretch flex flex-row items-center justify-start gap-2">
                                        <span
                                            class="bg-neutral-800 rounded-md p-2 size-6 flex justify-center items-center select-none">
                                            <p class="text-center text-white font-medium">
                                                {{ indexInAlphabet(index) }}
                                            </p>
                                        </span>
                                        <InputText class="basis-2/5" placeholder="Option" v-model="element.choice" />
                                    </div>
                                    <i class="pi pi-trash hover:text-red-500 active:text-red-700"
                                        @click="handleDeleteOption(index)" />
                                </div>
                            </template>
                        </draggable>

                        <div
                            class="w-96 rounded-81xl flex flex-row items-center justify-center py-2.5 px-4 box-border gap-[7px] max-w-full text-left text-base text-dark-background">
                            <Button label="Add more answers" icon="pi pi-plus" iconPos="right" severity="secondary" rounded text="" outlined @click="handleAddOption" />
                        </div>

                    </div>
                </div>
                <Divider />
                <div class="self-stretch flex flex-row items-center justify-start gap-4 max-w-full">
                    <div class="sm:basis-1/6 flex flex-col gap-2 max-w-full">
                        <label for="coefficient" class="font-medium">
                            Coefficient
                        </label>
                        <InputText id="coefficient" class="w-full" type="number"
                            v-model="localQuestion.coefficient" />
                    </div>
                    <div v-if="isMultipleChoice()"
                        class="basis-2/6 flex flex-col gap-2 max-w-full">
                        <label for="expectedAnswer" class="font-medium">
                            Expected response
                        </label>
                        <MultiSelect
                            id="expectedAnswer" 
                            class="w-full" 
                            :options="localQuestion.answer" 
                            option-label="choice" 
                            option-value="id" 
                            filter 
                            display="chip"
                            v-model="(localQuestion as QuestionMultipleChoice).expectedAnswer"
                        />
                    </div>
                    <div class="grow flex justify-end items-end self-stretch">
                        <Button 
                            :icon="isConfirmDelete ? 'pi pi-question' : 'pi pi-trash'" 
                            :label="isConfirmDelete ? 'Delete this question' : ''"
                            iconPos="right" 
                            severity="danger" 
                            rounded 
                            :outlined="!isConfirmDelete"
                            @click="isConfirmDelete ? handleConfirmDelete() : handleDeleteClick()"
                            @mouseleave="handleMouseLeave"
                        />
                    </div>
                </div>
            </div>
        </Panel>
    </div>
</template>
<style>
.my-no-flex .p-panel-header {
    display: block !important;
}
</style>