<template>
    <DataTable :value="jobStore.loading?loadingData:jobsFiltered" sortMode="multiple" removableSort tableStyle="align-self: stretch;"
        selectionMode="single" @rowSelect="onRowSelect" class="min-w-[768px] bg-white rounded-2xl border border-[#f2f4f7]"
        :rows="rows"
        :first="first"
        :paginator="true"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 20, 50]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} jobs"
        responsiveLayout="scroll"
        @page="onPage"
        scrollable
        scrollHeight="flex"
    >
        <Column field="title" header="Title" sortable>
            <template #body="slotProps">
                <Skeleton v-if="jobStore.loading"/>
                <template v-else>{{ slotProps.data.title }}</template>
            </template>
        </Column>
        <Column field="status" header="Status" sortable>
            <template #body="slotProps">
                <Skeleton v-if="jobStore.loading"/>
                <Tag v-else 
                    rounded 
                    :value="formatStatus(slotProps.data.status)"
                    :style="getStatusStyle(slotProps.data.status)"
                    :label="formatStatus(slotProps.data.status)" 
                />
            </template>
        </Column>
        <Column field="created" header="Created" sortable>
            <template #body="slotProps">
                <Skeleton v-if="jobStore.loading"/>
                <template v-else>
                    <span v-tooltip.top="new Date(slotProps.data.published_at).toLocaleTimeString()">
                        {{ formatDate(slotProps.data.published_at) }}
                    </span>
                </template>
            </template>
        </Column>
        <Column field="views" sortable>
            <template #header>
                <i class="pi pi-eye" v-tooltip.top="'View count'" />
            </template>
            <template #body="slotProps">
                <Skeleton v-if="jobStore.loading"/>
                <template v-else>{{ slotProps.data.views }}</template>
            </template>
        </Column>
        <Column field="candidates" sortable>
            <template #header>
                <i class="pi pi-users" v-tooltip.top="'Candidates count'" />
            </template>
            <template #body="slotProps">
                <Skeleton v-if="jobStore.loading"/>
                <template v-else>{{ slotProps.data.candidates }}</template>
            </template>
        </Column>
        <Column header="Actions">
            <template #body="slotProps">
                <Skeleton v-if="jobStore.loading"/>
                <template v-else class="flex gap-2">
                    <div class="flex gap-1">
                        <Button type="button" icon="pi pi-eye" rounded text severity="secondary" 
                            v-tooltip.top="'View'"
                            @click="viewJob(slotProps.data.id)" />
                        <Button type="button" icon="pi pi-pencil" rounded text severity="secondary"
                            v-tooltip.top="'Edit (Coming Soon)'"
                            disabled />
                        <Button type="button" icon="pi pi-trash" rounded text severity="danger"
                            v-tooltip.top="'Delete Job'"
                            @click.stop="confirmDelete(slotProps.data)" />
                        <Button type="button" 
                            :icon="slotProps.data.status.toLowerCase() === 'closed' ? 'pi pi-refresh' : 'pi pi-times'" 
                            rounded 
                            text 
                            :severity="slotProps.data.status.toLowerCase() === 'closed' ? 'success' : 'warning'"
                            v-tooltip.top="slotProps.data.status.toLowerCase() === 'closed' ? 'Reopen Job' : 'Close Job'"
                            @click.stop="slotProps.data.status.toLowerCase() === 'closed' ? confirmReopen(slotProps.data) : confirmClose(slotProps.data)" />
                        <Button type="button" icon="pi pi-copy" rounded text severity="secondary"
                            v-tooltip.top="'Copy Link'"
                            @click="copyToClipboard(getJobUrl(slotProps.data.id))" />
                    </div>
                </template>
            </template>
        </Column>

    </DataTable>

    <Dialog v-model:visible="deleteDialog" modal header="Delete Job" :style="{ width: '450px' }">
        <p class="m-0">Are you sure you want to delete "{{ selectedJob?.title }}"?</p>
        <template #footer>
            <Button label="No" icon="pi pi-times" @click="deleteDialog = false" text />
            <Button label="Yes" icon="pi pi-check" @click="handleDelete" severity="danger" autofocus />
        </template>
    </Dialog>

    <Dialog v-model:visible="closeDialog" modal header="Close Job" :style="{ width: '450px' }">
        <p class="m-0">Are you sure you want to close "{{ selectedJob?.title }}"?</p>
        <template #footer>
            <Button label="No" icon="pi pi-times" @click="closeDialog = false" text />
            <Button label="Yes" icon="pi pi-check" @click="handleClose" severity="warning" autofocus />
        </template>
    </Dialog>

    <Dialog v-model:visible="reopenDialog" modal header="Reopen Job" :style="{ width: '450px' }">
        <p class="m-0">Are you sure you want to reopen "{{ selectedJob?.title }}"?</p>
        <template #footer>
            <Button label="No" icon="pi pi-times" @click="reopenDialog = false" text />
            <Button label="Yes" icon="pi pi-check" @click="handleReopen" severity="success" autofocus />
        </template>
    </Dialog>

</template>

<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Tag from 'primevue/tag';
import Skeleton from 'primevue/skeleton';
import dayjs from 'dayjs';
import type { Job } from '@/stores/jobStore';
import { useJobStore } from "@/stores/jobStore";
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router';
import Dialog from 'primevue/dialog';
import { getStatusStyle } from '@/utils/style';

const toast = useToast();
const jobStore = useJobStore()
const router = useRouter();

const jobs = computed<Job[]>(() => jobStore.jobs)
const loadingData = Array<Job>(5).fill({} as Job);

const rows = ref<number>(10);
const first = ref<number>(0);

const onRowSelect = (event: any) => {
    router.push({
        name: 'candidates',
        query: { job: event.data.id }
    });
}

interface Props {
    /**
     * Whether to show closed jobs in the table
     */
    showClosed: boolean
}

const props = defineProps<Props>()

const jobsFiltered = computed(
    () => {
        return props.showClosed ?
            jobs.value :
            jobs.value.filter(job => job.status.toLowerCase() !== "closed")
    }
)

function formatStatus(status: string): string {
    const statusMap: { [key: string]: string } = {
        'closed': 'Closed',
        'open': 'Open'
    };
    return statusMap[status] || status;
}

const copyToClipboard = async (url: string) => {
    try {
        await navigator.clipboard.writeText(url);
        toast.add({ severity: 'success', summary: 'Copied', detail: 'Link copied to clipboard', life: 3000 });
    } catch (err) {
        console.error('Failed to copy: ', err);
        toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to copy link', life: 3000 });
    }
}

const getJobUrl = (jobId: string): string => {
    return `${location.origin}/jobs/${jobId}`;
};

const formatDate = (date: Date): string => {
    return dayjs(date).format('MMM D, YYYY');
};


const viewJob = (jobId: string) => {
    router.push(`/jobs/${jobId}`);
};

const editJob = (jobId: string) => {
    router.push(`/jobs/${jobId}/edit`);
};

/**
 * Shows a confirmation dialog before deleting a job
 * @param job - The job to be deleted
 */
const confirmDelete = (job: Job) => {
    selectedJob.value = job;
    deleteDialog.value = true;
};

/**
 * Shows a confirmation dialog before closing a job
 * @param job - The job to be closed
 */
const confirmClose = (job: Job) => {
    if (job.status === 'Closed') {
        toast.add({ severity: 'info', summary: 'Info', detail: 'Job is already closed', life: 3000 });
        return;
    }
    selectedJob.value = job;
    closeDialog.value = true;
};

/**
 * Reloads job data when the component is mounted.
 */
onMounted(async () => {
    await jobStore.init(); 
});

const onPage = (event: PaginatorState) => {
    first.value = event.first;
    rows.value = event.rows;
};

const deleteDialog = ref(false);
const closeDialog = ref(false);
const selectedJob = ref<Job | null>(null);

const handleDelete = async () => {
    try {
        await jobStore.deleteJobById(selectedJob.value!.id);
        toast.add({ severity: 'success', summary: 'Success', detail: 'Job deleted successfully', life: 3000 });
    } catch (error) {
        toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to delete job', life: 3000 });
    }
    deleteDialog.value = false;
};

const handleClose = async () => {
    try {
        await jobStore.closeJobById(selectedJob.value!.id);
        toast.add({ severity: 'success', summary: 'Success', detail: 'Job closed successfully', life: 3000 });
    } catch (error) {
        toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to close job', life: 3000 });
    }
    closeDialog.value = false;
};

const reopenDialog = ref(false);

const confirmReopen = (job: Job) => {
    selectedJob.value = job;
    reopenDialog.value = true;
};

const handleReopen = async () => {
    try {
        await jobStore.reopenJobById(selectedJob.value!.id);
        toast.add({ severity: 'success', summary: 'Success', detail: 'Job reopened successfully', life: 3000 });
    } catch (error) {
        toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to reopen job', life: 3000 });
    }
    reopenDialog.value = false;
};
</script>

interface PaginatorState {
    first: number;
    rows: number;
    page: number;
    pageCount: number;
}

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@400;500&display=swap');

:deep(.p-datatable-wrapper) {
    border-radius: 1rem;
    position: relative;
}

:deep(.p-datatable-thead) th {
    background-color: #f2f4f7;
    color: #636770;
}

:deep(.p-datatable-tbody) tr {
    cursor: pointer;
}

:deep(.p-datatable-tbody) tr:hover {
    background-color: #f2f4f7;
}

/* Add mobile responsiveness */
.data-table-wrapper {
    width: 100%;
    position: relative;
    margin: 0 auto;
}

/* Mobile styles */
@media (max-width: 768px) {
    :deep(.p-datatable) {
        font-size: 0.875rem;
    }

    :deep(.p-datatable-thead) th {
        padding: 0.75rem 0.5rem;
    }

    :deep(.p-datatable-tbody) td {
        padding: 0.75rem 0.5rem;
    }
}

</style>
