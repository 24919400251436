<template>
    <div v-if="details" class="candidate-details rounded-2xl border border-[#f2f4f7]">
        <div class="flex flex-col md:flex-row items-start md:items-center justify-between px-4 sm:px-6 pt-6">
            <div class="flex flex-row items-center justify-start max-w-full md:max-w-[60%]">
                <Avatar icon="pi pi-user" class="mr-4 flex-shrink-0" size="xlarge" shape="circle" />
                <div class="flex flex-col items-start justify-start min-w-0">
                    <div class="text-container w-full" 
                         :class="{'animate-text': isTextLong(details?.fullname)}">
                        <h2 class="text-[#040813] text-xl font-medium font-['Sora'] name-text"
                            v-tooltip.top="details?.fullname">
                            {{ details?.fullname || 'N/A' }}
                        </h2>
                    </div>
                    <div class="text-container w-full" 
                         :class="{'animate-text': isTextLong(applicationDetails?.current_position)}">
                        <p v-if="applicationDetails?.current_position || applicationDetails?.total_experience" 
                           class="text-[#636770] text-sm font-normal font-['Sora'] position-text"
                           v-tooltip.top="applicationDetails.current_position">
                            {{ applicationDetails.current_position }}
                            <span v-if="applicationDetails.current_position && applicationDetails.total_experience"> • </span>
                            <span v-if="applicationDetails.total_experience">{{ applicationDetails.total_experience }} years experience</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="flex flex-col w-full md:w-auto mt-4 md:mt-0">
                <div class="flex flex-col sm:flex-row gap-[8px] w-full">
                    <div class="w-full sm:w-auto">
                        <ButtonGroup :title="configStore.switches.ENABLE_SHARE_CANDIDATE_BUTTON ? '' : 'Coming soon!'" 
                                    class="w-full flex">
                            <Button :disabled="!configStore.switches.ENABLE_SHARE_CANDIDATE_BUTTON" 
                                    outlined 
                                    severity="secondary" 
                                    class="h-10 flex-1" 
                                    rounded 
                                    label="Share"/>
                            <Button :disabled="!configStore.switches.ENABLE_SHARE_CANDIDATE_BUTTON" 
                                    outlined 
                                    severity="secondary" 
                                    class="h-10" 
                                    rounded 
                                    icon="pi pi-share-alt"/>
                        </ButtonGroup>
                    </div>
                    <Button label="Download Resume" 
                            outlined
                            severity="primary"
                            rounded
                            class="h-10 w-full sm:w-auto"
                            @click="downloadResume(applicationDetails.id, details?.fullname)" />
                </div>
                
                <!-- Status select below buttons on mobile -->
                <Select
                    v-if="candidate && isMobile"
                    v-model="candidate.status"
                    :options="statusOptions"
                    optionLabel="label"
                    optionValue="value"
                    @update:modelValue="handleStatusChange"
                    class="w-full mt-2"
                    :class="{'p-invalid': !candidate.status}"
                />
            </div>
        </div>

        <Divider class="my-4" />

        <div class="flex flex-col gap-6 px-4 sm:px-6 pb-6">
            <!-- Contact Information Section -->
            <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div class="w-full">
                    <h3 class="text-[#636770] text-sm font-normal font-['Sora'] mb-3">Contact Information</h3>
                    <div class="flex flex-col gap-1.5">
                        <p class="text-[#040813] text-sm flex items-center justify-between">
                            Email: {{ details?.contact?.email || 'N/A' }}
                            <i v-if="details?.contact?.email" 
                               @click="copyToClipboard(details.contact.email)"
                               class="pi pi-copy cursor-pointer text-gray-400 hover:text-gray-600"></i>
                        </p>
                        <p class="text-[#040813] text-sm flex items-center justify-between">
                            Location: {{ details?.contact?.location || 'N/A' }}
                            <i v-if="details?.contact?.location"
                               @click="copyToClipboard(details.contact.location)"
                               class="pi pi-copy cursor-pointer text-gray-400 hover:text-gray-600"></i>
                        </p>
                        <p class="text-[#040813] text-sm flex items-center justify-between">
                            Phone: {{ details?.contact?.phone || 'N/A' }}
                            <i v-if="details?.contact?.phone"
                               @click="copyToClipboard(details.contact.phone)"
                               class="pi pi-copy cursor-pointer text-gray-400 hover:text-gray-600"></i>
                        </p>
                    </div>
                </div>

                <!-- Enhanced Compact Links Section -->
                <div class="w-full" v-if="applicationDetails?.links?.length">
                    <div class="flex items-center justify-between mb-3">
                        <h3 class="text-[#636770] text-sm font-normal font-['Sora']">Online Presence</h3>
                        <button @click="isLinksExpanded = !isLinksExpanded"
                                class="text-xs text-blue-600 hover:text-blue-700 flex items-center gap-1">
                            {{ isLinksExpanded ? 'Collapse' : 'View All' }}
                            <i :class="['pi text-xs transition-transform duration-200',
                                      isLinksExpanded ? 'pi-chevron-up' : 'pi-chevron-down']"></i>
                        </button>
                    </div>
                    
                    <div class="bg-white rounded-lg border border-gray-100 overflow-hidden">
                        <!-- Always visible links (first 2) -->
                        <div class="divide-y divide-gray-50">
                            <template v-for="(link, index) in formattedLinks" :key="link.url">
                                <div v-if="index < 2 || isLinksExpanded">
                                    <a :href="link.url" 
                                       target="_blank" 
                                       rel="noopener noreferrer" 
                                       class="flex items-center gap-2 p-2.5 hover:bg-gray-50/50 transition-colors">
                                     <div class="w-6 h-6 flex items-center justify-center 
                                                rounded bg-gray-50/80">
                                        <i :class="[getLinkIcon(link.url), 'text-gray-500']"></i>
                                    </div>
                                    <span class="flex-1 text-sm text-gray-600 hover:text-blue-600 font-medium truncate">
                                        {{ link.name }}
                                    </span>
                                    <i class="pi pi-external-link text-[10px] text-gray-400 "></i>
                                    </a>
                                </div>
                            </template>
                        </div>
                        
                        <!-- Collapsed state hint -->
                        <div v-if="!isLinksExpanded && formattedLinks.length > 2"
                             @click="isLinksExpanded = true"
                             class="text-xs text-gray-400 text-center py-1.5 bg-gray-50/30 border-t 
                                    border-gray-100 cursor-pointer hover:bg-gray-100 transition-colors">
                            +{{ formattedLinks.length - 2 }} more links
                        </div>
                    </div>
                </div>
            </div>

            <!-- Experience Section -->
            <div class="w-full">
                <h3 class="text-[#636770] text-sm font-normal font-['Sora'] mb-3">Experience</h3>
                <div class="flex flex-col gap-4">
                    <template v-if="applicationDetails?.experiences?.length">
                        <div v-for="(exp, index) in applicationDetails.experiences" :key="index" 
                             class="flex flex-col gap-1">
                            <p class="text-[#040813] text-sm font-semibold">{{ exp.company }} - {{ exp.position }}</p>
                            <p class="text-[#636770] text-sm">{{ exp.duration }}</p>
                            <p class="text-[#040813] text-sm whitespace-pre-line">{{ exp.description }}</p>
                        </div>
                    </template>
                    <div v-else class="text-[#040813] text-sm font-normal font-['Sora']">N/A</div>
                </div>
            </div>

            <!-- Skills, Education, and Languages Section -->
            <div class="grid grid-cols-1  gap-6">
                
                <!-- Education Section -->
                <div class="flex flex-col gap-3">
                    <h3 class="text-[#636770] text-sm font-normal font-['Sora'] mb-3">Education</h3>
                    <div class="grid grid-cols-1 gap-3">
                        <div v-for="(edu, index) in details?.education" 
                             :key="index" 
                             class="flex flex-col gap-1 p-3 bg-gray-50 rounded-lg">
                            <div class="flex items-center justify-between gap-2">
                                <div class="flex items-center gap-2">
                                    <i class="pi pi-book text-blue-600"></i>
                                    <span class="font-medium text-gray-900">{{ edu.institution }}</span>
                                </div>
                                <span v-if="edu.graduation_year" 
                                      class="text-xs text-gray-700 whitespace-nowrap">
                                    Class of {{ edu.graduation_year }}
                                </span>
                            </div>
                            <div class="text-sm text-gray-500 pl-6">
                                {{ edu.field_of_study }}
                                <br/>{{ edu.degree }}
                            </div>
                        </div>
                        <div v-if="!details?.education?.length" 
                             class="text-sm text-gray-500 italic">
                            No education information available
                        </div>
                    </div>
                </div>

                <!-- Skills Section -->
                <div class="w-full">
                    <div class="flex flex-col gap-3">
                        <div class="flex justify-between items-center">
                            <h3 class="text-[#636770] text-sm font-normal font-['Sora'] mb-3">Skills</h3>
                            <div v-if="applicationDetails?.matching_skills?.length"
                                 class="flex items-center gap-1.5 px-3 py-1.5 bg-blue-50 rounded-full">
                                <i class="pi pi-star text-xs text-blue-600"></i>
                                <span class="text-sm text-gray-700">{{ applicationDetails.matching_skills.length }} Highly relevant skills</span>
                            </div>
                        </div>
                        <div class="flex flex-wrap gap-2">
                            <template v-if="applicationDetails?.skills?.length">
                                <div v-for="skill in applicationDetails.skills" 
                                     :key="skill.skill_name"
                                     class="flex items-center gap-1.5 px-3 py-1.5 bg-blue-50 rounded-full"
                                     v-tooltip="{
                                         value: getSkillTooltipContent(skill),
                                         position: 'top',
                                         escape: false
                                     }">
                                    <i :class="[
                                        isMatchingSkill(skill.skill_name) ? 'pi pi-star text-blue-600' : 'pi pi-check text-blue-600',
                                        'text-xs'
                                    ]"></i>
                                    <span class="text-sm text-gray-700">{{ skill.skill_name }}</span>
                                    <span v-if="skill.score" class="text-xs text-gray-400"> {{ skill.score }}%</span>
                                    <i v-if="skill.score || skill.score_reason || skill.year_of_experience" 
                                       class="pi pi-info-circle text-xs text-blue-600"></i>
                                </div>
                                <div v-for="skill in matchingSkillsNotInSkills" 
                                     :key="skill"
                                     class="flex items-center gap-1.5 px-3 py-1.5 bg-blue-50 rounded-full">
                                    <i class="pi pi-star text-xs text-blue-600"></i>
                                    <span class="text-sm text-gray-700">{{ skill }}</span>
                                </div>
                            </template>
                            <template v-else>
                                <div class="text-sm text-gray-500">
                                    {{ details?.skills || 'No skills listed' }}
                                </div>
                            </template>
                        </div>
                    </div>
                </div>

                <!-- Languages -->
                <div class="w-full">
                    <div class="self-stretch justify-start items-start gap-8 inline-flex">
                        <div class="grow shrink basis-0 flex-col justify-center items-start gap-3 inline-flex">
                            <h3 class="text-[#636770] text-sm font-normal font-['Sora'] mb-3">Languages</h3>

                            <div class="self-stretch flex flex-wrap gap-2">
                                <template v-if="details?.languages?.length">
                                    <Tag v-for="lang in details.languages" :key="lang.name"
                                        :value="formatLanguage(lang)" severity="info"
                                        class="bg-[#f2f4f7] text-[#040813] border-none px-3 py-1 rounded-full text-sm font-['Sora']" />
                                </template>
                                <span v-else class="text-[#040813] text-sm">N/A</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="flex items-center justify-center p-4">
        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
    </div>
</template>

<script setup lang="ts">
import Avatar from 'primevue/avatar';
import Divider from 'primevue/divider';
import Button from 'primevue/button';
import ButtonGroup from 'primevue/buttongroup';
import Select from 'primevue/select';
import Tag from 'primevue/tag'; // Import Tag component
import type { CandidateDetails, ApplicationDetails, Candidate, ApplicationStatus } from '@/stores/candidateStore';
import { formatDate_LL } from '@/dayjsFormats'
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import { downloadResume } from '@/api_client';
import { useToast } from 'primevue/usetoast'
import { useConfigStore } from '@/stores/configStore'
import { useJobStore } from '@/stores/jobStore'
import { useCandidateStore } from '@/stores/candidateStore';

const configStore = useConfigStore()
const toast = useToast()
const candidateStore = useCandidateStore();

const formatLanguage = (lang: { name: string; proficiency?: string }) => {
    const name = lang.name.charAt(0).toUpperCase() + lang.name.slice(1).toLowerCase();
    return lang.proficiency ? `${name} (${lang.proficiency})` : name;
};

const props = defineProps<{
    details?: CandidateDetails | null
    applicationDetails: ApplicationDetails
    jobId?: number
    candidate?: Candidate | null  // Add this new prop
}>()

const jobStore = useJobStore()

const requiredSkills = computed(async () => {
    if (!props.jobId) return [];
    const job = await jobStore.getJobById(props.jobId);
    return job?.skills || [];
});

const collapsedGroups = ref(new Set<string>())

const getLinkIcon = (url: string): string => {
    if (url.includes('linkedin.com')) return 'pi pi-linkedin';
    if (url.includes('github.com')) return 'pi pi-github';
    if (url.includes('twitter.com')) return 'pi pi-twitter';
    if (url.includes('gitlab.com')) return 'pi pi-gitlab';
    if (url.includes('behance.net')) return 'pi pi-behance';
    if (url.includes('dribbble.com')) return 'pi pi-dribbble';
    return 'pi pi-link';
}

const isLinksExpanded = ref(false)

const shouldAnimate = (text?: string) => {
    if (!text) return false;
    return text.length > 15;
};

// Add these from CandidateDrawer
const statusOptions = [
    { label: 'New Applicant', value: 'newapplicant' },
    { label: 'AI Selected', value: 'aiselected' },
    { label: 'AI Rejected', value: 'airejected' },
    { label: 'Interview', value: 'interview' },
    { label: 'Selected', value: 'selected' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Offer', value: 'offer' },
    { label: 'No Decision', value: 'no_decision' }
] as const;

// Add this from CandidateDrawer
const handleStatusChange = async (newStatus: string) => {
    if (!props.candidate?.id) return;
    
    try {
        await candidateStore.updateCandidateStatus(props.candidate.id, newStatus as ApplicationStatus);
        
        toast.add({
            severity: 'success',
            summary: 'Status Updated',
            detail: `Candidate status has been changed to ${statusOptions.find(opt => opt.value === newStatus)?.label}`,
            life: 3000,
        });
    } catch (error) {
        console.error('Failed to update status:', error);
        
        toast.add({
            severity: 'error',
            summary: 'Update Failed',
            detail: 'Failed to update candidate status. Please try again.',
            life: 3000,
        });
    }
};

const isMobile = ref(false)

onMounted(() => {
    checkScreenSize()
    window.addEventListener('resize', checkScreenSize)
})

onBeforeUnmount(() => {
    window.removeEventListener('resize', checkScreenSize)
})

const checkScreenSize = () => {
    isMobile.value = window.innerWidth <= 768
}

// Add this new function
const isTextLong = (text?: string) => {
    if (!text) return false;
    return text.length > 15;
};

const copyToClipboard = async (text: string) => {
    try {
        await navigator.clipboard.writeText(text);
        toast.add({
            severity: 'success',
            summary: 'Copied',
            detail: `Copied to clipboard (${text})`,
            life: 2000,
        });
    } catch (err) {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to copy text',
            life: 2000,
        });
    }
};

/**
 * Formats a URL by ensuring it has a proper protocol prefix
 * @param {string} url - The URL or email to format
 * @returns {string} - The formatted URL with appropriate protocol
 */
const formatUrl = (url: string): string => {
    if (!url) return '';
    
    // Handle email addresses
    if (url.includes('@')) {
        return url.startsWith('mailto:') ? url : `mailto:${url}`;
    }
    
    // Handle regular URLs
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
        return `https://${url}`;
    }
    
    return url;
};

/**
 * Returns formatted links with proper protocols
 * @returns {Array<{url: string, name: string}>}
 */
const formattedLinks = computed(() => {
    return props.applicationDetails?.links?.map(link => ({
        ...link,
        url: formatUrl(link.url)
    })) || [];
});

// TODO rework this entire skills and matching_skills code to be more consistent and dev friendly
const isMatchingSkill = (skillName: string): boolean => {
    const matchingSkillsString = props.applicationDetails?.matching_skills?.map(skill => skill.toLowerCase()).join('');
    const result =  matchingSkillsString?.includes(skillName.toLowerCase());
    console.log(result);
    return result;
};

const matchingSkillsNotInSkills = computed(() => {
    const matchingSkills = props.applicationDetails?.matching_skills || [];
    const skillsString = props.applicationDetails?.skills?.map(skill => skill.skill_name.toLowerCase()).join('');
    return matchingSkills.filter(skill => !skillsString.includes(skill.toLowerCase()));
});

const getSkillTooltipContent = (skill: any) => {
    const parts = [];
    
    if (skill.score) {
        parts.push(`<div class="mb-1"><span class="font-semibold">Score:</span> ${skill.score}%</div>`);
    }
    if (skill.score_reason) {
        parts.push(`<div class="mb-1"><span class="font-semibold">Reason:</span> ${skill.score_reason}</div>`);
    }
    if (skill.year_of_experience) {
        parts.push(`<div class="mb-1"><span class="font-semibold">Approx. Experience:</span> ${skill.year_of_experience} years</div>`);
    }
    
    return parts.length ? `<div class="text-left p-2">${parts.join('')}</div>` : '';
};
</script>

<style scoped>
.animate-fadeIn {
    animation: fadeIn 0.3s ease-in-out;
}

.animate-ping-slow {
    animation: ping 2s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes ping {
    75%, 100% {
        transform: scale(1.5);
        opacity: 0;
    }
}

.scale-102 {
    --tw-scale-x: 1.02;
    --tw-scale-y: 1.02;
}

/* Add these new styles */
.text-container {
    position: relative;
    overflow: hidden;
    padding: 2px 0;
}

.name-text, .position-text {
    display: inline-block;
    white-space: nowrap;
    padding-right: 20px; /* Spacing at the end of scrolling text */
}

@keyframes scrollText {
    0% {
        transform: translateX(0);
    }
    15% { /* Reduced from 40% to 15% */
        transform: translateX(0);
    }
    85% { /* Changed from 80% to 85% */
        transform: translateX(calc(-100% + 200px));
    }
    100% {
        transform: translateX(0);
    }
}

.animate-text:hover .name-text,
.animate-text:hover .position-text {
    animation: scrollText 5s linear infinite; /* Reduced from 8s to 5s */
}

.name-text, .position-text {
    overflow: visible;
}

/* Update the candidate-details class */
.candidate-details {
    @apply w-full bg-white overflow-hidden flex flex-col;
}

/* Add these new styles for button container */
.flex-shrink-0 {
    flex-shrink: 0;
}

/* Add these styles for better mobile layout */
@media (max-width: 768px) {
    .flex-col > .flex-row {
        width: 100%;
    }
    
    .p-buttongroup, .p-button {
        flex: 1;
    }

    /* Make select full width on mobile */
    .p-select {
        width: 100%;
    }

    /* Ensure select has proper spacing and styling on mobile */
    :deep(.p-select) {
        width: 100%;
        margin-top: 0.5rem;
    }
    
    :deep(.p-dropdown) {
        width: 100%;
    }
}

/* ...existing styles... */

@keyframes scrollText {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-100% + 200px));
    }
}

.animate-text .name-text,
.animate-text .position-text {
    animation: scrollText 10s linear infinite;
}

/* Update mobile styles */
@media (max-width: 768px) {
    .p-buttongroup {
        width: 100%;
        display: flex;
    }
    
    .p-buttongroup .p-button {
        flex: 1;
        justify-content: center;
    }
    
    .p-buttongroup .p-button:last-child {
        flex: 0 0 auto;
    }
}

/* Add these styles for education cards */
.education-card {
    @apply transition-all duration-200 hover:bg-gray-100;
}

@media (max-width: 640px) {
    .education-card {
        @apply p-2;
    }
}

/* ...existing styles... */

/* Add these styles for skills */
.flex-wrap > div {
    @apply transition-all duration-200;
}

.flex-wrap > div:hover {
    @apply transform scale-105;
}

@media (max-width: 640px) {
    .flex-wrap > div {
        @apply text-xs py-1;
    }
}
</style>