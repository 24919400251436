<template>
    <DataTable :value="candidates" sortMode="multiple" removableSort tableStyle="align-self: stretch;"
        style="width: 100%;">
        <Column field="details.fullname" header="Name" sortable></Column>
        <Column field="details.contact.location" header="Location" sortable></Column>
        <Column field="report.score" header="AI Score" sortable>
            <template #body="slotProps">
                {{ slotProps.data.report.score }} / 100
            </template>
        </Column>
        <!-- <Column field="role" header="Role" sortable></Column> -->
        <Column field="report.interview_date" header="Interviewed at" sortable></Column>
        <Column headerStyle="text-align: center" bodyStyle="width: 9rem; text-align: center; overflow: visible">
            <template #body>
                <Button type="button" label="View result" severity="secondary" outlined raised />
            </template>
        </Column>
    </DataTable>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import type { Candidate } from '@/stores/candidateStore';
import { useCandidateStore } from '@/stores/candidateStore';

const route = useRoute()
const candidateStore = useCandidateStore()
const candidates = computed<Candidate[]>(() => candidateStore.getInterviewedCandidatesByJobId(Number(route.params.jobId)))
</script>
