

// Update getStatusStyle function to match the new status values
export function getStatusStyle(status: string) {
    const commonStyle = {
        height: '34px',
        padding: '4px 16px',
        fontSize: '16px',
        fontFamily: 'Sora, sans-serif',
        fontWeight: '400',
        lineHeight: '1.5',
        letterSpacing: '-0.01em',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: '8px',
        borderRadius: '39px',
    }

    const statusColorMap: { [key: string]: { color: string, backgroundColor: string } } = {
        'newapplicant': {
            color: '#0066FF',
            backgroundColor: 'rgba(0, 102, 255, 0.1)', // equivalent to #0066ff/10
        },
        'open': {
            color: '#0066FF',
            backgroundColor: 'rgba(0, 102, 255, 0.1)', // equivalent to #0066ff/10
        },
        'selected': {
            color: '#34C759',
            backgroundColor: '#34C7591A',
        },
        'aiselected': {
            color: '#00C7BE',
            backgroundColor: '#00C7BE1A',
        },
        'interview': {
            color: '#DEB100',
            backgroundColor: '#DEB1001A',
        },
        'rejected': {
            color: '#FF3B30',
            backgroundColor: '#FF3B301A',
        },
        'closed': {
            color: '#FF3B30',
            backgroundColor: '#FF3B301A',
        },
        'airejected': {
            color: '#FF3B30',
            backgroundColor: '#FF3B301A',
        },
        'no_decision': {
            color: '#8E8E93',
            backgroundColor: '#8E8E931A',
        },
        'offer': {
            color: '#5856D6',
            backgroundColor: '#5856D61A',
        }
    };

    return {
        ...commonStyle,
        ...(statusColorMap[status] || statusColorMap['no_decision'])
    };
}
