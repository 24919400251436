import axios from "axios";
import { JobDetailsForm, type NewJobForm, type QuestionTextAnswer, type QuestionMultipleChoice, type MultipleChoice } from "./stores/jobStore";
import router from "./router";
import { useRecruiterStore } from "./stores/recruiterStore";

import { type ApplicationDetails, type Candidate, type ApplicationStatus } from './stores/candidateStore';
const SERVER_URL = import.meta.env.VITE_SERVER_URL;
export const BASE_URL = `${SERVER_URL}/api`

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        console.error('API Error:', error?.response?.status, error?.message);
        
        if (error?.response?.status === 401) {
            try {
                const recruiterStore = useRecruiterStore();
                await recruiterStore.logout();
            } catch (navigationError) {
                console.error('Navigation failed:', navigationError);
            }
        }
        return Promise.reject(error);
    }
);

export const fetchJobs = async () => {
    const response = await axios.get(`${BASE_URL}/jobs/`);
    return response;
}

export const fetchJobById = async (id: number) => {
  const response = await axios.get(`${BASE_URL}/jobs/${id}/`);
  return response.data; // Ensure to return the data property
};

export const sendOTP = async (email: string) => {
    return await axios.post(`${BASE_URL}/auth/login`, { email });
}

export const confirmOTP = async (token: number, email: string) => {
    return await axios.post(`${BASE_URL}/auth/confirm-otp?token=${String(token)}&email=${encodeURIComponent(email)}`);
}

export const fetchSignedInUserDetails = async () => {
    //TODO: get recruiter details and their comapany
    return await axios.get(`${BASE_URL}/profile/`);
}
export type UserProfile_Server = {
    first_name: string;
    last_name: string;
    position: string;
    email: string;
    profile_picture?: File;
}
export const patchUserProfile = async (profile: UserProfile_Server) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(profile)) {
        if (value != null) {
            formData.append(key, value);
        }
    }
    return await axios.patch(`${BASE_URL}/profile/`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export const fetchSignedInCompanyDetails = async () => {
    return await axios.get(`${BASE_URL}/company/`);
}
export type CompanyProfile_Server = {
    name: string;
    location: string;
    // description?: string;
    industry: string;
    // size?: string;
    url: string;
    contact: string;
    logo?: File;
}
export const patchCompanyProfile = async (profile: CompanyProfile_Server) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(profile)) {
        if (value != null) {
            formData.append(key, value);
        }
    }
    return await axios.patch(`${BASE_URL}/company/`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export type Application = {
    resume: File;
    full_name: string;
    email: string;
    phone: string;
    job: number;
    linkedin: string;
}
export const submitApplication = async (application: Application) => {
    const formData = new FormData();
    formData.append('resume', application.resume);
    formData.append('full_name', application.full_name);
    formData.append('email', application.email);
    formData.append('phone', application.phone);
    formData.append('job', application.job.toString());
    formData.append('linkedin', application.linkedin);

    return await axios.post(`${BASE_URL}/application-submissions/submit/`, formData);
}

export const generateJobDescription = async (form: JobDetailsForm): Promise<{ job_description: string, required_skills: string[] }> => {
    const response = await axios.post(`${BASE_URL}/job-info/generate-job-description/`, {
        job_title: form.jobPosition,
        language: form.language,
        location: form.location,
    });
    return response.data;
}

interface GeneratedQuestion {
    order: number; // unnessisary value unless it is a uid
    type: string; // should be "text answer" or "multiple choice" unless server only sends text questions and this value has different meaning
    coefficient: number;
    question: string;
    // missing values: questionFrom, answer(for text), expectedAnswer[](for multiple choice)
}

export const generateJobQuestions = async (job_description: string, num_questions: number = 10): Promise<GeneratedQuestion[]> => {
    const response = await axios.post(`${BASE_URL}/job-info/generate_interview_questions/`, {
        job_description: job_description,
        num_questions: num_questions
    });
    return response.data.questions;
}

export const createJob = async (job: NewJobForm) => {
    const response = await axios.post(`${BASE_URL}/jobs/`, {
        title: job.jobDetails.jobPosition,
        description: JSON.stringify(job.jobDetails.jobDescription),
        location: job.jobDetails.location,
        skills: job.jobDetails.skillsMajor.map(skill => ({ name: skill, description: "", skill_type: "hardskill" })),
        status: job.advancedOptions.jobStatus,
        min_salary: job.advancedOptions.salary[0],
        max_salary: job.advancedOptions.salary[1],
        prompt_text: job.advancedOptions.aiScorePrompt,
        ai_preselect_great: job.advancedOptions.aiPreselectGreat,
        ai_preselect_less: job.advancedOptions.aiPreselectLess,
        ai_automatic_interview: job.advancedOptions.aiAutoInvite
    });
    return response.data;
}

export const addQuestionToJob = async (jobId: number, question: QuestionTextAnswer | QuestionMultipleChoice, index: number) => {
    const request = {
        order: index,
        question_from: "rh",
        question_type: question.questionType,
        question_text: question.question,
        coefficient: question.coefficient,
        job: jobId
    }

    if ((question as QuestionTextAnswer).questionType === "text") {
        return await axios.post(`${BASE_URL}/text-questions/`, {
            ...request,
            expected_answer: question.answer,
        });
    } else if ((question as QuestionMultipleChoice).questionType === "multiple choice") {
        const choices = (question as QuestionMultipleChoice).answer.map((choice: MultipleChoice) => ({
            choice_text: choice.choice,
            is_expected_answer: false, //TODO: HARDCODED for now
            question: question.question,
        }));
        return await axios.post(`${BASE_URL}/multiple-choice-questions/`, {
            ...request,
            multiple_choices: true, //TODO check if this is needed
            choices,
        });
    }
}

export const fetchCandidatesByJobId = async (jobId: number): Promise<Candidate[]> => {
    try {
        const response = await axios.get(`${BASE_URL}/applications/?job=${jobId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching candidates:', error);
        return [];
    }
}

export const fetchAllCandidates = async (): Promise<Candidate[]> => {
    try {
        const response = await axios.get(`${BASE_URL}/applications/`);
        return response.data;
    } catch (error) {
        console.error('Error fetching all candidates:', error);
        return [];
    }
}

export const downloadResume = async (applicationId: number, candidateName: string) => {
    const response = await axios.get(
        `${BASE_URL}/applications/${applicationId}/resume/`,
        {
            responseType: 'blob'
        }
    )

    const blob = new Blob([response.data], { 
        type: response.headers['content-type'] 
    })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url

        const filename = candidateName
            ? `${candidateName}_resume.pdf`
            : 'resume.pdf';

        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(url)

}

export const fetchApplicationDetailsById = async (id: number): Promise<ApplicationDetails> => {
    try {
        const response = await axios.get(`${BASE_URL}/applications/${id}/`);
        return response.data;
    } catch (error) {
        console.error('Error fetching application details:', error);
        throw error;
    }
}

export const updateApplicationStatus = async (applicationId: number, status: ApplicationStatus) => {
    return await axios.patch(`${BASE_URL}/application-status-update/${applicationId}/update_status/`, {
        status: status
    });
}

/**
 * Fetches feature switches from the API
 * @returns Promise containing array of switches
 */
export async function fetchSwitches(): Promise<Record<string, boolean>> {
    const response = await axios.get<Record<string, boolean>>(`${BASE_URL}/switches/`)
    return response.data
}

/**
 * Deletes a job by ID
 * @param jobId - ID of the job to delete
 * @returns Promise with the deletion response
 */
export const deleteJob = async (jobId: number): Promise<void> => {
    return await axios.delete(`${BASE_URL}/jobs/${jobId}/`);
}

/**
 * Updates a job's status to closed
 * @param jobId - ID of the job to close
 * @returns Promise with the updated job data
 */
export const closeJob = async (jobId: number): Promise<any> => {
    return await axios.patch(`${BASE_URL}/jobs/${jobId}/`, {
        status: 'closed'
    });
}

/**
 * Reopens a closed job
 * @param jobId - ID of the job to reopen
 * @returns Promise with the updated job data
 */
export const reopenJob = async (jobId: number): Promise<any> => {
    return await axios.patch(`${BASE_URL}/jobs/${jobId}/`, {
        status: 'open'
    });
}
